import React from 'react'
import { IGoogleAutoAd } from './GoogleAutoAd'
import AdsClickIcon from '@mui/icons-material/AdsClick';
import { DefaultModal } from 'src/components/windows/DefaultModal';
import AddYourProductWindow from 'src/components/windows/AddYourProductWindow';
import useWindowSize from 'src/hooks/UseWindowSize';

const MockAd = (props: IGoogleAutoAd) => {
    const [openAd, setOpenAd] = React.useState(false);

    const handleClose = (e: React.MouseEvent) => {
        e.stopPropagation(); 
        setOpenAd(false);
    }
    
    const _renderAdInfo = () => {
        return(
            <DefaultModal open={openAd} handleClose={handleClose} handleOpen={() => setOpenAd(true)}>
                <AddYourProductWindow title='ad|A' textAction='ad_action|A'/>
            </DefaultModal>
        )
    }

    return (
        <div 
            className='bg-[#f2f2f2] flex items-center justify-center py-5 px-2 rounded-3xl border-dashed border-[#60a5fa] border-2 gap-1 cursor-pointer'
            style={{marginTop: props.marginTop, marginBottom: props.marginBottom, marginRight: props.marginRight, marginLeft: props.marginLeft}}
            onClick={() => setOpenAd(true)}
        >
            <div><AdsClickIcon style={{fontSize: '2.5rem', color: '#60a5fa'}}/></div>
            <div className='text-blue-400'>Покажіть свою рекламу тут</div>
            {openAd && _renderAdInfo()}
        </div>
    )
}

export default MockAd