import { Adsense } from '@ctrl/react-adsense';
import React from 'react'
import { isAdActive } from 'src/App';

const _renderGoogleAd = () => {
    return (
        <Adsense
            client="ca-pub-1861092917384814"
            slot="4298039593"
            layout="in-feed"
            format="fluid"
            adTest='on'
        />
    )
}

const GoogleFeedAd = () => {
    return isAdActive ? _renderGoogleAd() : <></>;
}

export default GoogleFeedAd