import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from "react-lazy-load-image-component";
import { motion } from 'framer-motion';
import 'react-lazy-load-image-component/src/effects/blur.css';

const tips = [
    {
        id: 1,
        title: 'Хохохо... додай понад 3 товари у корзину та отримай додаткові 5% знижки.',
        image: 'animalsChris/cat.png'
    }, {
        id: 3,
        title: 'Замовляй товар зараз - оплачуй на пошті. Безпека в інтернеті перш за все!',
        image: 'animalsChris/bear.png'
    }, {
        id: 4,
        title: 'Не забудь обрати розмір та колір, щоб ми знали, що тобі пакувати!',
        image: 'animalsChris/pig.png'
    }, {
        id: 5,
        title: 'Вжжжуууххх. Вперед за шопінгооом. Покажемо йому, де раки зимують!',
        image: 'animalsChris/cat_tea.png'
    }, {
        id: 6,
        title: 'Ці лапки тут, щоб тобі допомогти....Раптом заблукаєш.',
        image: 'animalsChris/cat_cute.png'
    }, {
        id: 7,
        title: 'Цейвот..Хочеш такі модні окуляри як в мене? - тобі в "аксесуари".',
        image: 'animals/cat_cool.png'
    }, {
        id: 8,
        title: 'Мяууууу.... Тепер ти можеш додати свій товар до нашого каталогу. Розкажи нам про нього у нашому інстаграмі!',
        image: 'animals/cat_cute2.png'
    }, {
        id: 9,
        title: 'Ці лапки доставлять тобі твоє замовлення якнайшвидше. Мурррр....',
        image: 'animals/cat_cute3.png'
    }, {
        id: 10,
        title: 'Хехееее ти знову тууут, ми за тобою вже ну дуууже скучили! Що сьогодні шукаємо?',
        image: 'animals/cat_cute4.png'
    }, {
        id: 11,
        title: 'Еййй хутчіш виставляй свої товари до нас! Це дуже просто і безкоштовно. Просто розкажи нам про них в інстаграмі!',
        image: 'animals/cat_cute5.png'
    }, {
        id: 12,
        title: 'Навіть якщо товару немає у наявності, не обурюйся. Ми не сумуємо і ти не сумуй! Ти все одно можеш замовити його, ці лапки подбають, щоб тобі доставили його якнайшвидше.',
        image: 'animals/cat_cute6.png'
    }
];

type Tip = {
    id: number;
    title: string;
    image: string;
};

const Tips = () => {
    const [randomTip, setRandomTip] = useState<Tip | null>(null);
    const [isMinimized, setIsMinimized] = useState(false);

    const selectRandomTip = () => {
        const randomIndex = Math.floor(Math.random() * tips.length);
        const selectedTip = tips[randomIndex];
        setRandomTip(selectedTip);
    };

    useEffect(() => {
        selectRandomTip();
    }, []);

    const handleTipClick = () => {
        setIsMinimized(true);
        setTimeout(() => {
            selectRandomTip();
            setIsMinimized(false);
        }, 1000);
    };

    if (!randomTip) {
        return null;
    }

    return (
        <div className="w-full flex justify-center relative mt-2">
            <motion.div
                onClick={handleTipClick}
                className="cursor-pointer flex flex-col items-center"
                initial={{ scale: 1 }}
                animate={{ scale: isMinimized ? 0 : 1 }} 
                transition={{ duration: 0.3, ease: 'easeInOut' }}
            >
                <LazyLoadImage
                    src={randomTip.image}
                    className="2xl:w-[250px] w-[200px]"
                    effect="blur"
                    PlaceholderSrc="defaults/logoclear.png"
                />
                <div
                    key={randomTip.id}
                    className="tips-container gilroy"
                >
                    <div>{randomTip.title}</div>
                </div>
            </motion.div>
        </div>
    );
};

export default Tips;
