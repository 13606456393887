import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import {ReactComponent as CloseIcon} from '../../images/close.svg';

const getWidth = () => (window.innerWidth < 768 ? '90%' : 400);

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: getWidth(),
    bgcolor: 'background.paper',
    borderRadius: 7,
    boxShadow: 24,
    p: 5,
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    gap: 2,
    border: 'none',
    outline: 'none',
};

interface IModalProps {
    open: boolean;
    handleClose: (e: React.MouseEvent) => void;
    handleOpen: () => void;
    children?: React.ReactNode;
}

export const DefaultModal = (props: IModalProps) => {
    const closeModal = (e: React.MouseEvent) => {
        props.handleClose(e);
    }

    const _renderIcon = () => {
        return (
            <div className="absolute top-4 right-4 bg-[#ccc] rounded-full p-1 cursor-pointer hover:bg-[#e6e6e6] duration-300" onClick={closeModal}><CloseIcon className="w-[15px]"/></div>
        )
    }

    return (
        <>
            <Modal
                open={props.open}
                onClose={closeModal}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={props.open}>
                    <Box sx={style}>
                        {_renderIcon()}
                        {props.children}
                    </Box>
                </Fade>
            </Modal>
        </>
    );
}