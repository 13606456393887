import React from 'react'
import './itembar.css'

interface SingleItemProps {
    icon: string;
    name: string;
    onChange?: () => void;
}

const SingleItem = (props: SingleItemProps) => {
    return (
        <div 
            className='single_item-container'
            onClick={props.onChange}
        >
            <div>
                <img
                    src={props.icon}
                />

            </div>
            <div className='text-sm text-[#f2f2f2]'>{props.name}</div>
        </div>
    )
}

export default SingleItem