import React from 'react'
import SingleItem from './SingleItem'
import './itembar.css'
import { useNavigate } from 'react-router'
import { getRoute } from 'src/services/routes.service'

interface ItemBarProps {
    handleSidebar: () => void;
}

const ItemBar = (props: ItemBarProps) => {
    const navigate = useNavigate();
    const handleInstagram = () => {
        window.open('https://www.instagram.com/love.tvoi/?igshid=YmMyMTA2M2Y%3D', '_blank');
    }

    const handleCart = () => {
        navigate(getRoute(`cart`));
        handleClose();
    }

    const handleClose = () => {
        props.handleSidebar()
    }
    const items_data = [
        {
            icon: 'icons/instagram.svg',
            name: 'Наш Інстаграм',
            onChange: () => handleInstagram()
        },
        {
            icon: 'icons/cart.svg',
            name: 'Моя корзина',
            onChange: () => handleCart()
        },
        {
            icon: 'icons/close-square.svg',
            name: 'Закрити',
            onChange: () => handleClose()
        }
    ]
    return (
        <div className='all_items-container'>
            {items_data.map((item, index) => {
                return (
                    <SingleItem
                        key={index}
                        icon={item.icon}
                        name={item.name}
                        onChange={item.onChange}
                    />
                )
            })}
        </div>
    )
}

export default ItemBar