import { Adsense } from '@ctrl/react-adsense';
import React from 'react'
import MockAd from './MockAd';
import { isAdActive } from 'src/App';

export interface IGoogleAutoAd {
    marginTop?: string;
    marginBottom?: string;
    marginRight?: string;
    marginLeft?: string;
}

const _renderGoogleAd = (props: IGoogleAutoAd) => {
    return (
        <Adsense
            client="ca-pub-1861092917384814"
            slot="8762559481"
            format="auto"
            adTest='on'
            responsive='true'
            style={{ 
                display: 'flex', 
                justifyContent: 'center',
                marginTop: props.marginTop, 
                marginBottom: props.marginBottom,
                marginRight: props.marginRight,
                marginLeft: props.marginLeft
            }}
        />
    )
}

const GoogleAutoAd = (props: IGoogleAutoAd) => {
    return isAdActive ? _renderGoogleAd(props) : <MockAd {...props} />
}

export default GoogleAutoAd