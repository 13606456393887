import React from 'react'
import { translateText } from 'src/services/translation.service'
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Tooltip } from '@mui/material';

interface IAddYourProductWindowProps {
    title: string;
    textAction: string;
}

const AddYourProductWindow = (props: IAddYourProductWindowProps) => {
    const handleInstagram = () => {
        window.open('https://www.instagram.com/love.tvoi/?igshid=YmMyMTA2M2Y%3D', '_blank');
    }

    return (
        <div className='flex flex-col items-center justify-center text-center gap-4'>
            <div className='text-xl font-bold text-[#414141]'>
                {translateText(props.title)}
            </div>

            <div className='text-sm text-[#6a6a6a] bg-[#f2f2f2] p-3 rounded-[20px] flex flex-col items-center text-center gap-2'> 
                <Tooltip title={translateText('ad_product_info|A')} placement='top' >
                    <NotificationsActiveIcon style={{fontSize: '2.5rem', color: '#3b82f6'}}/>  
                </Tooltip>
                <div>{translateText(props.textAction)}</div>
            </div>

            <div className='flex flex-col items-center justify-center text-center gap-1' onClick={handleInstagram}>
                <InstagramIcon style={{fontSize: '2.5rem', color: '#3b82f6'}}/>
                
                <div className='text-base text-blue-500 cursor-pointer' onClick={handleInstagram}>   
                    {translateText('go_insta|A')}
                </div>
            </div>
            
        </div>
    )
}

export default AddYourProductWindow