import React, { useEffect, useState } from "react";
import toastrService from "../../services/toastr.service";
import { Toastr } from "./Toastr";

interface IToastr {
    message: string;
    type: string;
    id: number; 
}

export function ToastrList() {
    const [toastrs, setToastrs] = useState<IToastr[]>([]);

    let toastrIdCounter = 0; 

    const removeToastr = (id: number) => {
        setToastrs(prevToastrs => prevToastrs.filter(t => t.id !== id));
    }

    const addToastr = (message: string, type: string = "info") => {
        const newToastrId = ++toastrIdCounter;
        const newToastr = { message, type, id: newToastrId };

        setToastrs(prevToastrs => {
            if (prevToastrs.length >= 6) {
                const updatedToastrs = [...prevToastrs.slice(1), newToastr];
                return updatedToastrs;
            } else {
                return [...prevToastrs, newToastr];
            }
        });

        setTimeout(() => {
            removeToastr(newToastrId);
        }, 2500);
    }

    useEffect(() => {
        toastrService.subscribe((message: string, type: string = "info") => {
            addToastr(message, type);
        });
    }, []);

    return (
        <div className="toastr_position">
            {toastrs.map((t) =>
                <Toastr
                    key={t.id}
                    message={t.message} 
                    type={t.type} 
                />
            )}
        </div>
    )
}
