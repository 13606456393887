import React, { useContext, useState } from "react";
import { ReactComponent as SearchIcon } from "../images/search2.svg";
import { ProductContext } from "../context/product-context";
import { getRoute } from "../services/routes.service";
import { useNavigate } from "react-router";
import { ReactComponent as DeleteIcon } from "../images/cancel.svg";
import { debounce } from "lodash";
import { translateText } from "../services/translation.service";

import '../styles/searchstyles.css'

const SearchField = () => {
  const { search, translatedProducts } = useContext(ProductContext);
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [inputValue, setInputValue] = React.useState("");
  const navigate = useNavigate();

  const debouncedSetSuggestions = debounce((query) => {
    const lowerCaseQuery = query.toLowerCase().trim();
    const terms = lowerCaseQuery.split(" ").filter((term) => term !== "");

    let filteredSuggestions;

    if (terms.length > 1) {
      filteredSuggestions = Array.from(
        new Set(
          translatedProducts
            .filter((product) => {
              const productName = product.translatedName?.toLowerCase() || "";
              const productBrand = product.brand?.toLowerCase() || "";
              return (
                terms.every(
                  (term) =>
                    productName.includes(term) || productBrand.includes(term)
                ) &&
                productName.includes(terms[0]) &&
                productBrand.includes(terms[1])
              );
            })
            .map((product) => `${product.translatedName} ${product.brand}`)
        )
      );
    } else {
      filteredSuggestions = Array.from(
        new Set(
          translatedProducts
            .filter((product) => {
              const productName = product.translatedName?.toLowerCase() || "";
              const productBrand = product.brand?.toLowerCase() || "";
              return (
                productName.includes(lowerCaseQuery) ||
                productBrand.includes(lowerCaseQuery)
              );
            })
            .map((product) => `${product.translatedName} ${product.brand}`)
        )
      );
    }

    setSuggestions(filteredSuggestions);
  }, 1000);

  const handleInputChange = (query) => {
    setInputValue(query);
    if (query === "") {
      setSuggestions([]);
    } else {
      search(query);
      debouncedSetSuggestions(query);
    }
  };

  const handleSuggest = (query) => {
    setInputValue(query);
    search(query);
    if(query){
      setTimeout(() => {
        navigate(getRoute(`search`));
      }, 0)
    }
  };

  const handleClearAll = () => {
    setSuggestions([]);
    setInputValue("");
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    navigate(getRoute(`search`));
  };

  return (
    <div className="relative">
      <div
        className={`searchField_container ${
          suggestions.length > 0 ? "rounded-t-3xl" : "rounded-3xl"
        } py-1 text-[#000]`}
      >
        <form
          role="search"
          onSubmit={handleSubmit}
          className="flex items-center w-5/6"
        >
          <input
            value={inputValue}
            autoComplete="off"
            type="text"
            id="searchInput"
            className="sm:py-2 py-3 px-4 bg-transparent focus:outline-none focus:border-none rounded-3xl"
            placeholder={translateText("search input label|A")}
            aria-label={translateText("search input label|A")}
            onChange={(event) => handleInputChange(event.target.value)}
          />
          {suggestions.length > 0 ? (
            <DeleteIcon className="pr-2 w-[35px]" onClick={handleClearAll} />
          ) : (
            <div className="w-[35px]" />
          )}
        </form>
        <div className="bg-[#ccc] w-[1px] h-[30px] " />
        <span
          className="pr-5 pl-4"
          onClick={() => navigate(getRoute(`search`))}
        >
          <SearchIcon />
        </span>
      </div>
      {suggestions.length > 0 ? (
        <div className="absolute max-h-[160px] w-full overflow-y-auto sm:bg-[#ffffff5e] bg-[#f2f2f2] rounded-b-3xl backdrop-blur-md z-10">
          {suggestions.map((suggestion, index) => (
            <div
              key={index}
              className="px-2.5 py-1 text-[#bebebe] hover:bg-[#4a4a4a5e] duration-200"
              onClick={() => handleSuggest(suggestion)}
            >
              {suggestion}
            </div>
          ))}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default SearchField;
