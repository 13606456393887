import React, { useState } from 'react';
import { Button, Input, Box, Typography } from '@mui/material';
import { convertQueryToFirestoreCommand } from 'src/services/firebase.service';

const InputAdmin = () => {
    const [query, setQuery] = useState<string>(''); // State to hold the query
    const [results, setResults] = useState<any[]>([]); // State to hold query results
    const [error, setError] = useState<string | null>(null); // State to hold errors

    const handleQueryExecution = async () => {
        try {
            setError(null); // Reset error state
            const data = await convertQueryToFirestoreCommand(query); // Execute the query
            setResults(Array.isArray(data) ? data : []); // Store results
        } catch (err: any) {
            setError(err.message || 'An error occurred while executing the query.');
        }
    };

    return (
        <div className='w-full'>
            <Input
                fullWidth
                placeholder="Enter your Firestore query (e.g., 'select prices where count > 0')"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                sx={{ marginBottom: '16px' }}
            />
            <Button
                variant="contained"
                color="primary"
                onClick={handleQueryExecution}
                disabled={!query.trim()}
            >
                Execute Query
            </Button>
            {error && (
                <Typography color="error" sx={{ marginTop: '16px' }}>
                    {error}
                </Typography>
            )}
            {results.length > 0 && (
                <Box sx={{ marginTop: '24px' }}>
                    <Typography variant="h6">Query Results:</Typography>
                    <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                        {JSON.stringify(results, null, 2)}
                    </pre>
                </Box>
            )}
        </div>
    );
};

export default InputAdmin;
