export function sanitizePhoneNumber(phone: string): string {
    let sanitizedPhone = phone.replace(/^(\+38|38)/, '');

    if (!sanitizedPhone.startsWith('0')) {
        sanitizedPhone = '0' + sanitizedPhone;
    }

    sanitizedPhone = sanitizedPhone.replace(/^0+/, '0');

    return sanitizedPhone;
}