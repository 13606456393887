export class ProductsService {
  getOfferedProducts = (products: IProduct[], currentProduct: IProduct) => {
    // Sort and filter based on category and metal as before
    let offeredProducts = [...products]
      .sort((a, b) => {
        if (a.category === currentProduct.category && b.category !== currentProduct.category) {
          return -1;
        }
        if (
          a.category !== currentProduct.category &&
          b.category === currentProduct.category
        ) {
          return 1;
        }
        if (a.metal === currentProduct.metal && b.metal !== currentProduct.metal) {
          return -1;
        }
        if (a.metal !== currentProduct.metal && b.metal === currentProduct.metal) {
          return 1;
        }
        return Math.random() - 0.5;
      })
      .filter((a) => a.category === currentProduct.category || a.metal === currentProduct.metal);

    // Ensure a minimum of 99 products by injecting from all possible categories if needed
    if (offeredProducts.length < 99) {
      const additionalProducts = this.getAdditionalProducts(products, offeredProducts.length);
      offeredProducts = offeredProducts.concat(additionalProducts);
    }

    return offeredProducts.slice(0, Math.max(99, offeredProducts.length)); // Ensure at least 99 products
  }

  getRandomProducts = (products: IProduct[], currentProduct: IProduct) => {
    let randomProducts = [...products]
      .sort(() => Math.random() - 0.5)
      .filter((a) => a.category !== currentProduct.category && a.metal !== currentProduct.metal);

    // Ensure a minimum of 99 products by injecting from all possible categories if needed
    if (randomProducts.length < 99) {
      const additionalProducts = this.getAdditionalProducts(products, randomProducts.length);
      randomProducts = randomProducts.concat(additionalProducts);
    }

    return randomProducts.slice(0, Math.max(99, randomProducts.length)); // Ensure at least 99 products
  }

  getSaleProducts = (products: IProduct[]) => {
    let saleProducts = [...products].filter((a) => a.salePrice > 0);

    // Ensure a minimum of 99 products by injecting from all possible categories if needed
    if (saleProducts.length < 99) {
      const additionalProducts = this.getAdditionalProducts(products, saleProducts.length);
      saleProducts = saleProducts.concat(additionalProducts);
    }

    return saleProducts.slice(0, Math.max(99, saleProducts.length)); // Ensure at least 99 products
  }

  // Helper function to get additional products from any available category
  private getAdditionalProducts = (products: IProduct[], currentLength: number) => {
    const additionalProducts = [...products]
      .sort(() => Math.random() - 0.5); // Randomize the additional products

    // Add only enough products to reach 99
    return additionalProducts.slice(0, 99 - currentLength);
  }

  isShippingFree = (product: IProduct): boolean => {
    const isAvailable = product.count > 0;
    //const isWatch = product.category === 'watches';
    const isSale = product.salePrice > 0;
    const applicablePrice = isSale ? product.salePrice : product.price;

    return isAvailable && applicablePrice > 1500;
}
}

const productsService = new ProductsService();
export default productsService;
