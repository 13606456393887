import React from 'react'
import { useNavigate } from 'react-router-dom';
import { getRoute } from 'src/services/routes.service';
import { translateText } from 'src/services/translation.service';
import TvoiSvg from '../ui/tvoi-svg';

const Christmas = () => {
    const navigate = useNavigate();

    const goToSales = () => {
        navigate(getRoute('sales'));
    }

    const _renderGoToButton = () => {
        return(
            <div className='flex justify-center items-center'>
                <button onClick={goToSales} className='bg-[#000] text-white p-3 rounded-3xl text-lg font-[100]' style={{letterSpacing: '1px'}}>{translateText("viewDetails|A")}</button>
            </div>
        )
    }
    return (
        <div className='flex sm:flex-row flex-col w-full justify-around px-3 gap-5 mt-5'>
            <div className='relative w-full flex justify-center'>
                    <TvoiSvg
                        path='icons/christmas_tree.svg'
                        alt='Christmas'
                    />
                <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2' >
                    <div className='text-[55px] font-[900] text-white'>TVOI</div>
                </div>
            </div>
            <div className='w-full flex justify-center items-center'>
                <div className='lg:text-[75px] text-[45px] font-[900] text-center' style={{letterSpacing: -2.5}}>
                    <div className='gilroy'>{translateText("christmas_sale|A")}.</div>
                    <div className='lg:text-[24px] text-[20px] text-[#bebebe] mb-5 font-[100]' style={{letterSpacing: -0.7}}>{translateText("christmas_sale_secondary|A")}</div>
                    {_renderGoToButton()}
                </div>
            </div>
        </div>
    )
}

export default Christmas