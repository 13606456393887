import { translateText } from "../services/translation.service";

export const ALL_DATA: any[] = [

  // COMPLECTS

  {
    id: 'SETPANdjksfjkfsldw-s6d6KLjkjdkwwsd6s-44ss4dsd4',
    count: 0,
    collection: "pandora",
    category: "necklaces",
    labelName:  "complect|A",
    brand: "Pandora",
    price: 3090,
    salePrice: 0,
    images: [
      { 
        id:'ISAjSKkksd-s6d6assd6s-44sadss44',
        url: "https://static.e-pandora.ua/24546/conversions/1701097828-webp.webp",
      },
      {
        id:'ISAjSKkksd-sasasad566kjdkwwsd6s-44ss44',
        url: "https://static.e-pandora.ua/24547/conversions/1701097833-webp.webp",
      },
      {
        id:'ISAasdjSKkksd-s6d6fsdsd6s-44ss44',
        url: "https://static.e-pandora.ua/24548/conversions/1701097837-webp.webp",
      }
    ],
    weight: 20.5,
    colors: [
      {
        id:'ISAjSKkksd-s6d6adsasdsgd6s-44ssgs44',
        name: "red|A",
      },
    ],
    metal: "silver925|A",
    sizes: [
      {
        id:'ISAjSKkksd-s6d6sd6s-44wrfadsdasdwss44',
        value: '45 cm',
      },
    ],
    description: "",
    popularity: 20,
    customPopularity: 20,
    packaging: 'basic_packaging|A',
    realPhotos: [],
    relatedProducts: [
      'EAJKHSJAFKssdfas-sdsdfsf-ldkdkj',
      'PAKJNDKsfd-FDSFDSS-dwedqASwdqwf'
    ]
  },
  {
    id: 'SETPSWRdjksfjkfsldw-s6d6KLjkjdkwwsd6s-44ss4dsd4',
    count: 1,
    collection: "Swarovski",
    category: "necklaces",
    labelName:  "complect|A",
    brand: "Swarovski",
    price: 4495,
    salePrice: 3195,
    images: [
      { 
        id:'ISAjSKkksd-s6d6assd6s-44sadss44',
        url: "https://thumbs2.imgbox.com/60/5d/MsNII5vM_t.png",
      }
    ],
    weight: 10.3,
    colors: [
      {
        id:'ISAjSKkksd-s6d6adsasdsgd6s-44ssgs44',
        name: "rosegold|A",
      },
    ],
    metal: "rosegold|A",
    sizes: [
      {
        id:'ISAjSKkksd-s6d6sd6s-44wrfadsdasdwss44',
        value: '14 - 18cm | 43 + 5cm',
      },
    ],
    description: "Символ нескінченного кохання: Цей елегантний браслет Swarovski демонструє символи любові та вічності, переплетені між собою. Прикрашений білими кристалами Swarovski, мотив серця ідеально доповнює символ нескінченності, створюючи по-справжньому романтичний образ. Покриття з змішаних металів ідеально балансує між теплими та холодними тонами, завершуючи дизайн. Це чудовий подарунок - і обіцянка - для особливої людини|A",
    popularity: 1,
    customPopularity: 1,
    packaging: 'Оригінальна коробка та пакетик Swarovski, сертифікат якості|A',
    realPhotos: [],
    relatedProducts: [
      'SWRJKSHKIoksddm,l-s6d6KLjkjdkwwsd6s-44ss4dsd4',
      'SWRKLJkdjsjklsdfdfdsfgb-s6d6KLjkjdkwwsd6s-44ss4dsd4'
    ]
  },



  // BRACELETS
  {
    id: 'CARATSSKDD-s6d6KLjkjdkwwsd6s-44ss4dsd4',
    count: 1,
    collection: "TVOI",
    category: "bracelets",
    labelName:  "bracelet_name|A",
    brand: "TVOI",
    price: 2590,
    salePrice: 1595,
    images: [
      {
        id:'ISAjSKkksd-sasasad566kjdkwwsd6s-44ss44',
        url: "https://thumbs2.imgbox.com/12/87/BFSl8tSs_t.jpg",
      },
      {
        id:'ISAasdjSKkksd-s6d6fsdsd6s-44ss44',
        url: "https://thumbs2.imgbox.com/f5/f5/CWCRiFQ1_t.jpg",
      },
      { 
        id:'ISAjSKkksd-s6d6assd6s-44sadss44',
        url: "https://thumbs2.imgbox.com/66/0b/ReZqZv2U_t.jpg",
      },
      {
        id:'ISAjSKkksd-sasasad566kjdkwwsd6s-44ss44',
        url: "https://thumbs2.imgbox.com/8b/fc/DPcgCFi0_t.jpg",
      },
      {
        id:'ISAasdjSKkksd-s6d6fsdsd6s-44ss44',
        url: "https://thumbs2.imgbox.com/f0/9d/Hc0Hips6_t.jpg",
      },
      { 
        id:'ISAjSKkksd-s6d6assd6s-44sadss44',
        url: "https://thumbs2.imgbox.com/7e/fb/UjHotTb0_t.jpg",
      },
      {
        id:'ISAjSKkksd-sasasad566kjdkwwsd6s-44ss44',
        url: "https://thumbs2.imgbox.com/c2/cf/uwC91Tbo_t.jpg",
      },
      {
        id:'ISAasdjSKkksd-s6d6fsdsd6s-44ss44',
        url: "https://thumbs2.imgbox.com/12/31/ExJyH9xx_t.jpg",
      },
      { 
        id:'ISAjSKkksd-s6d6assd6s-44sadss44',
        url: "https://thumbs2.imgbox.com/ef/d2/8BLWsKso_t.jpg",
      },
      {
        id:'ISAjSKkksd-sasasad566kjdkwwsd6s-44ss44',
        url: "https://thumbs2.imgbox.com/ca/14/z4K7zi5v_t.jpg",
      },
      {
        id:'ISAasdjSKkksd-s6d6fsdsd6s-44ss44',
        url: "https://thumbs2.imgbox.com/57/48/UtBgfLFp_t.jpg",
      }
    ],
    weight: 17.8,
    colors: [
      {
        id:'ISAjSKkksd-s6d6adsasdsgd6s-44ssgs44',
        name: "silver|A",
      },
      {
        id:'ISAjSKkksd-s6d6adsasdsgd6s-44ssgs44',
        name: "gold|A",
      },
      {
        id:'ISAjSKkksd-s6d6adsasdsgd6s-44ssgs44',
        name: "rosegold|A",
      },
    ],
    metal: "Ювелірна Сталь|A",
    sizes: [
      {
        id:'ISAjSKkksd-s6d6sd6s-44wrfadsdasdwss44',
        value: '17',
      }
    ],
    description: "",
    popularity: 2,
    customPopularity: 2,
    packaging: 'basic_packaging|A',
    realPhotos: []
  },
  {
    id: 'TVOIDKSJfkesfw-s6d6KLjkjdkwwsd6s-44ss4dsd4',
    count: 1,
    collection: "TVOI",
    category: "bracelets",
    labelName:  "bracelet_name|A",
    brand: "TVOI",
    price: 2199,
    salePrice: 1599,
    images: [
      {
        id:'ISAjSKkksd-sasasad566kjdkwwsd6s-44ss44',
        url: "https://thumbs2.imgbox.com/f7/f0/Z4dhhVF1_t.jpg",
      },
      {
        id:'ISAasdjSKkksd-s6d6fsdsd6s-44ss44',
        url: "https://thumbs2.imgbox.com/6b/72/JoBiZPmq_t.jpg",
      },
      { 
        id:'ISAjSKkksd-s6d6assd6s-44sadss44',
        url: "https://thumbs2.imgbox.com/d4/f4/AejSWEzU_t.jpg",
      }
    ],
    weight: 10.5,
    colors: [
      {
        id:'ISAjSKkksd-s6d6adsasdsgd6s-44ssgs44',
        name: "gold|A",
      },
    ],
    metal: "Ювелірна Сталь|A",
    sizes: [
      {
        id:'ISAjSKkksd-s6d6sd6s-44wrfadsdasdwss44',
        value: '17',
      }
    ],
    description: "",
    popularity: 2,
    customPopularity: 2,
    packaging: 'basic_packaging|A',
    realPhotos: []
  },
  {
    id: 'SWARHJdhskjfksdf-s6d6KLjkjdkwwsd6s-44ss4dsd4',
    count: 1,
    collection: "Swarovski",
    category: "bracelets",
    labelName:  "bracelet_name|A",
    brand: "Swarovski",
    price: 2890,
    salePrice: 2290,
    images: [
      {
        id:'ISAjSKkksd-sasasad566kjdkwwsd6s-44ss44',
        url: "https://thumbs2.imgbox.com/89/f3/wjDGGFY3_t.jpg",
      },
      {
        id:'ISAasdjSKkksd-s6d6fsdsd6s-44ss44',
        url: "https://asset.swarovski.com/images/$size_1450/t_swa103/b_rgb:ffffff,c_scale,dpr_1.0,f_auto,w_675/5669169_png_var2/teddy-bracelet--bear--pink--rose-gold-tone-plated-swarovski-5669169.png",
      },
      { 
        id:'ISAjSKkksd-s6d6assd6s-44sadss44',
        url: "https://asset.swarovski.com/images/$size_1450/t_swa103/b_rgb:ffffff,c_scale,dpr_1.0,f_auto,w_675/5669169_png_var3/teddy-bracelet--bear--pink--rose-gold-tone-plated-swarovski-5669169.png",
      },
      {
        id:'ISAadsasSKkksd-s6d6cvsd6s-44ss44',
        url: "https://asset.swarovski.com/images/$size_1450/t_swa002/c_scale,dpr_1.0,f_auto,w_675/5669169_ms1/teddy-bracelet--bear--pink--rose-gold-tone-plated-swarovski-5669169.jpg",
      },
    ],
    weight: 8.2,
    colors: [
      {
        id:'ISAjSKkksd-s6d6adsasdsgd6s-44ssgs44',
        name: "rosegold|A",
      },
    ],
    metal: "rosegold|A",
    sizes: [
      {
        id:'ISAjSKkksd-s6d6sd6s-44wrfadsdasdwss44',
        value: '16,5 - 18,5 cm',
      }
    ],
    description: "",
    popularity: 2,
    customPopularity: 2,
    packaging: 'basic_packaging|A',
    realPhotos: []
  },
  {
    id: 'SWRJKSHKIoksddm,l-s6d6KLjkjdkwwsd6s-44ss4dsd4',
    count: 1,
    collection: "Swarovski",
    category: "bracelets",
    labelName:  "bracelet_name|A",
    brand: "Swarovski",
    price: 3390,
    salePrice: 2395,
    images: [
      {
        id:'ISAjSKkksd-sasasad566kjdkwwsd6s-44ss44',
        url: "https://www.swarovski.ae/dw/image/v2/BDCL_PRD/on/demandware.static/-/Sites-swarovski-master-catalog/default/dw659aca03/images/5518869_png.jpg?sw=840&sh=840&sm=fit&sfrm=png&bgcolor=f2f2f2",
      },
      {
        id:'ISAasdjSKkksd-s6d6fsdsd6s-44ss44',
        url: "https://www.swarovski.ae/dw/image/v2/BDCL_PRD/on/demandware.static/-/Sites-swarovski-master-catalog/default/dw641528df/images/5518869_ms1.jpg?sw=840&sh=840&sm=fit&sfrm=jpg&bgcolor=ffffff",
      },
      { 
        id:'ISAjSKkksd-s6d6assd6s-44sadss44',
        url: "https://www.swarovski.ae/dw/image/v2/BDCL_PRD/on/demandware.static/-/Sites-swarovski-master-catalog/default/dw6913f203/images/5518869_var1_png.jpg?sw=840&sh=840&sm=fit&sfrm=png&bgcolor=f2f2f2",
      },
      {
        id:'ISAadsasSKkksd-s6d6cvsd6s-44ss44',
        url: "https://www.swarovski.ae/dw/image/v2/BDCL_PRD/on/demandware.static/-/Sites-swarovski-master-catalog/default/dw9bd7b251/images/5518869_var2_png.jpg?sw=840&sh=840&sm=fit&sfrm=png&bgcolor=f2f2f2",
      },
      {
        id:'ISAadsasSKkksd-s6d6cvsd6s-44ss44',
        url: "https://www.swarovski.ae/dw/image/v2/BDCL_PRD/on/demandware.static/-/Sites-swarovski-master-catalog/default/dw6e63b22b/images/5518869_var3_png.jpg?sw=840&sh=840&sm=fit&sfrm=png&bgcolor=f2f2f2",
      }
    ],
    weight: 6.5,
    colors: [
      {
        id:'ISAjSKkksd-s6d6adsasdsgd6s-44ssgs44',
        name: "rosegold|A",
      },
    ],
    metal: "rosegold|A",
    sizes: [
      {
        id:'ISAjSKkksd-s6d6sd6s-44wrfadsdasdwss44',
        value: '14 - 18 cm',
      }
    ],
    description: "Символ нескінченного кохання: Цей елегантний браслет Swarovski демонструє символи любові та вічності, переплетені між собою. Прикрашений білими кристалами Swarovski, мотив серця ідеально доповнює символ нескінченності, створюючи по-справжньому романтичний образ. Покриття з змішаних металів ідеально балансує між теплими та холодними тонами, завершуючи дизайн. Це чудовий подарунок - і обіцянка - для особливої людини|A",
    popularity: 1,
    customPopularity: 1,
    packaging: 'Оригінальна коробка та пакетик Swarovski, сертифікат якості|A',
    realPhotos: []
  },
  {
    id: 'ISAjSKkksd-s6d6KLjkjdkwwsd6s-44ss4dsd4',
    count: 1,
    collection: "pandora",
    category: "bracelets",
    labelName:  "bracelet_name|A",
    brand: "Pandora",
    price: 1590,
    salePrice: 1094,
    images: [
      {
        id:'ISAjSKkksd-sasasad566kjdkwwsd6s-44ss44',
        url: "https://thumbs2.imgbox.com/b1/40/lB4MSOcH_t.png",
      },
      {
        id:'ISAasdjSKkksd-s6d6fsdsd6s-44ss44',
        url: "https://thumbs2.imgbox.com/bb/28/SExr8PVq_t.png",
      },
      { 
        id:'ISAjSKkksd-s6d6assd6s-44sadss44',
        url: "https://thumbs2.imgbox.com/c3/24/VlSqPGjY_t.png",
      },
      {
        id:'ISAadsasSKkksd-s6d6cvsd6s-44ss44',
        url: "https://thumbs2.imgbox.com/c1/20/Vop6yzGr_t.jpg",
      },
    ],
    weight: 8.2,
    colors: [
      {
        id:'ISAjSKkksd-s6d6adsasdsgd6s-44ssgs44',
        name: "silver|A",
      },
    ],
    metal: "silver925|A",
    sizes: [
      {
        id:'ISAjSKkksd-s6d6sd6s-44wrfadsdasdwss44',
        value: '16',
      },
      {
        id:'ISAjSKkksd-s6d6sd6s-44wrfadsdasdwss44',
        value: '17',
      },
      {
        id:'ISAjSKkksd-s6d6sd6s-44wrfadsdasdwss44',
        value: '18',
      },
      {
        id:'ISAjSKkksd-s6d6sd6s-44wrfadsdasdwss44',
        value: '19',
      },
      {
        id:'ISAjSKkksd-s6d6sd6s-44wrfadsdasdwss44',
        value: '20',
      },
    ],
    description: "",
    popularity: 2,
    customPopularity: 2,
    packaging: 'basic_packaging|A',
    realPhotos: [
      {
        id:'ISAjSKkksd-sasasadfsfd566kjdkwwsasdd6s-44ss44',
        url: "real_photos/bracelets/ref_1.png",
      },
      {
        id:'ISAasdfsdjSKkksd-s6d6fsdsd6s-44ss44',
        url: "real_photos/bracelets/ref_2.jpg",
      },
      {
        id:'ISAadsasSwfq65as6dqKkksd-s6d6cvsd6s-44ss44',
        url: "https://thumbs2.imgbox.com/45/75/fJZtABkD_t.png",
      }
    ]
  },
  {
    id: 'ISAjSKkksd-fdgdhdgfafefeewfwewf-44ss4dsd4',
    count: 0,
    collection: "pandora",
    category: "bracelets",
    labelName:  "bracelet_name|A",
    brand: "Pandora",
    price: 1590,
    salePrice: 0,
    images: [
      {
        id:'ISAjSKkksd-sasasad566kjdkwwsd6s-44ss44',
        url: "https://static.e-pandora.ua/19188/conversions/PNGTRPNT_590039C01_RGB-webp.webp",
      },
      {
        id:'ISAasdjSKkksd-s6d6fsdsd6s-44ss44',
        url: "https://static.e-pandora.ua/19189/conversions/PNGTRPNT_590039C01_V2_RGB-webp.webp",
      },
      { 
        id:'ISAjSKkksd-s6d6assd6s-44sadss44',
        url: "https://static.e-pandora.ua/19191/conversions/PNGTRPNT_590039C01_V4_RGB-webp.webp",
      },
      {
        id:'ISAadsasSKkksd-s6d6cvsd6s-44ss44',
        url: "https://static.e-pandora.ua/19193/conversions/PNGTRPNT_SS22_A_Feb_Val_giftset_03_RGB-(56)-webp.webp",
      },
    ],
    weight: 10.2,
    colors: [
      {
        id:'ISAjSKkksd-s6d6adsasdsgd6s-44ssgs44',
        name: "silver|A",
      },
    ],
    metal: "silver925|A",
    sizes: [
      {
        id:'ISAjSKkksd-s6d6sd6s-44wrfadsdasdwss44',
        value: '15-21',
      },
    ],
    description: "",
    popularity: 2,
    customPopularity: 2,
    packaging: 'basic_packaging|A',
    realPhotos: []
  },
  {
    id: 'ISAjSKkksd-sdgfsgsefsd-44ss4dsd4',
    count: 0,
    collection: "pandora",
    category: "bracelets",
    labelName:  "bracelet_name|A",
    brand: "Pandora",
    price: 1590,
    salePrice: 0,
    images: [
      {
        id:'ISAjSKkksd-sasasad566kjdkwwsd6s-44ss44',
        url: "https://us.pandora.net/dw/image/v2/AAVX_PRD/on/demandware.static/-/Sites-pandora-master-catalog/default/dw8acb5659/productimages/singlepackshot/580041C01_RGB.jpg?sw=900&sh=900&sm=fit&sfrm=png&bgcolor=F2F2F2",
      },
      {
        id:'ISAasdjSKkksd-s6d6fsdsd6s-44ss44',
        url: "https://us.pandora.net/dw/image/v2/AAVX_PRD/on/demandware.static/-/Sites-pandora-master-catalog/default/dweeec453b/productimages/singlepackshot/580041C01_V3_RGB.jpg?sw=900&sh=900&sm=fit&sfrm=png&bgcolor=F2F2F2",
      },
      { 
        id:'ISAjSKkksd-s6d6assd6s-44sadss44',
        url: "https://us.pandora.net/dw/image/v2/AAVX_PRD/on/demandware.static/-/Sites-pandora-master-catalog/default/dwcd9bf6fc/productimages/singlepackshot/580041C01_V2_RGB.jpg?sw=900&sh=900&sm=fit&sfrm=png&bgcolor=F2F2F2",
      },
      {
        id:'ISAadsasSKkksd-s6d6cvsd6s-44ss44',
        url: "https://us.pandora.net/dw/image/v2/AAVX_PRD/on/demandware.static/-/Sites-pandora-master-catalog/default/dw33206f98/productimages/singlepackshot/580041C01_V4_RGB.jpg?sw=900&sh=900&sm=fit&sfrm=png&bgcolor=F2F2F2",
      },
    ],
    weight: 10.2,
    colors: [
      {
        id:'ISAjSKkksd-s6d6adsasdsgd6s-44ssgs44',
        name: "rosegold|A",
      },
    ],
    metal: "silver925|A",
    sizes: [
      {
        id:'ISAjSKkksd-s6d6sd6s-44wrfadsdasdwss44',
        value: '15-21',
      },
    ],
    description: "basic_desc|A",
    popularity: 2,
    customPopularity: 2,
    packaging: 'basic_packaging|A',
    realPhotos: []
  },
    {
      id: 'ISAjSKkksd-s6d6sd6s-44ss4dsd4',
      count: 1,
      collection: "pandora",
      category: "bracelet",
      labelName: "bracelet_name|A",
      brand: "Pandora",
      price: 2290,
      salePrice: 0,
      images: [
        {
          id:'ISAjSKkksd-s6d6swdd6s-44ss44',
          url: "https://live.staticflickr.com/65535/52644686048_8a965c3ff8_z.jpg",
        },
        {
          id:'ISAjSKkksd-s6d6fsdsd6s-44ss44',
          url: "https://live.staticflickr.com/65535/52644686068_98eab22115_z.jpg",
        },
        { 
          id:'ISAjSKkksd-s6d6assd6s-44ss44',
          url: "https://live.staticflickr.com/65535/52643704837_96afbd5eef_z.jpg",
        },
        {
          id:'ISAjSKkksd-s6d6cvsd6s-44ss44',
          url: "https://live.staticflickr.com/65535/52644686043_050a9ae076_z.jpg",
        },
      ],
      weight: 10.2,
      colors: [
        {
          id:'ISAjSKkksd-s6d6sgd6s-44ssgs44',
          name: "silver|A",
        },
      ],
      metal: "silver925|A",
      sizes: [
        {
          id:'ISAjSKkksd-s6d6sd6s-44wrfwss44',
          value: '17.5',
        },
        {
          id:'ISAjSKkksd-s6d6sd6s-44sgrs44',
          value: '19',
        },
        {
          id:'ISdfAjSKkksd-s6d6sd6s-44ss44',
          value: '21',
        },
      ],
      description: "",
      popularity: 25,
      customPopularity: 25,
      packaging: 'basic_packaging|A',
      realPhotos: []
    },
    {
      id: 'pajkhdja-s6d6sd6s-44ss4dsd4',
      count: 0,
      collection: "pandora",
      category: "bracelets",
      labelName: "bracelet_name|A",
      brand: "Pandora",
      price: 1590,
      salePrice: 0,
      images: [
        {
          id:'pajkhdja-s6d6swdd6s-44ss44',
          url: "https://static.e-pandora.ua/18769/conversions/PNGTRPNT_599694C01_V2_RGB-webp.webp",
        },
        {
          id:'pajkhdja-s6d6fsdsd6s-44ss44',
          url: "https://static.e-pandora.ua/18768/conversions/PNGTRPNT_599694C01_RGB-webp.webp",
        },
        { 
          id:'pajkhdja-s6d6assd6s-44ss44',
          url: "https://static.e-pandora.ua/18771/conversions/PNGTRPNT_599694C01_V4_RGB-webp.webp",
        },
        {
          id:'pajkhdja-s6d6cvsd6s-44ss44',
          url: "https://static.e-pandora.ua/18773/responsive-images/PNGTRPNT_599694C01_ABC123_MODEL_eCOM_02_RGB___webp_960_960.webp",
        },
      ],
      weight: 14.2,
      colors: [
        {
          id:'pajkhdja-s6d6sgd6s-44ssgs44',
          name: "silver|A",
        },
      ],
      metal: "silver925|A",
      sizes: [
        {
          id:'pajkhdja-s6d6sd6s-44wrfwss44',
          value: '17.5',
        },
        {
          id:'pajkhdja-s6d6sd6s-44sgrs44',
          value: '19',
        },
        {
          id:'pajkhdja-s6d6sd6s-44ss44',
          value: '21',
        },
      ],
      description: "",
      popularity: 25,
      customPopularity: 25,
      packaging: 'basic_packaging|A',
      realPhotos: []
    },
    {
      id: 'KHDaejfwegjwfgwerg-s6d6sd6s-44ss4dsd4',
      count: 1,
      collection: "pandora",
      category: "bracelets",
      labelName: "bracelet_name|A",
      brand: "Pandora",
      price: 1560,
      salePrice: 1265,
      images: [
        {
          id:'pajkhdja-s6d6swdd6s-44ss44',
          url: "https://static.e-pandora.ua/23936/conversions/1695903884-webp.webp",
        },
        {
          id:'pajkhdja-s6d6fsdsd6s-44ss44',
          url: "https://static.e-pandora.ua/23935/responsive-images/1695903881___webp_960_960.webp",
        },
      ],
      weight: 14.2,
      colors: [
        {
          id:'pajkhdja-s6d6sgd6s-44ssgs44',
          name: "silver|A",
        },
      ],
      metal: "silver925|A",
      sizes: [
        {
          id:'pajkhdja-s6d6sd6s-44wrfwss44',
          value: '17.5',
        },
        {
          id:'pajkhdja-s6d6sd6s-44sgrs44',
          value: '19',
        },
        {
          id:'pajkhdja-s6d6sd6s-44ss44',
          value: '21',
        },
      ],
      description: "",
      popularity: 1,
      customPopularity: 1,
      packaging: 'basic_packaging|A',
      realPhotos: []
    },
    {
      id: 'KHAduhqeifwef-s6d6sd6s-44ss4dsd4',
      count: 0,
      collection: "pandora",
      category: "bracelets",
      labelName: "bracelet_name|A",
      brand: "Pandora",
      price: 1790,
      salePrice: 0,
      images: [
        {
          id:'pajkhdja-s6d6swdd6s-44ss44',
          url: "https://static.e-pandora.ua/17575/responsive-images/PNGTRPNT_599493C00_V3_RGB___webp_960_960.webp",
        },
        {
          id:'pajkhdja-s6d6fsdsd6s-44ss44',
          url: "https://static.e-pandora.ua/17576/responsive-images/PNGTRPNT_599493C00_V4_RGB___webp_960_960.webp",
        },
        {
          id:'pajkhdja-s6d6swdd6s-44ss44',
          url: "https://static.e-pandora.ua/17574/responsive-images/PNGTRPNT_599493C00_V2_RGB___webp_960_960.webp",
        },
        {
          id:'pajkhdja-s6d6fsdsd6s-44ss44',
          url: "https://static.e-pandora.ua/17577/responsive-images/PNGTRPNT_599493C00_ABC123_MODEL_eCOM_01_RGB___webp_960_960.webp",
        },
      ],
      weight: 8.6,
      colors: [
        {
          id:'pajkhdja-s6d6sgd6s-44ssgs44',
          name: "silver|A",
        },
      ],
      metal: "silver925|A",
      sizes: [
        {
          id:'pajkhdja-s6d6sd6s-44wrfwss44',
          value: '17.5',
        },
        {
          id:'pajkhdja-s6d6sd6s-44sgrs44',
          value: '19',
        },
      ],
      description: "",
      popularity: 5,
      customPopularity: 5,
      packaging: 'basic_packaging|A',
      realPhotos: []
    },
    {
      id: 'KAHdiakfkwefgw-s6d6sd6s-44ss4dsd4',
      count: 0,
      collection: "pandora",
      category: "bracelets",
      labelName: "bracelet_name|A",
      brand: "Pandora",
      price: 2290,
      salePrice: 0,
      images: [
        {
          id:'pajkhdja-s6d6swdd6s-44ss44',
          url: "https://static.e-pandora.ua/17626/responsive-images/PNGTRPNT_599375C01_RGB___webp_960_960.webp",
        },
        {
          id:'pajkhdja-s6d6fsdsd6s-44ss44',
          url: "https://static.e-pandora.ua/17629/responsive-images/PNGTRPNT_599375C01_V4_RGB___webp_960_960.webp",
        },
        {
          id:'pajkhdja-s6d6swdd6s-44ss44',
          url: "https://static.e-pandora.ua/17628/responsive-images/PNGTRPNT_599375C01_V3_RGB___webp_960_960.webp",
        },
        {
          id:'pajkhdja-s6d6fsdsd6s-44ss44',
          url: "https://static.e-pandora.ua/17627/responsive-images/PNGTRPNT_599375C01_V2_RGB___webp_960_960.webp",
        },
      ],
      weight: 12.6,
      colors: [
        {
          id:'pajkhdja-s6d6sgd6s-44ssgs44',
          name: "silver|A",
        },
      ],
      metal: "silver925|A",
      sizes: [
        {
          id:'pajkhdja-s6d6sd6s-44wrfwss44',
          value: 'єдиний розмір',
        },
      ],
      description: "",
      popularity: 5,
      customPopularity: 5,
      packaging: 'basic_packaging|A',
      realPhotos: []
    },

    {
      id: 'OWUriuwqefdcjasid-s6d6sd6s-44ss4dsd4',
      count: 1,
      collection: "pandora",
      category: "bracelets",
      labelName: "bracelet_name|A",
      brand: "Pandora",
      price: 1695,
      salePrice: 1315,
      images: [
        {
          id:'pajkhdja-s6d6swdd6s-44ss44',
          url: "https://static.e-pandora.ua/7624/responsive-images/PNGTRPNT_598342CZ_RGB___webp_960_960.webp",
        },
        {
          id:'pajkhdja-s6d6fsdsd6s-44ss44',
          url: "https://static.e-pandora.ua/7648/responsive-images/PNGTRPNT_598342CZ_V2_RGB___webp_960_960.webp",
        },
        {
          id:'pajkhdja-s6d6swdd6s-44ss44',
          url: "https://static.e-pandora.ua/7591/responsive-images/PNGTRPNT_598342CZ_V3_RGB___webp_960_960.webp",
        },
      ],
      weight: 11.8,
      colors: [
        {
          id:'pajkhdja-s6d6sgd6s-44ssgs44',
          name: "silver|A",
        },
      ],
      metal: "silver925|A",
      sizes: [
        {
          id:'pajkhdja-s6d6sd6s-44wrfwss44',
          value: '18',
        },
        {
          id:'pajkhdja-s6d6sd6s-44wrfwss44',
          value: '19',
        },
      ],
      description: "",
      popularity: 1,
      customPopularity: 1,
      packaging: 'basic_packaging|A',
      realPhotos: []
    },
    
    {
      id: 'JSjgdjasdhj3223-232nsdm3n-dsdsds2ed',
      count: 1,
      collection: "Tiffany classic",
      category: "bracelets",
      labelName: "bracelet_name|A",
      brand: "Tiffany&Co",
      price: 2390,
      salePrice: 0,
      images: [
        {
          id: 'JSjgdjah34j3223-232sdnm3n-dscs2ed',
          url: "https://images2.imgbox.com/d8/f7/1LNW0X2a_o.png"
        },
        {
          id: 'JSjgdjahfwj3223-232ndsfm3n-ds2dsed',
          url: "https://images2.imgbox.com/39/94/k1ltLaCO_o.png"
        },
        {
          id: 'JSjgdj53ahj3223-232fdfwdqnm3n-dsdfs2ed',
          url: "https://images2.imgbox.com/73/99/AongHNkg_o.png"
        },
        {
          id: 'JSjgdjfdahj3223-232ndfdm3n-d43s2ed',
          url: "https://thumbs2.imgbox.com/a7/79/tPEWpr7i_t.png"
        }
      ],
      weight: 8.2,
      colors: [
        {
          id: 'JSjgd434jahj3223-23er2nm3n-dsfw2ed',
          name: "bluetiffany|A"
        },
        {
          id: 'JSjgdjawfehj3223-232errnm3n-dsfefe2ed',
          name: "red|A"
        },
        {
          id: 'JSjgddwfjahj3223-232efnm3n-defs2ed',
          name: "rosegold|A"
        }
      ],
      metal: "silver925|A",
      sizes: [
        {
          id: 'JSjgdjadhj3223-23efge2nm3n-dswr2ed',
          value: '16'
        },
        {
          id: 'JSjgdjahj3223-232ndm3n-ds2ed',
          value: '17'
        },
        {
          id: 'JSjgdjahj3223-232fdnm3n-ds2ed',
          value: '18'
        },
        {
          id: 'JSjgdjahjfw3223-232nm3n-ds2ed',
          value: '19'
        }
      ],
      description: "none",
      popularity: 2,
      customPopularity: 2,
      packaging: 'Оригінальна коробка бренду',
      realPhotos: []
    },
    {
      id:'PAiwodjwk-dswwfe22-211mndm',
      count: 0,
      collection: "Hermes Clic H",
      category: "bracelets",
      labelName: "bracelet_name|A",
      brand: "Hermes",
      price: 5090,
      salePrice: 0,
      images: [
        {
          id:'PAiwoefdjwk-dsw3ef22-211mndm',
          url: "https://thumbs2.imgbox.com/1b/c8/pFSkhXpJ_t.jpg"
        },
        {
          id:'PAiwdwwodjwk-dsw322-211mndm',
          url: "https://thumbs2.imgbox.com/50/17/3MYUwUb3_t.jpg"
        },
        {
          id:'PAiwdwsswodjwk-dsw322-211mndm',
          url: "https://thumbs2.imgbox.com/99/df/3aG6MXZF_t.jpg"
        }
      ],
      weight: 15.6,
      colors: [
        {
          id:'PAiwodjwk-defw322-211mndm',
          name: "black|A"
        },
        {
          id:'PAiwodjwk-defw322-21asdf1mndm',
          name: "rosegold|A"
        },
      ],
      metal: "емальована сталь",
      sizes: [
        {
          id:'PAiwdfodjwk-dsw322-211mndm',
          value: "17"
        },
        {
          id:'PAiwdasdasdfodjwk-dsw322-211mndm',
          value: "18"
        },
        {
          id:'PAiwdzCXasdfodjwk-dsw322-211mndm',
          value: "19"
        },
      ],
      description: "none",
      popularity: 4,
      customPopularity: 4,
      packaging: 'Оригінальна коробка бренду',
      realPhotos: []
    },
    {
      id: 'KLSkhdjwdsd-23kn2j3-23dcdds',
      count: 1,
      collection: "Sif Jakobs Erto",
      category: "bracelets",
      labelName: "bracelet_name|A",
      brand: "Sif Jakobs",
      price: 7990,
      salePrice: 5290,
      images: [
        {
          id: 'KLSkhdjwdsd-23kn2j3-23dcddfdds',
          url: "https://live.staticflickr.com/65535/52704663665_dcb710efba_z.jpg"
        },
        {
          id: 'KLSkhdjwdsd-23kn2j3-2qq3dcwdds',
          url: "https://live.staticflickr.com/65535/52704730088_ecf8fb0639_z.jpg"
        },
        {
          id: 'KLSkhdjwdsd-23kn2j3-23sdsdcdds',
          url: "https://live.staticflickr.com/65535/52704243831_726cfe5785_z.jpg"
        }
      ],
      weight: 13.4,
      colors: [
        {
          id: 'KLSkhdjwdsd-23kn2j3-2wew3dcdds',
          name: "silver|A"
        }
      ],
      metal: "silver925|A",
      sizes: [
        {
          id: 'KLSkhdjwdsd-23kn2j3-2sfq3dcdds',
          value: "16.5 - 19.5см"
        }
      ],
      description: "none",
      popularity: 2,
      customPopularity: 2,
      packaging: 'Оригінальна коробка бренду',
      realPhotos: []
    },
    {
      id: 'LSKJkjdhjs-32sdj32jbed0-232neds',
      count: 1,
      collection: "Xenox",
      category: "bracelets",
      labelName: "Срібний браслет із позолотою|A",
      brand: "Xenox",
      price: 1090,
      salePrice: 0,
      images: [
        {
          id: 'LSKJkjdhjs-32j3sw2jbed0-232nedsyy',
          url: "https://live.staticflickr.com/65535/52708553643_a5abeda3bc_z.jpg"
        },
        {
          id: 'LSKJkjdhjs-32j32j22bed0-232nedus',
          url: "https://live.staticflickr.com/65535/52707546237_196432a72d_z.jpg"
        }
      ],
      weight: 7.3,
      colors: [
        {
          id: 'LSKJkjd11ehjs-32j32jbed0-232nedsi',
          name: "rosegold|A"
        }
      ],
      metal: "silver925|A",
      sizes: [
        {
          id: 'LSKJkjdhjs-32jweew32jbed0-232nedssd',
          value: "16.5 - 18.5"
        }
      ],
      description: "string",
      popularity: 3,
      customPopularity: 3,
      packaging: 'Оригінальна коробка бренду',
      realPhotos: []
    },
    {
      id: 'LKSfweadhjd-323728sd-dnsmdbsj',
      count: 0,
      collection: "Pandora Me Old",
      category: "bracelets",
      labelName: "bracelet_name|A",
      brand: "Pandora",
      price: 1695,
      salePrice: 0,
      images: [
        {
          id: 'LKSadhjd-323728sd-dnsmdbsjsg',
          url: "https://live.staticflickr.com/65535/52644212836_bfcd6ca4bf_z.jpg"
        },
        {
          id: 'LKSasdsddhjd-323728sd-dnsmdbsjb',
          url: "https://live.staticflickr.com/65535/52644212871_62b1a29d7f_z.jpg"
        }
      ],
      weight: 9.3,
      colors: [
        {
          id: 'LKSadhjd-323728sd-dnsfsmdbsja',
          name: "silver|A"
        }
      ],
      metal: "silver925|A",
      sizes: [
        {
          id: 'LKSadwdqhjd-3237dwq28sd-dnsqwdmdbsjm',
          value: "17.5"
        },
        {
          id: 'LKSadhwdqjd-323728sd-dnsmdbsjc',
          value: "19"
        },
        {
          id: 'LKSadhjd-3237dwew28sd-dnsmdbsjt',
          value: "21"
        }
      ],
      description: "string",
      popularity: 1,
      customPopularity: 1,
      packaging: 'Оригінальна коробка бренду',
      realPhotos: [
        {
          id: 'LKSadhjd-Kkhdfkjasa-dnsmdbsjsg',
          url: "https://thumbs2.imgbox.com/11/99/Y4TurWJE_t.jpg"
        },
        {
          id: 'LKSasdsddhjd-323728sd-Jfksdkfsf',
          url: "https://thumbs2.imgbox.com/57/2c/a08FkFNo_t.jpg"
        },
        {
          id: 'Ofodjsdoffs-323728sd-dnsmdbsjsg',
          url: "https://thumbs2.imgbox.com/f7/6d/N1N2Aghe_t.jpg"
        }
      ]
    },
    {
      id: 'KSkhjdjsqwdhdkj-2u3idsjj-sldjkd',
      count: 1,
      collection: "Esprit Classic",
      category: "bracelets",
      labelName: "bracelet_name|A",
      brand: "Esprit",
      price: 1890,
      salePrice: 0,
      images: [
        {
          id: 'KSkhjdjshdkj-2u3qdwqwidsjj-sldjkd',
          url: "https://live.staticflickr.com/65535/52713043045_9ff59e9e46_z.jpg"
        },
        {
          id: 'KSkhjdqdwjshdkj-2u3iwfedsjj-sldjkd',
          url: "https://live.staticflickr.com/65535/52712105337_5408560e3a_z.jpg"
        }
      ],
      weight: 7.8,
      colors: [
        {
          id: 'KSkhjdjshdkj-2u3weridsjj-sldjkd',
          name: "silver|A"
        }
      ],
      metal: "silver925|A",
      sizes: [
        {
          id: 'KSkhjdwefjshdkj-2u3iwfdsjj-sldjkd',
          value: "17 - 19 - 21см"
        }
      ],
      description: "string",
      popularity: 5,
      customPopularity: 5,
      packaging: 'Оригінальна коробка бренду',
      realPhotos: []
    },
    {
      id:'OAsjdjsk-23n2jn-dskwefwefnej2',
      count: 1,
      collection: "Pandora",
      category: "bracelets",
      labelName: "bracelet_name|A",
      brand: "Pandora",
      price: 1590,
      salePrice: 1195,
      images: [
        {
          id:'OAsjdjsk-2fe3n2jn-dskwefnej2sas',
          url: "https://live.staticflickr.com/65535/52644655075_92570f98e0_z.jpg"
        },
        {
          id:'OAsjdjsk-23fwen2jn-dsknej2eg',
          url: "https://live.staticflickr.com/65535/52644655100_31d6f3d259_z.jpg"
        },
        {
          id:'OAsjwfedjsk-23n2jn-dsknej2fd',
          url: "https://live.staticflickr.com/65535/52644685978_60c4e618ea_z.jpg"
        },
        {
          id:'OAsjdjsk-23n2jn-dsknej2fweadd',
          url: "https://live.staticflickr.com/65535/52643704677_caa3de0262_z.jpg"
        }
      ],
      weight: 9.9,
      colors: [
        {
          id:'OAsjdr3qjsk-23n2jn-dsknejqefw2sdsd',
          name: "silver|A"
        }
      ],
      metal: "silver925|A",
      sizes: [
        {
          id:'OAsjdjsk-23n2jn-dsknewfj2cvc',
          value: "19"
        },
        {
          id:'OAsjdjsk-23n2jn-dsknewfj2cvc',
          value: "20"
        },
        {
          id:'OAsjdjsk-23n2jn-dsknewfj2cvc',
          value: "21"
        },
      ],
      description: "basic_desc|A",
      popularity: 15,
      customPopularity: 15,
      packaging: 'basic_packaging|A',
      realPhotos: []
    },
    {
      id: 'IUwqjdk920-skwndjw2-dwksndks',
      count: 0,
      collection: "Pandora",
      category: "bracelets",
      labelName: "bracelet_name|A",
      brand: "Pandora",
      price: 1790,
      salePrice: 0,
      images: [
        {
          id: 'IUwqjdqwedwk920-skwndjw2-dwksndks',
          url: "https://live.staticflickr.com/65535/52644212896_cc351cb4f1_z.jpg"
        },
        {
          id: 'IUwqjdk920-skwndjw2weq-dwksndks',
          url: "https://live.staticflickr.com/65535/52644654945_0a879a7684_z.jpg"
        },
        {
          id: 'IUwqjdkewew920-skwndjw2-dwksndks',
          url: "https://live.staticflickr.com/65535/52644212921_58711b0831_z.jpg"
        }
      ],
      weight: 9.9,
      colors: [
        {
          id: 'IUwqj232dk920-skwndjw2-dwksndks',
          name: "silver|A"
        }
      ],
      metal: "silver925|A",
      sizes: [
        {
          id: 'IUwqjdk920-skwndjw2-d2wwksndks',
          value: "16"
        },
        {
          id: 'IUwqjdkwewww920-skwndjw2-dwksndks',
          value: "17"
        },
        {
          id: 'IUw1w1wqqjdk920-skwndjw2-dwksndks',
          value: "18"
        },
        {
          id: 'IUwqqwqdjdk920-skwndjw2-dwksndks',
          value: "19"
        }
      ],
      description: "string",
      popularity: 15,
      customPopularity: 15,
      packaging: 'Оригінальна коробка бренду',
      realPhotos: []
    },
    {
      id: 'Uqpwkds2334-skwndjw2-dwksndks',
      count: 1,
      collection: "Thomas Sabo",
      category: "bracelets",
      labelName: "bracelet_name|A",
      brand: "Thomas Sabo",
      price: 1790,
      salePrice: 0,
      images: [
        {
          id: 'Uqpwkds2334-skwndjw2-ewrwe',
          url: "https://live.staticflickr.com/65535/52721094511_afc3450339_z.jpg"
        },
        {
          id: 'Uqpwkds2334-qwdqeq-dwksndks',
          url: "https://live.staticflickr.com/65535/52721581068_0010f9d0c9_z.jpg"
        }
      ],
      weight: 11.9,
      colors: [
        {
          id: 'Uqpwkds23qwew34-skwndjw2-dwksndks',
          name: "white|A"
        }
      ],
      metal: "прісноводна перлина",
      sizes: [
        {
          id: 'Uqpwkds2334-skwndqwe2121jw2-dwksndks',
          value: "єдиний розмір"
        }
      ],
      description: "string",
      popularity: 6,
      customPopularity: 1,
      packaging: 'Оригінальна коробка бренду',
      realPhotos: []
    },
    {
      id: 'PODojdlsd-efwfwe234-55d5sdw',
      count: 1,
      collection: "Thomas Sabo",
      category: "bracelets",
      labelName: "bracelet_name|A",
      brand: "Thomas Sabo",
      price: 1590,
      salePrice: 0,
      images: [
        {
          id: 'PODojdlsd-werwerw-55d5sdw',
          url: "https://live.staticflickr.com/65535/52721356534_24ca1a0e24_z.jpg"
        },
        {
          id: 'PODojdqweqlsd-efwfwe234-55d5sdw',
          url: "https://live.staticflickr.com/65535/52721581068_0010f9d0c9_z.jpg"
        }
      ],
      weight: 11.9,
      colors: [
        {
          id: 'PODojdlsd-efwfwe234-werwee43',
          name: "black|A"
        }
      ],
      metal: "обсидіан",
      sizes: [
        {
          id: 'PODojdlsd-qweqweq234-55d5sdw',
          value: "єдиний розмір"
        }
      ],
      description: "string",
      popularity: 7,
      customPopularity: 4,
      packaging: "",
      realPhotos: []
    },
    {
      id: 'Ouaydn885-qweqweq234-55d5sdw',
      count: 1,
      collection: "gift",
      category: "bracelets",
      labelName: "bracelet_name|A",
      brand: "Pandora",
      price: 1795,
      salePrice: 1365,
      images: [
        {
          id: 'Ouaydn885-qweqwe2weq234-55d5sdw',
          url: "https://live.staticflickr.com/65535/52643704772_7c1db9647b_z.jpg"
        },
        {
          id: 'Ouaydn885-dqwdqw-55d5sdw',
          url: "https://live.staticflickr.com/65535/52644655120_9c321849d3_z.jpg"
        },
        {
          id: 'Ouaydn885-4545rfwdfw-55d5sdw',
          url: "https://live.staticflickr.com/65535/52644468884_cc6a9be294_z.jpg"
        }
      ],
      weight: 11.9,
      colors: [
        {
          id: 'Ouaydn885-23edsf-55d5sdw',
          name: "silver|A"
        }
      ],
      metal: "silver925|A",
      sizes: [
        {
          id: 'Ouaydn885-qweqweq234-qwdqdqww',
          value: "17.5"
        },
        {
          id: 'Ouaydn885-qweqweq234-qwewqe32',
          value: "19"
        },
        {
          id: 'qwqwqwff-qweqweq234-55d5sdw',
          value: "21"
        }
      ],
      description: "Срібний браслет відомого бренду виготовлений у сучасному модному дизайні із масивними ланками, додасть акценту до твого образу на кожен день",
      popularity: 1,
      customPopularity: 1,
      packaging: "basic_packaging|A",
      realPhotos: []
    },
    {
      id: 'Paidskdms848-qweqweq234-55d5sdw',
      count: 1,
      collection: "gift",
      category: "bracelets",
      labelName: "bracelet_name|A",
      brand: "Paul Hewitt",
      price: 1490,
      salePrice: 1185,
      images: [
        {
          id: 'Paidskdms848-erfwwe32-55d5sdw',
          url: "https://live.staticflickr.com/65535/52753272480_6ae55e739d_z.jpg"
        },
        {
          id: 'wewewdf-qweqweq234-55d5sdw',
          url: "https://live.staticflickr.com/65535/52753272495_fb141d2419_z.jpg"
        },
        {id: 'Paidskdms848-qweqwweweq234-55d5sdw',
          url: "https://live.staticflickr.com/65535/52753102254_97a674defd_z.jpg"
        }
      ],
      weight: 7.9,
      colors: [
        {
          id: 'Paidskdmqwqws848-qweqweq234-55d5sdw',
          name: "black|A"
        }
      ],
      metal: "натуральна шкіра",
      sizes: [
        {
          id: 'Paidskdms848-qweqweq234-5tgreg5d5sdw',
          value: "16"
        }
      ],
      description: "Браслет унісекс Paul Hewitt має чорний шкіряний ремінець зі сріблястою пластиною з написом 53 08 32.9 N 8 12 39.8 E - координатами головного офісу Paul Hewitt. Застібається на фірмову застібку Paul Hewitt Anchor. Мікс чорного і сріблястого кольорів дозволяє легко поєднувати його з іншими речами.",
      popularity: 3,
      customPopularity: 3,
      packaging: "Мішечок Paul Hewitt та фірмовий пакет TVOI",
      realPhotos: []
    },
    {
      id: 'Iowsqesnkk223-qwesdsqweq234-5tgreg5d5sdw',
      count: 1,
      collection: "gift",
      category: "bracelets",
      labelName: "bracelet_name|A",
      brand: "Paul Hewitt",
      price: 1890,
      salePrice: 1399,
      images: [
        {
          id: 'Iowsqesnkk223-gdfgbvd-5tgreg5d5sdw',
          url: "https://live.staticflickr.com/65535/52753357283_2a52132879_z.jpg"
        },
        {
          id: 'Iowsqesnkk223-dqdwqq-5tgreg5d5sdw',
          url: "https://live.staticflickr.com/65535/52753102279_a5a2e2b35e_z.jpg"
        }
      ],
      weight: 10.4,
      colors: [
        {
          id: 'Iowsqesnkk223-qwesdsqweq234-wdqda',
          name: "red|A"
        }
      ],
      metal: "натуральна шкіра",
      sizes: [
        {
          id: 'Iowsqwewesnkk223-qwesdsqweq234-5tgreg5d5sdw',
          value: "16"
        }
      ],
      description: "Браслет із високоякісної шкіри із фірмовою застібкою Paul Hewitt Anchor. Застібка, яка символізує міцність та нероздільність - нагадування про ваші морські подорожі, що додає браслету особливий шар значущості",
      popularity: 3,
      customPopularity: 3,
      packaging: "Мішечок Paul Hewitt та фірмовий пакет TVOI",
      realPhotos: []
    },
    {
      id: 'Psisjdskf-sdqw34-5tgreg5d5sdw',
      count: 0,
      collection: "pandora",
      category: "bracelets",
      labelName: "bracelet_name|A",
      brand: "Pandora",
      price: 1595,
      salePrice: 0,
      images: [
        {
          id: 'qwdqqdw-sdqw34-5tgreg5d5sdw',
          url: "https://live.staticflickr.com/65535/52800597663_78b3edfc46_z.jpg"
        },
        {
          id: 'Psisjdskf-sdqweww34-5tgreg5d5sdw',
          url: "https://live.staticflickr.com/65535/52800398004_ea9d426202_z.jpg"
        },
        {
          id: 'Psisjdskf-sdqw34-5tgreg5dwewe5sdw',
          url: "https://live.staticflickr.com/65535/52800597748_9b71e71219_z.jpg"
        }
      ],
      weight: 7.8,
      colors: [
        {
          id: 'wqdqfdfsdds-sdqw34-5tgreg5d5sdw',
          name: "silver|A"
        }
      ],
      metal: "silver925|A",
      sizes: [
        {
          id: 'Psisjdskf-wedfefe-5tgreg5d5sdw',
          value: "16"
        },
        {
          id: 'Psisjdskf-wedfefe-5tgreg5d5sdw',
          value: "17"
        },
        {
          id: 'Psisjdskf-wedfefe-5tgreg5d5sdw',
          value: "18"
        },
        {
          id: 'Psisjdskf-wedfefe-5tgreg5d5sdw',
          value: "19"
        },
        {
          id: 'Psisjdskf-wedfefe-5tgreg5d5sdw',
          value: "20"
        },
      ],
      description: "Незмінна класика у колекції прикрас кожної жінки. Срібний браслет, який буде гарно вписуватись під будь-який образ",
      popularity: 2,
      customPopularity: 2,
      packaging: "basic_packaging|A",
      realPhotos: [
      ]
    },
    {
      id: 'Rsysdns-wedf44efe-5tgreg5d5sdw',
      count: 0,
      collection: "pandora",
      category: "bracelets",
      labelName: "bracelet_name|A",
      brand: "Pandora",
      price: 1790,
      salePrice: 0,
      images: [
        {
          id: 'Rsysdns-wedf44efe-wedfsqww',
          url: "https://live.staticflickr.com/65535/52892006310_4d7c65b25f_z.jpg"
        },
        {
          id: 'Rsysdns-qwdqwdf-5tgreg5d5sdw',
          url: "https://live.staticflickr.com/65535/52891784169_c47b208bdb_z.jpg"
        },
        {
          id: 'qwewqdew-wedf44efe-5tgreg5d5sdw',
          url: "https://live.staticflickr.com/65535/52892074503_7016941611_z.jpg"
        }
      ],
      weight: 7.8,
      colors: [
        {
          id: 'Rsysdns-wedf44efe-sdwqdqwew',
          name: "gold|A"
        }
      ],
      metal: "silver925|A",
      sizes: [
        {
          id: 'Rsysdns-wedf44efe-dwedqwdqwf',
          value: "17"
        },
        {
          id: 'Rsysdns-Kjkdsdss-dwedqwdqwf',
          value: "18"
        }
      ],
      description: "Незмінна класика у колекції прикрас кожної жінки. Срібний браслет, який буде гарно вписуватись під будь-який образ",
      popularity: 2,
      customPopularity: 2,
      packaging: "basic_packaging|A",
      realPhotos: []
    },

    // NECKLACES
    {
      id: 'SWARHJdhsk65347grfjdsf-s6d6KLjkjdkwwsd6s-44ss4dsd4',
      count: 1,
      collection: "Swarovski",
      category: "necklaces",
      labelName:  "necklace_name|A",
      brand: "Swarovski",
      price: 2590,
      salePrice: 1799,
      images: [
        {
          id:'ISAjSKkksd-sasasad566kjdkwwsd6s-44ss44',
          url: "https://thumbs2.imgbox.com/44/45/fvdt75uf_t.jpg",
        },
        {
          id:'ISAasdjSKkksd-s6d6fsdsd6s-44ss44',
          url: "https://asset.swarovski.com/images/$size_1450/t_swa103/b_rgb:ffffff,c_scale,dpr_1.0,f_auto,w_675/5642976_png_var3/teddy-pendant--bear--pink--rose-gold-tone-plated-swarovski-5642976.png",
        },
        { 
          id:'ISAjSKkksd-s6d6assd6s-44sadss44',
          url: "https://asset.swarovski.com/images/$size_1450/t_swa103/b_rgb:ffffff,c_scale,dpr_1.0,f_auto,w_675/5642976_png_var4/teddy-pendant--bear--pink--rose-gold-tone-plated-swarovski-5642976.png",
        },
        {
          id:'ISAadsasSKkksd-s6d6cvsd6s-44ss44',
          url: "https://asset.swarovski.com/images/$size_1450/t_swa103/b_rgb:ffffff,c_scale,dpr_1.0,f_auto,w_675/5642976_png_var1/teddy-pendant--bear--pink--rose-gold-tone-plated-swarovski-5642976.png",
        },
        {
          id:'ISAadsasSKkksd-s6d6cvsd6s-44ss44',
          url: "https://asset.swarovski.com/images/$size_1450/t_swa103/b_rgb:ffffff,c_scale,dpr_1.0,f_auto,w_675/5642976_png_var2/teddy-pendant--bear--pink--rose-gold-tone-plated-swarovski-5642976.png",
        },
        {
          id:'ISAadsasSKkksd-s6d6cvsd6s-44ss44',
          url: "https://asset.swarovski.com/images/$size_1450/t_swa002/c_scale,dpr_1.0,f_auto,w_675/5642976_ms1/teddy-pendant--bear--pink--rose-gold-tone-plated-swarovski-5642976.jpg",
        },
      ],
      weight: 16.2,
      colors: [
        {
          id:'ISAjSKkksd-s6d6adsasdsgd6s-44ssgs44',
          name: "rosegold|A",
        },
      ],
      metal: "rosegold|A",
      sizes: [
        {
          id:'ISAjSKkksd-s6d6sd6s-44wrfadsdasdwss44',
          value: '40 - 45cm',
        }
      ],
      description: "",
      popularity: 1,
      customPopularity: 1,
      packaging: 'basic_packaging|A',
      realPhotos: []
    },
    {
      id: 'SWRKLJkdjsjklsdfdfdsfgb-s6d6KLjkjdkwwsd6s-44ss4dsd4',
      count: 1,
      collection: "Swarovski",
      category: "necklaces",
      labelName:  "necklace_name|A",
      brand: "Swarovski",
      price: 3190,
      salePrice: 2099,
      images: [
        {
          id:'ISAjSKkksd-sasasad566kjdkwwsd6s-44ss44',
          url: "https://fs5.deka.ua/photo/goods/popup/409/556035/556035_20240203_1800_1800_swarovski_infinity_necklace_infinity_and_heart_white_mixed_metal_finish_swarovski_5518865__1_.jpg",
        },
        {
          id:'ISAasdjSKkksd-s6d6fsdsd6s-44ss44',
          url: "https://fs5.deka.ua/photo/goods/popup/409/556035/5518865_var1_png.png",
        },
        { 
          id:'ISAjSKkksd-s6d6assd6s-44sadss44',
          url: "https://fs5.deka.ua/photo/goods/popup/409/556035/556035_20240203_1800_1800_swarovski_infinity_necklace_infinity_and_heart_white_mixed_metal_finish_swarovski_5518865__2_.jpg",
        },
        {
          id:'ISAadsasSKkksd-s6d6cvsd6s-44ss44',
          url: "https://fs5.deka.ua/photo/goods/popup/409/556035/556035_20240203_1800_1800_swarovski_infinity_necklace_infinity_and_heart_white_mixed_metal_finish_swarovski_5518865.jpg",
        }
      ],
      weight: 3.4,
      colors: [
        {
          id:'ISAjSKkksd-s6d6adsasdsgd6s-44ssgs44',
          name: "rosegold|A",
        },
      ],
      metal: "rosegold|A",
      sizes: [
        {
          id:'ISAjSKkksd-s6d6sd6s-44wrfadsdasdwss44',
          value: '43 + 5 cm',
        }
      ],
      description: "Вічна романтика: Символи кохання і нескінченності пов'язані воєдино в цьому елегантному кулоні Swarovski. Серце з білого кришталю переплітається з символом нескінченності, покритим рожево-золотистим напиленням, у цьому прекрасному виробі, що наочно демонструє обіцянку вічного кохання. Ідеальний подарунок для вашої другої половинки.|A",
      popularity: 1,
      customPopularity: 1,
      packaging: 'Оригінальна коробка та пакетик Swarovski, сертифікат якості|A',
      realPhotos: []
    },
    {
      id: 'SWARHJdhsk6sdkfjsdhfscsf-s6d6KLjkjdkwwsd6s-44ss4dsd4',
      count: 1,
      collection: "Swarovski",
      category: "necklaces",
      labelName:  "necklace_name|A",
      brand: "Swarovski",
      price: 2690,
      salePrice: 1899,
      images: [
        {
          id:'ISAjSKkksd-sasasad566kjdkwwsd6s-44ss44',
          url: "https://thumbs2.imgbox.com/76/12/NfKh8FIT_t.jpg",
        },
        {
          id:'ISAasdjSKkksd-s6d6fsdsd6s-44ss44',
          url: "https://asset.swarovski.com/images/$size_1450/t_swa103/b_rgb:ffffff,c_scale,dpr_1.0,f_auto,w_675/5669166_png_var1/teddy-pendant--bear--pink--rose-gold-tone-plated-swarovski-5669166.png",
        },
        { 
          id:'ISAjSKkksd-s6d6assd6s-44sadss44',
          url: "https://asset.swarovski.com/images/$size_1450/t_swa103/b_rgb:ffffff,c_scale,dpr_1.0,f_auto,w_675/5669166_png_var2/teddy-pendant--bear--pink--rose-gold-tone-plated-swarovski-5669166.png",
        },
        {
          id:'ISAadsasSKkksd-s6d6cvsd6s-44ss44',
          url: "https://asset.swarovski.com/images/$size_1450/t_swa103/b_rgb:ffffff,c_scale,dpr_1.0,f_auto,w_675/5669166_png_var3/teddy-pendant--bear--pink--rose-gold-tone-plated-swarovski-5669166.png",
        },
        {
          id:'ISAadsasSKkksd-s6d6cvsd6s-44ss44',
          url: "https://asset.swarovski.com/images/$size_1450/t_swa002/c_scale,dpr_1.0,f_auto,w_675/5669166_ms1/teddy-pendant--bear--pink--rose-gold-tone-plated-swarovski-5669166.jpg",
        }
      ],
      weight: 22.5,
      colors: [
        {
          id:'ISAjSKkksd-s6d6adsasdsgd6s-44ssgs44',
          name: "rosegold|A",
        },
      ],
      metal: "rosegold|A",
      sizes: [
        {
          id:'ISAjSKkksd-s6d6sd6s-44wrfadsdasdwss44',
          value: '40 - 45cm',
        }
      ],
      description: "",
      popularity: 1,
      customPopularity: 1,
      packaging: 'basic_packaging|A',
      realPhotos: []
    },

    {
      id: 'PAKJNDKsfd-FDSFDSS-dwedqASwdqwf',
      count: 0,
      collection: "Thomas Sabo",
      category: "necklaces",
      labelName: "necklace_name|A",
      brand: "Pandora",
      price: 2560,
      salePrice: 0,
      images: [
        {
          id: 'PAKJNDKsfd-FDSadFDSS-dwedqplkASwdqwf',
          url: "https://static.e-pandora.ua/22369/conversions/1673429914-webp.webp"
        },
        {
          id: 'PAKJNDKsfd-FDSFasdsDSS-asdcadawe',
          url: "https://static.e-pandora.ua/22371/conversions/1673429914-webp.webp"
        },
        {
          id: 'PAKJNDKsfd-LKgdsksldj-asdcadawe',
          url: "https://static.e-pandora.ua/22370/conversions/1673429914-webp.webp"
        },
        {
          id: 'PAKJNDKsfd-CVBNDKjhds-asdcadawe',
          url: "https://static.e-pandora.ua/22372/conversions/1673429915-webp.webp"
        }
      ],
      weight: 12.7,
      colors: [
        {
          id: 'PAKJNDKsfd-FDS213ewdFDSS-dwedqsadASwdqwf',
          name: "red|A"
        }
      ],
      metal: "silver925|A",
      sizes: [
        {
          id: 'PAKJNDKsfd-dwsa-dwedqASwdqwf',
          value: "45см"
        }
      ],
      description: "",
      popularity: 2,
      customPopularity: 2,
      packaging: "Оригінальна коробка бренду|A",
      realPhotos: []
    },
    {
      id: 'THOMSBNJAD-FDSFDSS-dwedqASwdqwf',
      count: 1,
      collection: "Thomas Sabo",
      category: "necklaces",
      labelName: "necklace_name|A",
      brand: "Thomas Sabo",
      price: 2290,
      salePrice: 0,
      images: [
        {
          id: 'THOMSBNJAD-FDSadFDSS-dwedqplkASwdqwf',
          url: "https://thumbs2.imgbox.com/ef/00/AyNKlKGB_t.jpg"
        },
        {
          id: 'THOMSBNJAD-FDSFasdsDSS-asdcadawe',
          url: "https://thumbs2.imgbox.com/3c/43/K1KwcYoL_t.jpg"
        },
        {
          id: 'THOMSBNJAD-LKgdsksldj-asdcadawe',
          url: "https://thumbs2.imgbox.com/77/cc/9SsRy6Qh_t.jpg"
        },
        {
          id: 'THOMSBNJAD-CVBNDKjhds-asdcadawe',
          url: "https://thumbs2.imgbox.com/a9/fb/sEw9q7I2_t.jpg"
        }
      ],
      weight: 11.3,
      colors: [
        {
          id: 'THOMSBNJAD-FDS213ewdFDSS-dwedqsadASwdqwf',
          name: "silver|A"
        }
      ],
      metal: "silver925|A",
      sizes: [
        {
          id: 'THOMSBNJAD-dwsa-dwedqASwdqwf',
          value: "36 - 38 см"
        }
      ],
      description: "Це ніжне кольє з підвіскою у вигляді чотирилистої конюшини стане справжнім талісманом на вашій шиї. Воно характеризується надзвичайно витонченим дизайном і точним виконанням. Довжина срібного якірного ланцюжка становить 38 см, його можна вкоротити до 36 см. Тому з цим стильним срібним кольє легко створювати нові ювелірні образи. Номер Моделі: KE2037-001-21",
      popularity: 4,
      customPopularity: 4,
      packaging: "Оригінальна коробка бренду|A",
      realPhotos: []
    },
    {
      id: 'POPOSOdjkala-FDSFDSS-dwedqASwdqwf',
      count: 1,
      collection: "Thomas Sabo",
      category: "necklaces",
      labelName: "necklace_name|A",
      brand: "Thomas Sabo",
      price: 4990,
      salePrice: 4585,
      images: [
        {
          id: 'THOMSBNJAD-FDSadFDSS-dwedqplkASwdqwf',
          url: "https://www.thbaker.co.uk/pub/media/catalog/product/cache/e8812041c301388cf8cfa5724aae2db1/0/6/06-02-708-thomas-sabo-silver-iconic-necklace-ke1808-637-21-l67v_1_grey.jpg"
        },
        {
          id: 'THOMSBNJAD-FDSFasdsDSS-asdcadawe',
          url: "https://m.media-amazon.com/images/I/91nXzhnCliL._AC_UY1000_.jpg"
        },
        {
          id: 'THOMSBNJAD-LKgdsksldj-asdcadawe',
          url: "https://www.karat24.net/media/catalog/product/cache/d5bc7642a391ac28974b1e3c17d5b3f8/t/h/thomas-sabo-jewellery-box_4.jpg"
        }
      ],
      weight: 15.3,
      colors: [
        {
          id: 'THOMSBNJAD-FDS213ewdFDSS-dwedqsadASwdqwf',
          name: "silver|A"
        }
      ],
      metal: "silver925|A",
      sizes: [
        {
          id: 'THOMSBNJAD-dwsa-dwedqASwdqwf',
          value: "40 - 45 см"
        }
      ],
      description: "basic_desc|A",
      popularity: 2,
      customPopularity: 2,
      packaging: "basic_packaging|A",
      realPhotos: []
    },
    {
      id: 'PAodoajfkwqf-FDSFDSS-dwedqASwdqwf',
      count: 0,
      collection: "Pandora",
      category: "necklaces",
      labelName: "necklace_name|A",
      brand: "Pandora",
      price: 1895,
      salePrice: 0,
      images: [
        {
          id: 'THOMSBNJAD-FDSadFDSS-dwedqplkASwdqwf',
          url: "https://static.e-pandora.ua/11406/responsive-images/PNGTRPNT_397436CZ_RGB___webp_960_960.webp"
        },
        {
          id: 'THOMSBNJAD-FDSFasdsDSS-asdcadawe',
          url: "https://static.e-pandora.ua/11441/responsive-images/PNGTRPNT_397436CZ_cacidi___webp_960_960.webp"
        },
        {
          id: 'THOMSBNJAD-LKgdsksldj-asdcadawe',
          url: "https://static.e-pandora.ua/11487/responsive-images/PNGTRPNT_397436CZ_eCom_RGB___webp_960_960.webp"
        },
        {
          id: 'THOMSBNJAD-CVBNDKjhds-asdcadawe',
          url: "https://static.e-pandora.ua/16860/responsive-images/PNGTRPNT_397436CZ_ABC123_MODEL_eCOM_02_RGB___webp_960_960.webp"
        }
      ],
      weight: 14.3,
      colors: [
        {
          id: 'THOMSBNJAD-FDS213ewdFDSS-dwedqsadASwdqwf',
          name: "silver|A"
        }
      ],
      metal: "silver925|A",
      sizes: [
        {
          id: 'THOMSBNJAD-dwsa-dwedqASwdqwf',
          value: "45 cm"
        }
      ],
      description: "",
      popularity: 4,
      customPopularity: 4,
      packaging: "Оригінальна коробка бренду|A",
      realPhotos: []
    },
    {
      id: 'PANHJgdjdsjfksdjfs-FDSFDSS-dwedqASwdqwf',
      count: 1,
      collection: "Pandora",
      category: "necklaces",
      labelName: "necklace_name|A",
      brand: "Pandora",
      price: 1895,
      salePrice: 1265,
      images: [
        {
          id: 'THOMSBNJAD-FDSadFDSS-dwedqplkASwdqwf',
          url: "https://static.e-pandora.ua/24742/responsive-images/1704466329___webp_960_960.webp"
        },
        {
          id: 'THOMSBNJAD-FDSFasdsDSS-asdcadawe',
          url: "https://static.e-pandora.ua/24743/responsive-images/1704466334___webp_960_960.webp"
        },
        {
          id: 'THOMSBNJAD-LKgdsksldj-asdcadawe',
          url: "https://static.e-pandora.ua/24744/responsive-images/1704466338___webp_960_960.webp"
        },
        {
          id: 'THOMSBNJAD-CVBNDKjhds-asdcadawe',
          url: "https://static.e-pandora.ua/24967/responsive-images/1704955303___webp_960_960.webp"
        }
      ],
      weight: 4.1,
      colors: [
        {
          id: 'THOMSBNJAD-FDS213ewdFDSS-dwedqsadASwdqwf',
          name: "silver|A"
        }
      ],
      metal: "silver925|A",
      sizes: [
        {
          id: 'THOMSBNJAD-dwsa-dwedqASwdqwf',
          value: "45 cm"
        }
      ],
      description: "Приготуйтесь до вишуканого балу з намистом 'Карета Попелюшки' з колекції Disney x Pandora. Це намисто зі срібла 925-ї проби має деталізовану підвіску у формі карети Попелюшки, яка інкрустована синім каменем у формі 'маркіз' та оточена ажурними деталями. На колесах та на верхівці карети виблискують камінці кубічного оксиду цирконію. Підвіска закріплена на ланцюжку, а намисто регулюється в трьох варіантах довжини. Поєднайте його з відповідними сережками-пусетами 'Карета Попелюшки', щоб створити вишуканий образ.|A",
      popularity: 1,
      customPopularity: 1,
      packaging: "basic_packaging|A",
      realPhotos: []
    },
    {
      id: 'PAN_MICKHJSds-FDSFDSS-dwedqASwdqwf',
      count: 1,
      collection: "Pandora",
      category: "necklaces",
      labelName: "necklace_name|A",
      brand: "Pandora",
      price: 1895,
      salePrice: 1395,
      images: [
        {
          id: 'THOMSBNJAD-FDSadFDSS-dwedqplkASwdqwf',
          url: "https://maple.finchcentrejewellers.com/cdn/shop/products/398233CZ-1.jpg?v=1695392839&width=1000a"
        },
      ],
      weight: 7.3,
      colors: [
        {
          id: 'THOMSBNJAD-FDS213ewdFDSS-dwedqsadASwdqwf',
          name: "silver|A"
        }
      ],
      metal: "silver925|A",
      sizes: [
        {
          id: 'THOMSBNJAD-dwsa-dwedqASwdqwf',
          value: "45 cm"
        }
      ],
      description: "Приготуйтесь до вишуканого балу з намистом 'Карета Попелюшки' з колекції Disney x Pandora. Це намисто зі срібла 925-ї проби має деталізовану підвіску у формі карети Попелюшки, яка інкрустована синім каменем у формі 'маркіз' та оточена ажурними деталями. На колесах та на верхівці карети виблискують камінці кубічного оксиду цирконію. Підвіска закріплена на ланцюжку, а намисто регулюється в трьох варіантах довжини. Поєднайте його з відповідними сережками-пусетами 'Карета Попелюшки', щоб створити вишуканий образ.|A",
      popularity: 1,
      customPopularity: 1,
      packaging: "basic_packaging|A",
      realPhotos: []
    },
    {
      id: 'LJAdkhajwfqe-FDSFDSS-dwedqASwdqwf',
      count: 0,
      collection: "Pandora",
      category: "necklaces",
      labelName: "necklace_name|A",
      brand: "Pandora",
      price: 4495,
      salePrice: 0,
      images: [
        {
          id: 'Panajhdsneck-FDSadFDSS-dwedqplkASwdqwf',
          url: "https://static.e-pandora.ua/18817/responsive-images/PNGTRPNT_399658C01_V2_RGB___webp_960_960.webp"
        },
        {
          id: 'THOMSBNJAD-FDSFasdsDSS-asdcadawe',
          url: "https://static.e-pandora.ua/18819/responsive-images/PNGTRPNT_399658C01_V4_RGB___webp_960_960.webp"
        },
        {
          id: 'THOMSBNJAD-LKgdsksldj-asdcadawe',
          url: "https://static.e-pandora.ua/18818/responsive-images/PNGTRPNT_399658C01_V3_RGB___webp_960_960.webp"
        },
        {
          id: 'THOMSBNJAD-CVBNDKjhds-asdcadawe',
          url: "https://static.e-pandora.ua/18821/responsive-images/PNGTRPNT_399658C01_ABC123_MODEL_eCOM_02_RGB___webp_960_960.webp"
        }
      ],
      weight: 36.8,
      colors: [
        {
          id: 'THOMSBNJAD-FDS213ewdFDSS-dwedqsadASwdqwf',
          name: "silver|A"
        }
      ],
      metal: "silver925|A",
      sizes: [
        {
          id: 'THOMSBNJAD-dwsa-dwedqASwdqwf',
          value: "45 cm"
        }
      ],
      description: "Намисто з прісноводними культивованими перлами Pandora ME — це класична прикраса з перлами у новій інтерпретації, що відкриває безмежні можливості для персоналізації дизайну.",
      popularity: 4,
      customPopularity: 4,
      packaging: "Оригінальна коробка бренду|A",
      realPhotos: []
    },
    {
      id: 'PKJAKJsddsSHdklds-wedf4SA4efe-dwedqASwdqwf',
      count: 1,
      collection: "tiffany",
      category: "necklaces",
      labelName: "necklace_name|A",
      brand: "Tiffany&Co",
      price: 3490,
      salePrice: 0,
      images: [
        {
          id: 'PKJAKklds-A4easdase-dqqwf',
          url: "https://thumbs2.imgbox.com/12/5c/Y6ce3obH_t.png"
        },
        {
          id: 'PKJAKklds-A4eaadsassdase-dqqwf',
          url: "https://thumbs2.imgbox.com/b5/20/23gFDiQf_t.jpg"
        }
      ],
      weight: 13.2,
      colors: [
        {
          id: 'PKJAKklds-A4eaadsassdase-dqasdasdadqwf',
          name: "bluetiffany|A"
        }
      ],
      metal: "silver925|A",
      sizes: [
        {
          id: 'PKsadasadsJAKklds-A4eaadsassdase-dqqwf',
          value: "45 см"
        }
      ],
      description: "string",
      popularity: 3,
      customPopularity: 3,
      packaging: "Оригінальна коробка бренду|A",
      realPhotos: []
    },
    {
      id: 'JDhfASDAisksd-wedf4SA4efe-dwedqASwdqwf',
      count: 0,
      collection: "tiffany",
      category: "necklaces",
      labelName: "necklace_name|A",
      brand: "Tiffany&Co",
      price: 4990,
      salePrice: 0,
      images: [
        {
          id: 'JDhfisksd-aasASDADSASDas-dwedqwdqwf',
          url: "https://thumbs2.imgbox.com/53/4a/5JfAb8E1_t.png"
        },
        {
          id: 'JDhfisksd-wedsadadf44efe-sasasew',
          url: "https://thumbs2.imgbox.com/69/6c/3K8fQqJs_t.png"
        },
        {
          id: 'JDhfisaaaksd-aasASDADSASDas-dwedqwdqwf',
          url: "https://thumbs2.imgbox.com/e0/aa/E2O8WIM3_t.png"
        },
        {
          id: 'JDhfisksd-aasASDADSASDas-dwedqasaaswdqwf',
          url: "https://thumbs2.imgbox.com/d8/1b/Q7COTQ4X_t.jpg"
        }
      ],
      weight: 13.2,
      colors: [
        {
          id: 'JDhfiADASDSAdssdsdsksd-wedSADDSADSAf44efe-dwedqwADSADSADSZXZdqwf',
          name: "bluetiffany|A"
        }
      ],
      metal: "silver925|A",
      sizes: [
        {
          id: 'JDhfiZXZXZXsksd-sdwZXZwww-dw213DSAedqwdqwf',
          value: "45 см"
        }
      ],
      description: "string",
      popularity: 3,
      customPopularity: 3,
      packaging: "Оригінальна коробка бренду|A",
      realPhotos: [
        {
          id: 'qwdqqdw-sdqwadsdsa34-5tgreg5d5sdw',
          url: "https://thumbs2.imgbox.com/20/40/5Hdt55fH_t.jpg"
        },
        {
          id: 'Psisjdskf-sdqweww34-5tgreadsadsg5d5sdw',
          url: "https://thumbs2.imgbox.com/c9/a7/9wY52UfB_t.jpg"
        },
        {
          id: 'Psisjdadaasdskf-sdqw34-5tgreg5dwewe5sdw',
          url: "https://thumbs2.imgbox.com/34/33/rUC68UN7_t.jpg"
        }
      ]
    },
    {
      id: 'JDhfisksd-wedf44efe-dwedqwdqwf',
      count: 1,
      collection: "sif jakobs",
      category: "necklaces",
      labelName: "necklace_name|A",
      brand: "Sif Jakobs",
      price: 4690,
      salePrice: 3990,
      images: [
        {
          id: 'JDhfisksd-aasas-dwedqwdqwf',
          url: "https://live.staticflickr.com/65535/52704645783_7fd8f659d3_z.jpg"
        },
        {
          id: 'JDhfisksd-wedf44efe-sasasew',
          url: "https://live.staticflickr.com/65535/52704414694_c02749ecc5_z.jpg"
        }
      ],
      weight: 11.2,
      colors: [
        {
          id: 'JDhfidssdsdsksd-wedf44efe-dwedqwdqwf',
          name: "silver|A"
        }
      ],
      metal: "silver925|A",
      sizes: [
        {
          id: 'JDhfisksd-sdwwww-dwedqwdqwf',
          value: "42-45 см"
        }
      ],
      description: "string",
      popularity: 11,
      customPopularity: 11,
      packaging: "Оригінальна коробка бренду|A",
      realPhotos: []
    },
    {
      id: 'OSUkdnfkc-wedf44efe-433434fdsd',
      count: 1,
      collection: "sif jakobs",
      category: "necklaces",
      labelName: "necklace_name|A",
      brand: "Sif Jakobs",
      price: 4690,
      salePrice: 3990,
      images: [
        {
          id: 'OSUkdnfkc-wewew2222-433434fdsd',
          url: "https://live.staticflickr.com/65535/52703651742_59404f755a_z.jpg"
        },
        {
          id: 'OSUkdnfkc-wedf44efe-qwqdwdv',
          url: "https://live.staticflickr.com/65535/52704645583_fa2b7ac513_z.jpg"
        },
        {
          id: 'OSUkdnqweqewfkc-wedf44efe-433434fdsd',
          url: "https://live.staticflickr.com/65535/52704645718_6fc272d7c9_z.jpg"
        },
        {id: 'OSUkdnfkc-wedf44eqweefe-433434fdsd',
          url: "https://live.staticflickr.com/65535/52704160416_252a19f24e_z.jpg"
        }
      ],
      weight: 15.2,
      colors: [
        {
          id: 'OSUkdqwewnfkc-wedf44efe-433434fdsd',
          name: "gold|A"
        }
      ],
      metal: "silver925|A",
      sizes: [
        {
          id: 'OSUkdnfkc-wedqwdewdwf22f44efe-433434fdsd',
          value: "42-46 см"
        }
      ],
      description: "string",
      popularity: 12,
      customPopularity: 12,
      packaging: "Оригінальна коробка бренду|A",
      realPhotos: []
    },
    {
      id: 'PIdkdldj-wedfwww44efe-ldkdkj',
      count: 1,
      collection: "sif jakobs Pila Grande",
      category: "necklaces",
      labelName: "necklace_name|A",
      brand: "Sif Jakobs",
      price: 5490,
      salePrice: 4295,
      images: [
        {
          id: 'PIdkdldj-wedasdafwww44efe-ldkdkj',
          url: "https://live.staticflickr.com/65535/52704603460_cbaa1a2297_z.jpg"
        }
      ],
      weight: 11.2,
      colors: [
        {
          id: 'asdsdd-wedfwww44efe-ldkdkj',
          name: "silver|A"
        }
      ],
      metal: "silver925|A",
      sizes: [
        {
          id: 'PIdkdldj-wedfwww44efe-lweedkdkj',
          value: "45 см"
        }
      ],
      description: "string",
      popularity: 13,
      customPopularity: 13,
      packaging: "Оригінальна коробка бренду|A",
      realPhotos: []
    },
    {
      id: 'Powjdk-fghhssdv-ldkdkj',
      count: 1,
      collection: "sif jakobs",
      category: "necklaces",
      labelName: "necklace_name|A",
      brand: "Sif Jakobs",
      price: 7290,
      salePrice: 5590,
      images: [
        {
          id: 'Powjdk-232dfsdfsf-ldkdkj',
          url: "https://live.staticflickr.com/65535/52704645738_d29428af3c_z.jpg"
        },
        {
          id: 'Powjdk-w23dscff-ldkdkj',
          url: "https://live.staticflickr.com/65535/52703637947_3ce5e0a162_z.jpg"
        },
        {
          id: 'Powjdk-fghhssdv-ldkdwqdqdkj',
          url: "https://live.staticflickr.com/65535/52704414534_5a92ca4ed0_z.jpg"
        },
        {
          id: 'Powjdwewk-fghhssdv-ldkdkj',
          url: "https://live.staticflickr.com/65535/52704580190_001153fe0c_z.jpg"
        }
      ],
      weight: 15.2,
      colors: [
        {
          id: 'Powjdk-fghhswefdwsdv-ldkdkj',
          name: "silver|A"
        },
        {
          id: 'Powdewdwwjdk-fghhssdv-ldkdkj',
          name: "rosegold|A"
        }
      ],
      metal: "silver925|A",
      sizes: [
        {
          value: "45 см"
        }
      ],
      description: "string",
      popularity: 14,
      customPopularity: 14,
      packaging: "Оригінальна коробка бренду|A",
      realPhotos: []
    },
    {
      id: 'Msbdkhf333-fghhssdv-ldkdadakj',
      count: 1,
      collection: "Xenox",
      category: "necklaces",
      labelName: "Срібний ланцюжок|A",
      brand: "Xenox",
      price: 1795,
      salePrice: 1495,
      images: [
        {
          id: 'Msbdkhf333-sfwe3-ldkdkj',
          url: "https://live.staticflickr.com/65535/52708302619_21510366f1_z.jpg"
        },
        {
          id: 'Msbdkhf333-fghwerwqefehssdv-ldkdkj',
          url: "https://live.staticflickr.com/65535/52708048946_924a8ff002_z.jpg"
        },
        {
          id: 'wqdqadf-fghhssdv-ldkdkj',
          url: "https://live.staticflickr.com/65535/52708459950_f72992d503_z.jpg"
        }
      ],
      weight: 10.1,
      colors: [
        {
          id: 'Msbdkhf333-fghhssdv-ldkdwefdwefkj',
          name: "silver|A"
        },
        {
          id: 'ewfdefde-fghhssdv-ldkdkj',
          name: "rosegold|A"
        }
      ],
      metal: "silver925|A",
      sizes: [
        {
          id: 'swdqfdfff-fghhssdv-ldkdkj',
          "value": "40-45 cм"
        }
      ],
      description: "string",
      popularity: 5,
      customPopularity: 5,
      packaging: "Оригінальна коробка бренду|A",
      realPhotos: []
    },
    {
      id: 'Msbdkhf333-fghhssdv-ldkdkj',
      count: 1,
      collection: "Xenox",
      category: "necklaces",
      labelName: "Срібний ланцюжок|A",
      brand: "Xenox",
      price: 1795,
      salePrice: 1495,
      images: [
        {
          id: 'Msbdkhf333-sfwe3-ldkdkj',
          url: "https://www.thbaker.co.uk/pub/media/catalog/product/cache/e8812041c301388cf8cfa5724aae2db1/1/3/13-04-164_1.jpg"
        },
      ],
      weight: 10.1,
      colors: [
        {
          id: 'Msbdkhf333-fghhssdv-ldkdwefdwefkj',
          name: "silver|A"
        },
        {
          id: 'ewfdefde-fghhssdv-ldkdkj',
          name: "rosegold|A"
        }
      ],
      metal: "silver925|A",
      sizes: [
        {
          id: 'swdqfdfff-fghhssdv-ldkdkj',
          "value": "40-45 cм"
        }
      ],
      description: "string",
      popularity: 5,
      customPopularity: 5,
      packaging: "Оригінальна коробка бренду|A",
      realPhotos: []
    },
    {
      id: 'BZNJajad77wtd-fghhssdv-ldkdkj',
      count: 1,
      collection: "Xenox",
      category: "necklaces",
      labelName: "Срібний ланцюжок із позолотою|A",
      brand: "Xenox",
      price: 2290,
      salePrice: 1895,
      images: [
        {
          id: 'BZNJajad77wtd-fghhdwfssdv-ldkdkj',
          url: "https://live.staticflickr.com/65535/52708533258_4fae240ee1_z.jpg"
        },
        {
          id: 'asdasdf-fghhssdv-ldkdkj',
          url: "https://live.staticflickr.com/65535/52708048966_c706f5fce3_z.jpg"
        },
        {
          id: 'BZNJajad77wtd-fghhssdv-wdqwdwddw',
          url: "https://live.staticflickr.com/65535/52708459990_c21bbfa18c_z.jpg"
        }
      ],
      weight: 14.1,
      colors: [
        {
          id: 'BZNJajad77wtd-qwdwd-ldkdkj',
          name: "rosegold|A"
        }
      ],
      metal: "silver925|A",
      sizes: [
        {
          id: 'BZNJaja45434d77wtd-fghhssdv-ldkdkj',
          value: "40-45 см"
        }
      ],
      description: "string",
      popularity: 6,
      customPopularity: 6,
      packaging: "Оригінальна коробка бренду|A",
      realPhotos: []
    },
    {
      id: 'BUKADndhdjf-777eedede-ldkdkj',
      count: 0,
      collection: "Bulgari",
      category: "necklaces",
      labelName: "necklace_name|A",
      brand: "Bulgari",
      price: 4990,
      salePrice: 0,
      images: [
        {
          id: 'BUKADndhdjf-qwdeqw-ldkdkj',
          url: "https://live.staticflickr.com/65535/52952777909_cd7658abc9_z.jpg"
        },
        {
          id: 'BUKADndhdjf-777eedede-wddqdqwd',
          url: "https://live.staticflickr.com/65535/52953092828_98febf0fc0_z.jpg"
        },
        {
          id: 'BUKADndhdjf-wddwffs-ldkdkj',
          url: "https://thumbs2.imgbox.com/8f/7d/b5p9APlm_t.jpg"
        },
        {
          id: 'BUKADndhdjf-wddwsadasffs-ldkdkj',
          url: "https://thumbs2.imgbox.com/7d/c0/eu1iqpON_t.jpg"
        }
      ],
      weight: 14.2,
      colors: [
        {
          id: 'BUKADndhdjf-777eedede-qwqwdfgvdws',
          name: "silver|A"
        }
      ],
      metal: "silver925|A",
      sizes: [
        {
          id: 'BUKADndhdjf-qdeqdqgfwscdv-ldkdkj',
          value: "45 - 50см"
        }
      ],
      description: "Ультрасучасна інтерпретація знаменитої ікони гламуру та спокуси від Bvlgari. Абстрагуючись від міфічної змії за допомогою геометричних і витончених форм, кулон намотується на ланцюжок, вражаючи дорогоцінною красою луски і характерною для колекції Serpenti звивистістю. Кольє Serpenti Viper виготовлене із срібла 925 та інкрустоване камінцями цирконію класу ААА",
      popularity: 2,
      customPopularity: 2,
      packaging: "Оригінальна коробка бренду|A",
      realPhotos: [
        {
          id: 'BUDndhdjf-wdadsadswedwffs-ldkewg436dkj',
          url: "https://thumbs2.imgbox.com/06/b9/yuxmjTLJ_t.jpg"
        },
        {
          id: 'BUKAD123adsvbnmndhdjf-wddncxvwffs-ldkadfsgdkj',
          url: "https://thumbs2.imgbox.com/ab/f6/1yTgkd9m_t.jpg"
        },
        {
          id: 'BUKAD123adadssndhdjf-wddw876ffs-ldkdgfsdkj',
          url: "https://thumbs2.imgbox.com/47/a2/juQDt2Wm_t.jpg"
        },
        {
          id: 'BUKAD123adsjlkhjghndhdjf-wddhgjkhwffs-lddasfdkj',
          url: "https://thumbs2.imgbox.com/bc/1f/0geUOrQH_t.jpg"
        },
        {
          id: 'BUKAD123ajtrhtgefdsndhdjf-wddfsasdwffs-ldkdlkhjggkj',
          url: "https://thumbs2.imgbox.com/bc/8e/7lrhByGJ_t.jpg"
        },
        {
          id: 'BUKAD123adsndhdjf-wddw1ads32edasffs-ldkhjgkhdkj',
          url: "https://thumbs2.imgbox.com/9b/32/cSWTF4kP_t.jpg"
        },
        {
          id: 'BUKAD123adsndhdjf-wddadsadswffs-ldasdkdkj',
          url: "https://thumbs2.imgbox.com/a6/20/1rK5jlO2_t.jpg"
        },
        {
          id: 'Basd123adsndhdjf-wddwffs-ldkdkj',
          url: "https://thumbs2.imgbox.com/52/07/Io1LvogT_t.jpg"
        }
      ]
    },

    // EARRINGS

    {
      id: 'PNAHSHJHKDJKHuywuifem-sdsdfsf-ldkdkj',
      count: 1,
      collection: "Spring`23",
      category: "earrings",
      labelName: "earrings_name|A",
      brand: "Pandora",
      price: 1390,
      salePrice: 995,
      images: [
        {
          id: 'PNAHSHJHKDJKHuywuifem-we23edws-ldkdkj',
          url: "https://www.nzpandora.co.nz/images/large/60721206437448/Pandora_Pandora_Signature_Twotone_Logo_C_1028_ZOOM.jpg"
        }
      ],
      weight: 4.2,
      colors: [
        {
          id: 'PNAHSHJHKDJKHuywuifem-sdsdfsf-ldkdkj',
          name: "rosegold|A"
        }
      ],
      metal: "silver925|A",
      sizes: [
        {
          id: 'PNAHSHJHKDJKHuywuifem-dwdw-ldkdkj',
          value: "єдиний розмір"
        }
      ],
      description: "none",
      popularity: 1,
      customPopularity: 1,
      packaging: "basic_packaging|A",
      realPhotos: []
    },
    {
      id: 'PAMKJKDSAJKADSWDasdasmk-sdsdfsf-ldkdkj',
      count: 1,
      collection: "Spring`23",
      category: "earrings",
      labelName: "earrings_name|A",
      brand: "Pandora",
      price: 1790,
      salePrice: 1395,
      images: [
        {
          id: 'EARDKSHSDJss-we23edws-ldkdkj',
          url: "https://static.e-pandora.ua/22935/responsive-images/1681155476___webp_960_960.webp"
        },
        {
          id: 'EARDKSHSDJss-sdswewdfsf-ldkdkj',
          url: "https://static.e-pandora.ua/22936/responsive-images/1681155477___webp_960_960.webp"
        },
        {
          id: 'EARDKSHSDJss-we23edws-ldkdkj',
          url: "https://static.e-pandora.ua/22937/responsive-images/1681155477___webp_960_960.webp"
        },
        {
          id: 'EARDKSHSDJss-sdswewdfsf-ldkdkj',
          url: "https://static.e-pandora.ua/22938/responsive-images/1681155477___webp_960_960.webp"
        }
      ],
      weight: 4.2,
      colors: [
        {
          id: 'EARDKSHS1DJss-sdsdfsf-ldkdkj',
          name: "rosegold|A"
        }
      ],
      metal: "silver925|A",
      sizes: [
        {
          id: 'EARDKSHSDJss-dwdw-ldkdkj',
          value: "єдиний розмір"
        }
      ],
      description: "none",
      popularity: 1,
      customPopularity: 1,
      packaging: "basic_packaging|A",
      realPhotos: []
    },
    {
      id: 'PANROslkaldjasjdof-sdsdfsf-ldkdkj',
      count: 1,
      collection: "Spring`23",
      category: "earrings",
      labelName: "earrings_name|A",
      brand: "Pandora",
      price: 1090,
      salePrice: 895,
      images: [
        {
          id: 'EARDKSHSDJss-we23edws-ldkdkj',
          url: "https://static.e-pandora.ua/22562/responsive-images/1676374922___webp_960_960.webp"
        },
        {
          id: 'EARDKSHSDJss-sdswewdfsf-ldkdkj',
          url: "https://static.e-pandora.ua/22564/responsive-images/1676374923___webp_960_960.webp"
        },
        {
          id: 'EARDKSHSDJss-we23edws-ldkdkj',
          url: "https://static.e-pandora.ua/22563/responsive-images/1676374923___webp_960_960.webp"
        }
      ],
      weight: 2.3,
      colors: [
        {
          id: 'EARDKSHS1DJss-sdsdfsf-ldkdkj',
          name: "rosegold|A"
        }
      ],
      metal: "silver925|A",
      sizes: [
        {
          id: 'EARDKSHSDJss-dwdw-ldkdkj',
          value: "єдиний розмір"
        }
      ],
      description: "none",
      popularity: 1,
      customPopularity: 1,
      packaging: "basic_packaging|A",
      realPhotos: []
    },
    {
      id: 'EAJKHSJAFKssdfas-sdsdfsf-ldkdkj',
      count: 0,
      collection: "Spring`23",
      category: "earrings",
      labelName: "earrings_name|A",
      brand: "Pandora",
      price: 1560,
      salePrice: 0,
      images: [
        {
          id: 'EARDKSHSDJss-we23edws-ldkdkj',
          url: "https://static.e-pandora.ua/22361/conversions/1673380927-webp.webp"
        },
        {
          id: 'EARDKSHSDJss-sdswewdfsf-ldkdkj',
          url: "https://static.e-pandora.ua/22362/conversions/1673380927-webp.webp"
        }
      ],
      weight: 4.2,
      colors: [
        {
          id: 'EARDKSHS1DJss-sdsdfsf-ldkdkj',
          name: "red|A"
        }
      ],
      metal: "silver925|A",
      sizes: [
        {
          id: 'EARDKSHSDJss-dwdw-ldkdkj',
          value: "єдиний розмір"
        }
      ],
      description: "none",
      popularity: 2,
      customPopularity: 2,
      packaging: "basic_packaging|A",
      realPhotos: []
    },
    {
      id: 'EARDKSHSDJss-sdsdfsf-ldkdkj',
      count: 0,
      collection: "Spring`23",
      category: "earrings",
      labelName: "earrings_name|A",
      brand: "Pandora",
      price: 1090,
      salePrice: 0,
      images: [
        {
          id: 'EARDKSHSDJss-we23edws-ldkdkj',
          url: "https://live.staticflickr.com/65535/52644216216_e3954f8205_z.jpg"
        },
        {
          id: 'EARDKSHSDJss-sdswewdfsf-ldkdkj',
          url: "https://live.staticflickr.com/65535/52644689223_9f39d3bfe3_z.jpg"
        },
        {
          id: 'EARDKSHSDJss-sdsdfsf-ldkd2kj',
          url: "https://live.staticflickr.com/65535/52644216186_49aa5e206e_z.jpg"
        }
      ],
      weight: 7.2,
      colors: [
        {
          id: 'EARDKSHS1DJss-sdsdfsf-ldkdkj',
          name: "silver|A"
        },
        {
          id: 'EARDKSHSDJss-sds2e2dfsf-ldkdkj',
          name: "gold|A"
        },
        {
          id: 'EARDKSHSwdwdDJss-sdsdfsf-ldkdkj',
          name: "rosegold|A"
        }
      ],
      metal: "silver925|A",
      sizes: [
        {
          id: 'EARDKSHSDJss-dwdw-ldkdkj',
          value: "єдиний розмір"
        }
      ],
      description: "none",
      popularity: 10,
      customPopularity: 10,
      packaging: "Оригінальна коробка бренду|A",
      realPhotos: []
    },
    {
      id: 'TAHDjdsa-sdsdfwdsf-ldkdkj',
      count: 1,
      collection: "Spring`23",
      category: "earrings",
      labelName: "earrings_name|A",
      brand: "Pandora",
      price: 1765,
      salePrice: 1195,
      images: [
        {
          id: 'TAHDjdqeqsa-sdsdfwdsf-ldkdkj',
          url: "https://live.staticflickr.com/65535/52643708052_2047f0dccb_z.jpg"
        },
        {
          id: 'TAHDjdqeqsa-sdsdfwdsf-ldkdkj',
          url: "https://static.e-pandora.ua/17586/responsive-images/PNGTRPNT_289052C01_RGB___webp_960_960.webp"
        },
        {
          id: 'TAHDjdsa-sdsqwqdfwdsf-ldkdkj',
          url: "https://uk.pandora.net/dw/image/v2/BFCR_PRD/on/demandware.static/-/Sites-pandora-master-catalog/default/dw74081e7d/productimages/modelshot/299052C01_ABC123_MODEL_eCOM_01_RGB.jpg?sw=900&sh=900&sm=fit&sfrm=png&bgcolor=F2F2F2"
        }
      ],
      weight: 7.2,
      colors: [
        {
          id: 'TAHD2eqjdsa-sdsdfwdsf-ldkdkj',
          name: "silver|A"
        },
        {
          id: 'TAHD2eqjdsa-sdsdfwdsf-ldkdkj',
          name: "rosegold|A"
        }
      ],
      metal: "silver925|A",
      sizes: [
        {
          id: 'TAHDjdsa-sdsdfwdsf-ld2ewkdkj',
          value: "єдиний розмір"
        }
      ],
      description: "none",
      popularity: 11,
      customPopularity: 11,
      packaging: "basic_desc|A",
      realPhotos: []
    },
    {
      id: 'PANSIAsklfdjsaldada-sdsdfwdsf-ldkdkj',
      count: 1,
      collection: "Spring`23",
      category: "earrings",
      labelName: "earrings_name|A",
      brand: "Pandora",
      price: 1190,
      salePrice: 0,
      images: [
        {
          id: 'TAHDjdqeqsa-sdsdfwdsf-ldkdkj',
          url: "https://uk.pandora.net/dw/image/v2/BFCR_PRD/on/demandware.static/-/Sites-pandora-master-catalog/default/dwfdc44d6e/productimages/singlepackshot/292545C01_RGB.jpg?sw=900&sh=900&sm=fit&sfrm=png&bgcolor=F2F2F2"
        },
        {
          id: 'TAHDjdqeqsa-sdsdfwdsf-ldkdkj',
          url: "https://uk.pandora.net/dw/image/v2/BFCR_PRD/on/demandware.static/-/Sites-pandora-master-catalog/default/dw527dc812/productimages/modelshot/292545C01_ABC123_MODEL_eCOM_01_RGB.jpg?sw=900&sh=900&sm=fit&sfrm=png&bgcolor=F2F2F2"
        }
      ],
      weight: 7.2,
      colors: [
        {
          id: 'TAHD2eqjdsa-sdsdfwdsf-ldkdkj',
          name: "silver|A"
        }
      ],
      metal: "silver925|A",
      sizes: [
        {
          id: 'TAHDjdsa-sdsdfwdsf-ld2ewkdkj',
          value: "єдиний розмір"
        }
      ],
      description: "none",
      popularity: 11,
      customPopularity: 11,
      packaging: "Оригінальна коробка бренду|A",
      realPhotos: []
    },
    {
      id: 'Pkdjdlf-sdsdfwdsf-ldkdkj',
      count: 1,
      collection: "Esprit",
      category: "earrings",
      labelName: "earrings_name|A",
      brand: "Esprit",
      price: 1490,
      salePrice: 0,
      images: [
        {
          id: 'Pkdjdlf-e2dsacs-ldkdkj',
          url: "https://live.staticflickr.com/65535/52713531678_5f118c7b1d_z.jpg"
        },
        {
          id: 'Pkdjdlf-sdsdfqwdswdsf-ldkdkj',
          url: "https://live.staticflickr.com/65535/52713473110_4dd9831fe0_z.jpg"
        }
      ],
      weight: 9.5,
      colors: [
        {
          id: 'Pkdjdlf-sdsdfwdsf-ldkqwqvwwdkj',
          name: "silver|A"
        }
      ],
      metal: "silver925|A",
      sizes: [
        {
          id: 'Pkdjdlf-ddsdsad2323-ldkdkj',
          value: "єдиний розмір"
        }
      ],
      description: "none",
      popularity: 6,
      customPopularity: 6,
      packaging: "Оригінальна коробка бренду|A",
      realPhotos: []
    },
    {
      id: 'TPshdklfwq-2ewdcsa22ed-lddasdkdkj',
      count: 0,
      collection: "Pandora",
      category: "earrings",
      labelName: "earrings_name|A",
      brand: "Pandora",
      price: 1090,
      salePrice: 0,
      images: [
        {
          id: 'TPshdklfwq-qwdeqwdsc-lddasdkdkj',
          url: "https://live.staticflickr.com/65535/52644658310_63aa6d4791_z.jpg"
        }
      ],
      weight: 7.2,
      colors: [
        {
          id: 'TPshdklfwq-dqdqw2eds-lddasdkdkj',
          name: "gold|A"
        }
      ],
      metal: "silver925|A",
      sizes: [
        {
          id: 'TPshdklfwq-wdqsdsfw-lddasdkdkj',
          value: "єдиний розмір"
        }
      ],
      description: "none",
      popularity: 12,
      customPopularity: 12,
      packaging: "Оригінальна коробка бренду|A",
      realPhotos: []
    },
    {
      id: 'OIsmsjdff322-dsaccawda-lddasdkdkj',
      count: 1,
      collection: "Sif Jakobs",
      category: "earrings",
      labelName: "earrings_name|A",
      brand: "Sif Jakobs",
      price: 1990,
      salePrice: 0,
      images: [
        {
          id: 'OIsmsjdff322-qwdqwd-lddasdkdkj',
          url: "https://live.staticflickr.com/65535/52722966281_fe9cd7d011_z.jpg"
        },
        {
          id: 'OIsmsjdff322-dsacwedwscawda-lddasdkdkj',
          url: "https://live.staticflickr.com/65535/52723441368_b61d86bf5d_z.jpg"
        },
        {
          id: 'OIsmsjdff322-dsacc22ewedcawda-lddasdkdkj',
          url: "https://live.staticflickr.com/65535/52722435857_206fe8c762_z.jpg"
        }
      ],
      weight: 8.4,
      colors: [
        {
          id: 'OIsmsjdff322-dsaccawda-754tgdvsd',
          name: "black|A"
        }
      ],
      metal: "silver925|A",
      sizes: [
        {
          id: 'OIsmsjdff322-d8w7f8dshi-lddasdkdkj',
          "value": "єдиний розмір"
        }
      ],
      description: "none",
      popularity: 5,
      customPopularity: 3,
      packaging: "Оригінальна коробка бренду|A",
      realPhotos: []
    },
    {
      id: 'Oisjdsnuvc-ew545tef-lddasdkdkj',
      count: 0,
      collection: "gift",
      category: "earrings",
      labelName: "earrings_name|A",
      brand: "Pandora",
      price: 790,
      salePrice: 0,
      images: [
        {
          id: 'Oisjdsnuvc-ew545tef-lddas222dkdkj',
          url: "https://live.staticflickr.com/65535/52740382445_35eff8fd23_z.jpg"
        },
        {
          id: 'Oisjdsnuvc-ew52e4rfdss45tef-lddasdkdkj',
          url: "https://live.staticflickr.com/65535/52740459908_5da1d9f4f0_z.jpg"
        },
        {
          id: 'Oisjdsnuvc-ew545tef-lddas222dkdkj',
          url: "https://static.e-pandora.ua/18787/responsive-images/PNGTRPNT_299682C01_ABC123_MODEL_eCOM_01_RGB___webp_960_960.webp"
        },
      ],
      weight: 4.4,
      colors: [
        {
          id: 'Oisjdsnuvc-ew54654545tef-lddasdkdkj',
          name: "silver|A"
        }
      ],
      metal: "silver925|A",
      sizes: [
        {
          id: 'Oisjdsnuvc-wfesdaqwd-lddasdkdkj',
          value: "єдиний розмір"
        }
      ],
      description: "Срібна сережка інкрустована камінцями Цирконію. Її можна носити як на одному вушку, так і на двох - усе на ваш вибір. Тому ми вказали ціну за одну штуку",
      popularity: 5,
      customPopularity: 3,
      packaging: "Оригінальний мішечок бренду та пакет TVOI",
      realPhotos: []
    },
    {
      id: 'Nbsmnasdkh-wedscs-lddasdkdkj',
      count: 0,
      collection: "gift",
      category: "earrings",
      labelName: "earrings_name|A",
      brand: "Pandora",
      price: 1250,
      salePrice: 0,
      images: [
        {
          id: 'Nbsmnasdkh-wdqwdqsqa-lddasdkdkj',
          url: "https://live.staticflickr.com/65535/52740587305_98d8ac6101_z.jpg"
        },
        {
          id: 'Nbsmnasdkh-wewdwdsc2ews-lddasdkdkj',
          url: "https://live.staticflickr.com/65535/52740665883_b61f0ed407_z.jpg"
        }
      ],
      weight: 4.8,
      colors: [
        {
          id: 'Nbsmnasdkh-fdwdqdqws-lddasdkdkj',
          name: "rosegold|A"
        }
      ],
      metal: "silver925|A",
      sizes: [
        {
          id: 'Nbsmnasdkh-wedscs-lddwdqwdasdkdkj',
          value: "єдиний розмір"
        }
      ],
      description: "Срібні сережки у вигляді квітки із тонким напиленням рожевого золота 18к та вставками кубічного цирконію. Любов та ніжність у всіх її проявах",
      popularity: 5,
      customPopularity: 3,
      packaging: "Оригінальний мішечок бренду та пакет TVOI",
      realPhotos: []
    },
    {
      id: 'VZbahgdsdsd-wedscs-lddasdkdkj',
      count: 0,
      collection: "gift",
      category: "earrings",
      labelName: "earrings_name|A",
      brand: "Pandora",
      price: 990,
      salePrice: 0,
      images: [
        {
          id: 'VZbahgdsdsd-dwdqsd-lddasdkdkj',
          url: "https://live.staticflickr.com/65535/52740184826_e45ee5ef1b_z.jpg"
        },
        {
          id: 'VZbahgdsdsd-wedscs-dqdqs2edss',
          url: "https://live.staticflickr.com/65535/52740438174_c8404a6867_z.jpg"
        }
      ],
      weight: 4.1,
      colors: [
        {
          id: 'VZbahgdsdsd-qdwqsds-lddasdkdkj',
          name: "silver|A"
        }
      ],
      metal: "silver925|A",
      sizes: [
        {
          id: 'wdqdqwds-wedscs-lddasdkdkj',
          value: "єдиний розмір"
        }
      ],
      description: "Срібні сережки-пусети інкрустовані голубими Цирконіями, кольором, який символізує спокій та зосередженість",
      popularity: 10,
      customPopularity: 10,
      packaging: "Оригінальний мішечок бренду та пакет TVOI",
      realPhotos: []
    },
    {
      id: 'Bajkadhks-wed2322scs-lddasdkdkj',
      count: 1,
      collection: "gift",
      category: "earrings",
      labelName: "earrings_name|A",
      brand: "s.Oliver",
      price: 1995,
      salePrice: 0,
      images: [
        {
          id: 'Ba23wedwjkadhks-wed2322scs-lddasdkdkj',
          url: "https://live.staticflickr.com/65535/52771296792_3c1987bf94_z.jpg"
        },
        {
          id: 'Bajkadhks-wedqwdqwd-lddasdkdkj',
          url: "https://live.staticflickr.com/65535/52772313978_7308d94449_z.jpg"
        }
      ],
      weight: 4.1,
      colors: [
        {
          id: 'Bajkadhks-wed2322scs-w23dscasd',
          name: "silver|A"
        }
      ],
      metal: "silver 925",
      sizes: [
        {
          id: 'Bajkadhks-wed2322scs-d5d5asds',
          value: "єдиний розмір"
        }
      ],
      description: "Срібні сережки інкрустовані білими Цирконіями. Адже вона цього варта",
      popularity: 4,
      customPopularity: 3,
      packaging: "Оригінальна коробка бренду|A",
      realPhotos: []
    },
    {
      id: 'Zcsdwfs4w5wd-wed2322scs-lddasdkdkj',
      count: 1,
      collection: "gift",
      category: "earrings",
      labelName: "earrings_name|A",
      brand: "xenox",
      price: 1090,
      salePrice: 0,
      images: [
        {
          id: 'Zcsdwfs4w5wd-5ew4d5we4df5-lddasdkdkj',
          url: "https://live.staticflickr.com/65535/52774803221_d13e928fea_z.jpg"
        },
        {
          id: 'Zcsdwfs4w5wd-dqwds-lddasdkdkj',
          url: "https://live.staticflickr.com/65535/52775060429_6349346c60_z.jpg"
        },
        {
          id: 'Zcsdwfs4w5wd-sdcascasdw-lddasdkdkj',
          url: "https://live.staticflickr.com/65535/52774803196_4596ce4c98_z.jpg"
        }
      ],
      weight: 4.1,
      colors: [
        {
          id: 'Zcsdwfs4w5wd-wed2322scs-dwsdsad',
          name: "silver|A"
        },
        {
          id: 'Zcsdwfs4w5wd-qdsads2eds-lddasdkdkj',
          name: "red|A"
        }
      ],
      metal: "silver925|A",
      sizes: [
        {
          id: 'Zcsdwfs4w5wd-sdqsdvqwds-lddasdkdkj',
          value: "єдиний розмір"
        }
      ],
      description: "Срібні базові сережки-пусети, доступні у двох кольорах. Ідеальний варіант на кожен день під будь-який образ",
      popularity: 2,
      customPopularity: 3,
      packaging: "Чорна коробка під сережки та фірмовий пакет TVOI",
      realPhotos: []
    },
    {
      id: 'Cvshdhasd22-wed2322scs-lddasdkdkj',
      count: 1,
      collection: "gift",
      category: "earrings",
      labelName: "earrings_name|A",
      brand: "Sif Jakobs",
      price: 3995,
      salePrice: 0,
      images: [
        {
          id: 'Cvshdhasd22-dqdsdqs-lddasdkdkj',
          url: "https://live.staticflickr.com/65535/52813149925_608077f0fb_z.jpg"
        },
        {
          id: 'Cvshdhasd22-wed2322scs-sadsqs',
          url: "https://live.staticflickr.com/65535/52812739666_0c74edb8d8_z.jpg"
        },
        {
          id: 'Cvshdhasd22-wdqwdq-lddasdkdkj',
          url: "https://live.staticflickr.com/65535/52812181997_89f1d72156_z.jpg"
        }
      ],
      weight: 4.3,
      colors: [
        {
          id: 'Cvshdhasd22-Sdqds-lddasdkdkj',
          name: "gold|A"
        }
      ],
      metal: "silver925|A",
      sizes: [
        {
          id: 'Cvshdhasd22-wed2322scs-dqwdqqwdd',
          value: "єдиний розмір"
        }
      ],
      description: "Довершені найкращими ювелірами від Sif Jakobs ці сережки із срібла з позолотою 18к та інкрустацією камінців цирконію із діамантовою огранкою - це вишуканість та простота водночас - ідеальний варіант для Неї",
      popularity: 5,
      customPopularity: 5,
      packaging: "Оригінальна коробка бренду|A",
      realPhotos: []
    },
    {
      id: 'Fsjakdhgud-wed2322scs-lddasdkdkj',
      count: 0,
      collection: "Pandora",
      category: "earrings",
      labelName: "earrings_name|A",
      brand: "Pandora",
      price: 1190,
      salePrice: 0,
      images: [
        {
          id: 'Fsjakdhgud-qwdqdsq-lddasdkdkj',
          
          url: "https://live.staticflickr.com/65535/52885473852_79d11534d6_z.jpg"
        },
        {
          id: 'wdasdsadsa-wed2322scs-lddasdkdkj',
          url: "https://live.staticflickr.com/65535/52886427685_95f4329c40_z.jpg"
        },
        {
          id: 'Fsjakdhgud-wed2322scs-KHudsguds',
          url: "https://live.staticflickr.com/65535/52886212789_6d47b74330_z.jpg"
        }
      ],
      weight: 4.4,
      colors: [
        {
          id: 'Fsjakdhgud-Kjdwjhdw645-lddasdkdkj',
          name: "rosegold|A"
        }
      ],
      metal: "silver925|A",
      sizes: [
        {
          id: 'Fsjakdhgud-das5d4a65-lddasdkdkj',
          "value": "єдиний розмір"
        }
      ],
      description: "Універсальні срібні сережки для будь-якого образу. Ці кільця інкрустовано прозорим камінцем кубічного оксиду цирконію завдяки чому ви можете носити їх під будь-який образ, залежно від настрою",
      popularity: 10,
      customPopularity: 10,
      packaging: "basic_packaging|A",
      realPhotos: []
    },
    {
      id: 'XENOXSKJDJD-wed2322scs-lddasdkdkj',
      count: 1,
      collection: "gift",
      category: "earrings",
      labelName: "earrings_name|A",
      brand: "xenox",
      price: 1090,
      salePrice: 0,
      images: [
        {
          id: 'XENOXSKJDJD-5ew4d5we4df5-lddasdkdkj',
          url: "https://thumbs2.imgbox.com/70/75/aClTKrTs_t.png"
        },
        {
          id: 'XENOXSKJDJD-dqwds-lddasdkdkj',
          url: "https://thumbs2.imgbox.com/53/13/FGl2axPY_t.png"
        },
      ],
      weight: 4.1,
      colors: [
        {
          id: 'XENOXSKJDJD-wed2322scs-dwsdsad',
          name: "silver|A"
        },
        {
          id: 'XENOXSKJDJD-qdsads2eds-lddasdkdkj',
          name: "rosegold|A"
        }
      ],
      metal: "silver925|A",
      sizes: [
        {
          id: 'XENOXSKJDJD-sdqsdvqwds-lddasdkdkj',
          value: "єдиний розмір"
        }
      ],
      description: "Срібні сережки у вигляді крил, доступні у двох кольорах. Ідеальний варіант на кожен день під будь-який образ",
      popularity: 2,
      customPopularity: 2,
      packaging: "Чорна коробка під сережки та фірмовий пакет TVOI",
      realPhotos: []
    },
    
  // RINGS

  {
    id: 'GOLDSLJDSJKfsder-LJKcdjskldsvsdvv-lddasdkdkj',
    count: 1,
    collection: "Spring`23",
    category: "rings",
    labelName: "ring_name|A",
    brand: "TVOI",
    price: 1799,
    salePrice: 1099,
    images: [
      {
        id: 'IYUdiuaofncksv-ringtyasduad-asdasdadsa',
        url: "https://thumbs2.imgbox.com/82/2f/QWc62fVv_t.jpg"
      },
      {
        id: 'IYUdiuaofncksv-ringtyasduad-asdasdadsa',
        url: "https://thumbs2.imgbox.com/20/fd/gWmkIbL1_t.jpg"
      },
      {
        id: 'IYUdiuaofncksv-ringtyasduad-asdasdadsa',
        url: "https://thumbs2.imgbox.com/9a/eb/Z8dAQx1Q_t.jpg"
      },
    ],
    weight: 4.3,
    colors: [
      {
        id: 'PAndjsifjis-Roswrkflds-lddasdkdkj',
        name: "silver|A"
      },
      {
        id: 'PAndjsifjis-Roswrkflds-lddasdkdkj',
        name: "gold|A"
      },
    ],
    metal: "Ювелірна Сталь|A",
    sizes: [
      {
        id: '5&PDJksdsd-KLJdkhkjfscfsc-lddasdkdkj',
        value: "50"
      },
      {
        id: '5&PDJksdsd-KLJdkhkjfscfsc-lddasdkdkj',
        value: "52"
      },
      {
        id: '5&PDJksdsd-KLJdkhkjfscfsc-lddasdkdkj',
        value: "54"
      },
    ],
    description: "",
    popularity: 1,
    customPopularity: 1,
    packaging: "Оригінальний мішечок бренду та фірмовий пакет Tvoi",
    realPhotos: []
  },

  {
    id: 'TVOPSOHDSJDKefw-LJKcdjskldsvsdvv-lddasdkdkj',
    count: 1,
    collection: "Spring`23",
    category: "rings",
    labelName: "ring_name|A",
    brand: "TVOI",
    price: 1799,
    salePrice: 1099,
    images: [
      {
        id: 'IYUdiuaofncksv-ringtyasduad-asdasdadsa',
        url: "https://thumbs2.imgbox.com/44/30/LvQ2SIYa_t.jpg"
      },
      {
        id: 'IYUdiuaofncksv-ringtyasduad-asdasdadsa',
        url: "https://thumbs2.imgbox.com/d2/9a/34g4ySie_t.jpg"
      },
      {
        id: 'IYUdiuaofncksv-ringtyasduad-asdasdadsa',
        url: "https://thumbs2.imgbox.com/37/90/3sy1eeEB_t.jpg"
      },
    ],
    weight: 4.4,
    colors: [
      {
        id: 'PAndjsifjis-Roswrkflds-lddasdkdkj',
        name: "gold|A"
      },
    ],
    metal: "Ювелірна Сталь|A",
    sizes: [
      {
        id: '5&PDJksdsd-KLJdkhkjfscfsc-lddasdkdkj',
        value: "50"
      },
      {
        id: '5&PDJksdsd-KLJdkhkjfscfsc-lddasdkdkj',
        value: "52"
      },
      {
        id: '5&PDJksdsd-KLJdkhkjfscfsc-lddasdkdkj',
        value: "54"
      },
    ],
    description: "",
    popularity: 1,
    customPopularity: 1,
    packaging: "Оригінальний мішечок бренду та фірмовий пакет Tvoi",
    realPhotos: []
  },

  {
    id: 'LSJDklajfjsdkf-LJKcdjskldsvsdvv-lddasdkdkj',
    count: 1,
    collection: "Spring`23",
    category: "rings",
    labelName: "ring_name|A",
    brand: "Pandora",
    price: 1290,
    salePrice: 1097,
    images: [
      {
        id: 'IYUdiuaofncksv-ringtyasduad-asdasdadsa',
        url: "https://us.pandora.net/dw/image/v2/AAVX_PRD/on/demandware.static/-/Sites-pandora-master-catalog/default/dw92371bc2/productimages/singlepackshot/198421C02_V2_RGB.jpg?sw=900&sh=900&sm=fit&sfrm=png&bgcolor=F2F2F2"
      },
      {
        id: 'IYUdiuaofncksv-ringtyasduad-asdasdadsa',
        url: "https://us.pandora.net/dw/image/v2/AAVX_PRD/on/demandware.static/-/Sites-pandora-master-catalog/default/dw3e5893d3/productimages/singlepackshot/198421C02_V5_RGB.jpg?sw=900&sh=900&sm=fit&sfrm=png&bgcolor=F2F2F2"
      },
      {
        id: 'IYUdiuaofncksv-ringtyasduad-asdasdadsa',
        url: "https://us.pandora.net/dw/image/v2/AAVX_PRD/on/demandware.static/-/Sites-pandora-master-catalog/default/dwea50e4f1/productimages/modelshot/SS24_A_PDP_Model_Styled_33_1x1_RGB.jpg?sw=900&sh=900&sm=fit&sfrm=png&bgcolor=F2F2F2"
      },
    ],
    weight: 6.3,
    colors: [
      {
        id: 'PAndjsifjis-Roswrkflds-lddasdkdkj',
        name: "red|A"
      }
    ],
    metal: "silver925|A",
    sizes: [
      {
        id: '5&PDJksdsd-KLJdkhkjfscfsc-lddasdkdkj',
        value: "54"
      },
    ],
    description: "",
    popularity: 1,
    customPopularity: 1,
    packaging: "Оригінальний мішечок бренду та фірмовий пакет Tvoi",
    realPhotos: []
  },
  {
    id: 'PANPRINHJSHDShdjs-LJKcdjskldsvsdvv-lddasdkdkj',
    count: 1,
    collection: "Spring`23",
    category: "rings",
    labelName: "ring_name|A",
    brand: "Pandora",
    price: 1495,
    salePrice: 1165,
    images: [
      {
        id: 'IYUdiuaofncksv-ringtyasduad-asdasdadsa',
        url: "https://uk.pandora.net/dw/image/v2/BFCR_PRD/on/demandware.static/-/Sites-pandora-master-catalog/default/dwdf1eb47c/productimages/main/187736CZ_RGB.jpg?sw=900&sh=900&sm=fit&sfrm=png&bgcolor=F2F2F2"
      },
      {
        id: 'IYUdiuaofncksv-ringtyasduad-asdasdadsa',
        url: "https://uk.pandora.net/dw/image/v2/BFCR_PRD/on/demandware.static/-/Sites-pandora-master-catalog/default/dw6aeecfea/productimages/modelshot/187736CZ_ABC123_MODEL_eCOM_02_RGB.jpg?sw=900&sh=900&sm=fit&sfrm=png&bgcolor=F2F2F2"
      },
      {
        id: 'IYUdiuaofncksv-ringtyasduad-asdasdadsa',
        url: "https://uk.pandora.net/dw/image/v2/BFCR_PRD/on/demandware.static/-/Sites-pandora-master-catalog/default/dwd2d3b3f9/productimages/singlepackshot/187736CZ_V4_RGB.jpg?sw=900&sh=900&sm=fit&sfrm=png&bgcolor=F2F2F2"
      },
      {
        id: 'IYUdiuaofncksv-ringtyasduad-asdasdadsa',
        url: "https://uk.pandora.net/dw/image/v2/BFCR_PRD/on/demandware.static/-/Sites-pandora-master-catalog/default/dw291749e5/productimages/singlepackshot/187736CZ_V3_RGB.jpg?sw=900&sh=900&sm=fit&sfrm=png&bgcolor=F2F2F2"
      },
    ],
    weight: 3.2,
    colors: [
      {
        id: 'PAndjsifjis-Roswrkflds-lddasdkdkj',
        name: "rosegold|A"
      }
    ],
    metal: "silver925|A",
    sizes: [
      {
        id: '5&PDJksdsd-KLJdkhkjfscfsc-lddasdkdkj',
        value: "54"
      },
    ],
    description: "Виготовлена з гладенькими та блискучими поверхнями в рожевих відтінках, ця каблучка - мрія кожної принцеси завдяки своїй формі, що нагадує діадему, яка досягається завдяки нашаруванню окремих кристалів на обідок каблучки, а також їх закріпленню на її краю. Ідеальна для самостійного носіння, каблучка у формі хреста також чудово поєднується з іншими виробами з такого ж або контрастного металу.|A",
    popularity: 1,
    customPopularity: 1,
    packaging: "basic_packaging|A",
    realPhotos: []
  },

  {
    id: 'PAndjsifjis-LJKcdjskldsvsdvv-lddasdkdkj',
    count: 1,
    collection: "Spring`23",
    category: "rings",
    labelName: "ring_name|A",
    brand: "Pandora",
    price: 1895,
    salePrice: 1195,
    images: [
      {
        id: 'LJkdaslfa-qwdassad-lddasdkdkj',
        url: "/images/rings/pandora/5.png"
      },
      {
        id: 'IYUdiuaofncksv-ringtyasduad-asdasdadsa',
        url: "/images/rings/pandora/6.png"
      },
    ],
    weight: 6.3,
    colors: [
      {
        id: 'PAndjsifjis-Roswrkflds-lddasdkdkj',
        name: "rosegold|A"
      }
    ],
    metal: "silver925|A",
    sizes: [
      {
        id: '5&PDJksdsd-KLJdkhkjfscfsc-lddasdkdkj',
        value: "54"
      }
    ],
    description: "Довершена срібла каблучка виконана із срібла 925 та інкрустована камінцями Цирконію. Носіть цю каблучку як символ життєвої енергії та позитиву або поєднайте її з іншою каблучкою — 'Блискучий блакитний місяць', — що є її повною протилежністю. Обидві каблучки ідеально поєднуються між собою, уособлюючи собою дуальність і гармонію у природі, та в середині нас",
    popularity: 1,
    customPopularity: 1,
    packaging: "Оригінальний мішечок бренду та фірмовий пакет Tvoi",
    realPhotos: []
  },

  {
    id: 'PAndjsifjis-KLJkhdkfslfds-lddasdkdkj',
    count: 0,
    collection: "Spring`23",
    category: "rings",
    labelName: "ring_name|A",
    brand: "Pandora",
    price: 1590,
    salePrice: 0,
    images: [
      {
        id: 'LJkdaslfa-KLJkhdkfslfds-lddasdkdkj',
        url: "https://static.e-pandora.ua/22930/responsive-images/1681155227___webp_960_960.webp"
      },
      {
        id: 'IYUdiuaofncksv-KLJkhdkfslfds-asdasdadsa',
        url: "https://static.e-pandora.ua/22932/responsive-images/1681155228___webp_960_960.webp"
      },
      {
        id: 'LJkdaslfa-KLJkhdkfslfds-lddasdkdkj',
        url: "https://static.e-pandora.ua/22931/responsive-images/1681155227___webp_960_960.webp"
      },
      {
        id: 'IYUdiuaofncksv-KLJkhdkfslfds-asdasdadsa',
        url: "https://static.e-pandora.ua/22933/responsive-images/1681155228___webp_960_960.webp"
      }
    ],
    weight: 6.3,
    colors: [
      {
        id: 'PAndjsifjis-KLJkhdkfslfds-lddasdkdkj',
        name: "silver|A"
      }
    ],
    metal: "silver925|A",
    sizes: [
      {
        id: '5&PDJksdsd-KLJkhdkfslfds-lddasdkdkj',
        value: "54"
      }
    ],
    description: "Довершена срібла каблучка виконана із срібла 925 та інкрустована камінцями Цирконію. Носіть цю каблучку як символ життєвої енергії та позитиву або поєднайте її з іншою каблучкою — 'Блискучий блакитний місяць', — що є її повною протилежністю. Обидві каблучки ідеально поєднуються між собою, уособлюючи собою дуальність і гармонію у природі, та в середині нас",
    popularity: 1,
    customPopularity: 1,
    packaging: "Оригінальний мішечок бренду та фірмовий пакет Tvoi",
    realPhotos: []
  },

  {
    id: 'PAndjsifjis-ringtyasduad-lddasdkdkj',
    count: 0,
    collection: "Spring`23",
    category: "rings",
    labelName: "ring_name|A",
    brand: "Pandora",
    price: 1780,
    salePrice: 0,
    images: [
      {
        id: 'PAndjsifjis-qwdassad-lddasdkdkj',
        url: "/images/rings/pandora/1.png"
      },
      {
        id: 'PAndjsifjis-ringtyasduad-asdasdadsa',
        url: "/images/rings/pandora/2.png"
      },
      {
        id: 'PAndjsifjis-ringtyas5dsa656dduad-lddasdkdkj',
        url: "/images/rings/pandora/3.png"
      },
      {
        id: 'PAndjsifjis-rinasdasdgtyasduad-lddasdkdkj',
        url: "/images/rings/pandora/4.png"
      }
    ],
    weight: 6.3,
    colors: [
      {
        id: 'PAndjsifjis-LJDksaa-lddasdkdkj',
        name: "silver|A"
      },
      {
        id: 'PAndjsifjis-ringtyasduad-HGOslsdkls',
        name: "gold|A"
      }
    ],
    metal: "silver925|A",
    sizes: [
      {
        id: '5&PDJksdsd-:asdasdsc-lddasdkdkj',
        value: "54"
      },
      {
        id: '5&8dsjksd78s-PAJkdnkfsf-56d5s6dJHiodfsd',
        value: "56"
      }
    ],
    description: "Довершена срібла каблучка виконана із срібла 925 та інкрустована камінцями Цирконію. Носіть цю каблучку як символ життєвої енергії та позитиву або поєднайте її з іншою каблучкою — 'Блискучий блакитний місяць', — що є її повною протилежністю. Обидві каблучки ідеально поєднуються між собою, уособлюючи собою дуальність і гармонію у природі, та в середині нас",
    popularity: 2,
    customPopularity: 2,
    packaging: "Оригінальний мішечок бренду та фірмовий пакет Tvoi",
    realPhotos: []
  },

  {
    id: '5&8dsjksd78s-das5d4a65-lddasdkdkj',
    count: 0,
    collection: "Spring`23",
    category: "rings",
    labelName: "ring_name|A",
    brand: "Pandora",
    price: 1250,
    salePrice: 0,
    images: [
      {
        id: '5&8dsjksd78s-das5d4a65-asdasdas',
        url: "https://live.staticflickr.com/65535/52644686813_1482f8c862_z.jpg"
      },
      {
        id: '5&8dsjksd78s-das5d4a65-56asd56s',
        url: "https://static.e-pandora.ua/17623/responsive-images/PNGTRPNT_199491C01_V3_RGB___webp_960_960.webp"
      },
      {
        id: '5&8dsjksd78s-IOidsldss-lddasdkdkj',
        url: "https://static.e-pandora.ua/17622/responsive-images/PNGTRPNT_199491C01_V2_RGB___webp_960_960.webp"
      },
      {
        id: '5&8dsjksd78s-das5d4a65-Oskdjds',
        url: "https://live.staticflickr.com/65535/52644656135_2990611849_z.jpg"
      }
    ],
    weight: 6.3,
    colors: [
      {
        id: '5&8dsjksd78s-PIodsljsa54873-lddasdkdkj',
        name: "silver|A"
      }
    ],
    metal: "silver925|A",
    sizes: [
      {
        id: '5&8dsjksd78s-:Ksodjofas-lddasdkdkj',
        value: "52"
      }
    ],
    description: "none",
    popularity: 8,
    customPopularity: 8,
    packaging: "basic_packaging|A",
    realPhotos: []
  },

  {
    id: '5&PAnskjdajfl-iwyuisidas-lajsdlaidls',
    count: 1,
    collection: "Spring`23",
    category: "rings",
    labelName: "ring_name|A",
    brand: "Pandora",
    price: 1690,
    salePrice: 1095,
    images: [
      {
        id: '5&8dsjksd78s-das5d4a65-asdasdas',
        url: "https://static.e-pandora.ua/23462/responsive-images/1690468422___webp_960_960.webp"
      },
      {
        id: '5&8dsjksd78s-das5d4a65-56asd56s',
        url: "https://static.e-pandora.ua/23463/responsive-images/1690468425___webp_960_960.webp"
      },
      {
        id: '5&8dsjksd78s-IOidsldss-lddasdkdkj',
        url: "https://static.e-pandora.ua/23464/responsive-images/1690468428___webp_960_960.webp"
      }
    ],
    weight: 3.1,
    colors: [
      {
        id: '5&8dsjksd78s-PIodsljsa54873-lddasdkdkj',
        name: "rosegold|A"
      }
    ],
    metal: "silver925|A",
    sizes: [
      {
        id: '5&8dsjksd78s-:Ksodjofas-lddasdkdkj',
        value: "54"
      }
    ],
    description: "none",
    popularity: 1,
    customPopularity: 1,
    packaging: "basic_packaging|A",
    realPhotos: []
  },

  {
    id: 'JKHSdddasdpandkas-iwyuisidas-PAKJNSkjhkjsa',
    count: 0,
    collection: "Spring`23",
    category: "rings",
    labelName: "ring_name|A",
    brand: "Pandora",
    price: 2290,
    salePrice: 0,
    images: [
      {
        id: '5&8dsjksd78s-das5d4a65-asdasdas',
        url: "https://static.e-pandora.ua/21842/responsive-images/1667383168___webp_960_951.webp"
      },
      {
        id: '5&8dsjksd78s-das5d4a65-56asd56s',
        url: "https://static.e-pandora.ua/21841/responsive-images/1667383168___webp_960_960.webp"
      },
      {
        id: '5&8dsjksd78s-IOidsldss-lddasdkdkj',
        url: "https://static.e-pandora.ua/21844/responsive-images/1667383169___webp_960_960.webp"
      },
      {
        id: '5&8dsjksd78s-IOidsldss-lddasdkdkj',
        url: "https://static.e-pandora.ua/21845/responsive-images/1667383169___webp_960_960.webp"
      }
    ],
    weight: 7.5,
    colors: [
      {
        id: '5&8dsjksd78s-PIodsljsa54873-lddasdkdkj',
        name: "silver|A"
      }
    ],
    metal: "silver925|A",
    sizes: [
      {
        id: '5&8dsjksd78s-:Ksodjofas-lddasdkdkj',
        value: "52"
      }
    ],
    description: "none",
    popularity: 1,
    customPopularity: 1,
    packaging: "basic_packaging|A",
    realPhotos: []
  },
  {
    id: '988e9rfkdshnfskjf-ljskdlsd-lddasdkdkj',
    count: 0,
    collection: "Spring`23",
    category: "rings",
    labelName: "ring_name|A",
    brand: "Pandora",
    price: 1785,
    salePrice: 0,
    images: [
      {
        id: '988e9rfkdshnfskjf-Poosjdsds-lddasdkdkj',
        url: "https://live.staticflickr.com/65535/52644213966_dcde4b3eb5_z.jpg"
      },
      {
        id: '988e9rfkdshnfskjf-ljs343kdlsd-lddasdkdkj',
        url: "https://live.staticflickr.com/65535/52644656075_74d59b3043_z.jpg"
      },
      {
        id: '988e9rfkdshnfskjf-ljskdlsd-LKJjdksd',
        url: "https://live.staticflickr.com/65535/52644469744_a910c70359_z.jpg"
      }
    ],
    weight: 7.3,
    colors: [
      {
        id: '988e9rfkdshnfskjf-ljskdlsd-Klskjld',
        name: "silver|A"
      }
    ],
    metal: "silver925|A",
    sizes: [
      {
        id: 'KJljsofdsd5s-ljskdlsd-lddasdkdkj',
        value: "50"
      },
      {
        id: '656fsadjdksa-ljskdlsd-lddasdkdkj',
        value: "52"
      },
      {
        id: '988e9rfkddasdsshnfskjf-ljskdlsd-lddasdkdkj',
        value: "54"
      },
      {
        id: '988e9rfkdsh58asd6956snfskjf-ljskdlsd-lddasdkdkj',
        value: "56"
      }
    ],
    description: "none",
    popularity: 8,
    customPopularity: 8,
    packaging: "basic_packaging|A",
    realPhotos: []
  },
  {
    id: 'OPisjds7sd8s-KLajdkfsdsd-lddasdkdkj',
    count: 1,
    collection: "s.Oliver",
    category: "rings",
    labelName: "ring_name|A",
    brand: "s.Oliver",
    price: 1390,
    salePrice: 0,
    images: [
      {
        id: 'OPisjds7sd8s-asdadadsd-lddasdkdkj',
        url: "https://live.staticflickr.com/65535/52704213716_0b24e83cc8_z.jpg"
      }
    ],
    weight: 9.3,
    colors: [
      {
        id: 'OPisjds7sd8s-KLajdkfsdsd-ldddasdadasdkdkj',
        name: "silver|A"
      }
    ],
    metal: "silver925|A",
    sizes: [
      {
        id: 'OPisjds7sd8s-asdasdseds-lddasdkdkj',
        value: "54"
      },
      {
        id: 'OPisjds7sd8s-KLajdkfsdsd-asdklsashgdj',
        value: "56"
      }
    ],
    description: "none",
    popularity: 5,
    customPopularity: 5,
    packaging: "basic_packaging|A",
    realPhotos: []
  },
  {
    id: 'Ppisdodikosd6sd5s-KLajdkfsdsd-lddasdkdkj',
    count: 1,
    collection: "s.Oliver",
    category: "rings",
    labelName: "ring_name|A",
    brand: "s.Oliver",
    price: 1390,
    salePrice: 0,
    images: [
      {
        id: 'Ppisdodikosd6sd5s-asdmakjdkss-lddasdkdkj',
        url: "https://live.staticflickr.com/65535/52704213706_2415ea3004_z.jpg"
      }
    ],
    weight: 10.3,
    colors: [
      {
        id: 'IOudsjkdls-KLajdkfsdsd-lddasdkdkj',
        name: "silver|A"
      }
    ],
    metal: "silver925|A",
    sizes: [
      {
        id: 'Ppisdodikosd6sd5s-OIaodjksks-lddasdkdkj',
        value: "52"
      },
      {
        id: 'Ppisdodikosd6sd5s-adasd45a45d-lddasdkdkj',
        value: "54"
      },
      {
        id: '54sa54d56a5s-KLajdkfsdsd-lddasdkdkj',
        value: "56"
      }
    ],
    description: "none",
    popularity: 6,
    customPopularity: 6,
    packaging: "basic_packaging|A",
    realPhotos: []
  },
  {
    id: 'Lkjskjdks78sd-KLajdkfsdsd-lddasdkdkj',
    count: 1,
    collection: "s.Oliver",
    category: "rings",
    labelName: "ring_name|A",
    brand: "s.Oliver",
    price: 890,
    salePrice: 0,
    images: [
      {
        id: 'Lkjskjdks78sd-Kldjksd5s645d-lddasdkdkj',
        url: "https://live.staticflickr.com/65535/52704216801_dc0d177c17_z.jpg"
      }
    ],
    weight: 6.8,
    colors: [
      {
        id: 'Lkjskjdks78sd-KLajdkfsdsd-KLdjssdks4d545sd',
        name: "silver|A"
      }
    ],
    metal: "silver925|A",
    sizes: [
      {
        id: 'Lkjskjdks78sd-656adKHJdsd-lddasdkdkj',
        value: "52"
      },
      {
        id: 'Lkjskjdks78sd-dslsd454s-lddasdkdkj',
        value: "54"
      },
      {
        id: 'Lkjskjdks78sd-Posdnksdk-lddasdkdkj',
        value: "56"
      }
    ],
    description: "none",
    popularity: 7,
    customPopularity: 7,
    packaging: "basic_packaging|A",
    realPhotos: []
  },
  {
    id: 'PIjsdkjksds55s5s-KLajdkfsdsd-lddasdkdkj',
    count: 1,
    collection: "Xenox",
    category: "rings",
    labelName: "ring_name|A",
    brand: "Xenox",
    price: 1695,
    salePrice: 1295,
    images: [
      {
        id: 'PIjsdkjksds55s5s-KLajdkfsdsd-nbxvcvx5dd',
        url: "https://live.staticflickr.com/65535/52708572258_631e8ec2c2_z.jpg"
      },
      {
        id: 'PIjsdkjksds55s5s-znmbHysudsd55-lddasdkdkj',
        url: "https://live.staticflickr.com/65535/52708087526_8e33a6d27f_z.jpg"
      }
    ],
    weight: 6.5,
    colors: [
      {
        id: 'PIjsdkjksds55s5s-KLajdkfsdsd-asd65s6a',
        name: "rosegold|A"
      }
    ],
    metal: "silver925|A",
    sizes: [
      {
        id: 'PIjsdkjksds55s5s-KLajdkfsdsd-j4hg65g6d4s',
        value: "52"
      }
    ],
    description: "Срібна каблучка із тонким напиленням рожевого золота 18к. Нагадати про свої почуття так просто",
    popularity: 3,
    customPopularity: 3,
    packaging: "Оригінальна коробка бренду|A",
    realPhotos: []
  },
  {
    id: 'Jklsdkjksdjfa56sas-igklsvcd4d5-lddasdkdkj',
    count: 1,
    collection: "Gooix",
    category: "rings",
    labelName: "ring_name|A",
    brand: "Gooix",
    price: 1990,
    salePrice: 0,
    images: [
      {
        id: 'Jklsdkjksdjfa56sas-igklasdasdasvcd4d5-lddasdkdkj',
        url: "https://live.staticflickr.com/65535/52713731352_6592bd055f_z.jpg"
      },
      {
        id: 'Jklsdkjksdjfa56sas-igklsvcd4d5-asdada5df6a',
        url: "https://live.staticflickr.com/65535/52714513854_27d531dfd0_z.jpg"
      },
      {
        id: 'Jklsdkjksdjfa56sas-asd5as6d56-lddasdkdkj',
        url: "https://live.staticflickr.com/65535/52714255141_5c6a72b0e9_z.jpg"
      }
    ],
    weight: 9.5,
    colors: [
      {
        id: 'Jklsdkjksdjfa56sas-igklsvcd4d5-asd5a6s5s',
        name: "silver|A"
      }
    ],
    metal: "silver925|A",
    sizes: [
      {
        id: 'Jklsdkjksdjfa56sas-asdkjlasdsad56s45-lddasdkdkj',
        value: "52"
      },
      {
        id: 'Jklsdkjksdjfa56sas-asd5sa6d4s5d-lddasdkdkj',
        value: "54"
      }
    ],
    description: "Срібна каблучка із діамантовою обробкою. Бо вона цього варта",
    popularity: 7,
    customPopularity: 7,
    packaging: "Оригінальна коробка бренду|A",
    realPhotos: []
  },
  {
    id: 'Opiodoadas5d45-adassd-lddasdkdkj',
    count: 0,
    collection: "Pandora",
    category: "rings",
    labelName: "ring_name|A",
    brand: "Pandora",
    price: 890,
    salePrice: 0,
    images: [
      {
        id: 'Opiodoadas5d45-Kldsjds4s5d-lddasdkdkj',
        url: "https://live.staticflickr.com/65535/52644213896_a7e7d59f10_z.jpg"
      },
      {
        id: 'Opiodoadas5d45-Mldsd4s54a-lddasdkdkj',
        url: "https://live.staticflickr.com/65535/52644686828_56311d0c88_z.jpg"
      },
      {
        id: 'Pjfasdsd400a45-Kldsjds4s5d-lddasdkdkj',
        url: "https://live.staticflickr.com/65535/52644686868_6f6330f984_z.jpg"
      }
    ],
    weight: 7.5,
    colors: [
      {
        id: 'Opiodoadas5d45-Kldsjds4s5d-Ojdskdlsd4ds5',
        name: "silver|A"
      }
    ],
    metal: "silver925|A",
    sizes: [
      {
        id: 'Opiodoadas5d45-Kldsjds4s5d-5d6sa56as',
        value: "52"
      },
      {
        id: 'Opiodoadas5d45-asdasda545a5s-lddasdkdkj',
        value: "54"
      },
      {
        id: 'Opiodoadas5d45-LLOjlsdsd54-lddasdkdkj',
        value: "56"
      }
    ],
    description: "string",
    popularity: 16,
    customPopularity: 16,
    packaging: "basic_packaging|A",
    realPhotos: []
  },
  {
    id: 'Ldsjkkds4s5d-Kldsjds4s5d-lddasdkdkj',
    count: 0,
    collection: "Pandora",
    category: "rings",
    labelName: "ring_name|A",
    brand: "Pandora",
    price: 1090,
    salePrice: 0,
    images: [
      {
        id: 'Ldsjkkds4s5d-ioiojoasodsasa-lddasdkdkj',
        url: "https://live.staticflickr.com/65535/52644686873_fd9ba70a50_z.jpg"
      },
      {
        id: 'Ldsjkkds4s5d-asdklasdas56ds-lddasdkdkj',
        url: "https://live.staticflickr.com/65535/52644213956_f49bd38501_z.jpg"
      },
      {
        id: 'Ldsjkkds4s5d-Kldsjds4s5d-asdlasdsd45ad4s',
        url: "https://live.staticflickr.com/65535/52644469784_f85c573c0f_z.jpg"
      }
    ],
    weight: 8.5,
    colors: [
      {
        id: 'Ldsjkkds4s5d-asdajskdad4a54d5-lddasdkdkj',
        name: "silver|A"
      }
    ],
    metal: "silver925|A",
    sizes: [
      {
        id: 'Ldsjkkds4s5d-Kldsjds4s5d-sdopdskodsa454d5',
        value: "52"
      },
      {
        id: 'Ldsjkkds4s5d-56as6d5aHJHds-lddasdkdkj',
        value: "54"
      },
      {
        id: 'Ldsjkkds4s5d-Kldsjds4s5d-lqdsddasdkdkj',
        value: "56"
      }
    ],
    description: "string",
    popularity: 16,
    customPopularity: 16,
    packaging: "basic_packaging|A",
    realPhotos: []
  },
  {
    id: 'Kldsks4-Kldsjds4s5d-lddasdkdkj',
    count: 1,
    collection: "Sif Jakobs",
    category: "rings",
    labelName: "ring_name|A",
    brand: "Sif Jakobs",
    price: 4770,
    salePrice: 0,
    images: [
      {
        id: 'Kldsks4-Kldsjdseee4s5d-lddasdkdkj',
        url: "https://live.staticflickr.com/65535/52720120961_876d853ce2_z.jpg"
      },
      {
        id: 'Kldsqwqks4-Kldsjds4s5d-lddasdkdkj',
        url: "https://live.staticflickr.com/65535/52720120951_28c331f97d_z.jpg"
      },
      {
        id: 'Kldsks4-Kldsjds4s5d-lddas342dkdkj',
        url: "https://live.staticflickr.com/65535/52719601967_c90779e5d2_z.jpg"
      }
    ],
    weight: 10.5,
    colors: [
      {
        id: 'Kldsks4-qeqwdsad656d-lddasdkdkj',
        name: "rosegold|A"
      }
    ],
    metal: "silver925|A",
    sizes: [
      {
        id: 'Kldqwdssks4-Kldsjds4s5d-lddasdkdkj',
        value: "56"
      }
    ],
    description: "string",
    popularity: 4,
    customPopularity: 2,
    packaging: "basic_packaging|A",
    realPhotos: []
  },
  {
    id: 'Uasidjsk-3948dsdksd-kjakhsdede',
    count: 1,
    collection: "Esprit",
    category: "rings",
    labelName: "ring_name|A",
    brand: "Esprit",
    price: 1990,
    salePrice: 1495,
    images: [
      {
        id: 'Uasidjsk-asdasda-kjakhsdede',
        url: "https://live.staticflickr.com/65535/52723535775_1463e4eff1_z.jpg"
      },
      {
        id: 'Uasidjsk-3948asadsdksd-kjakhsdede',
        url: "https://live.staticflickr.com/65535/52723132516_5165165ccd_z.jpg"
      }
    ],
    "weight": 6.1,
    "colors": [
      {
        id: 'Uasidjsk-3948dsdksd-kjakasdahsdede',
        "name": "silver|A"
      },
      {
        id: 'Uasidjsk-asdas-kjakhsdede',
        "name": "gold|A"
      }
    ],
    "metal": "silver|A",
    "sizes": [
      {
        id: 'Uasidjsk-3948dsdksd-asdadws',
        "value": "17"
      },
      {
        id: 'sdsdsdas-3948dsdksd-kjakhsdede',
        "value": "18"
      }
    ],
    "description": "string",
    "popularity": 6,
    "customPopularity": 2,
    packaging: "basic_packaging|A",
    realPhotos: []
  },
  {
    id: 'Posudksns-3948dsdksd-kjakhsdede',
    "count": 1,
    "collection": "gift",
    "category": "rings",
    "labelName": "ring_name|A",
    "brand": "TVOI",
    "price": 2570,
    "salePrice": 0,
    "images": [
      {
        id: 'Posudksns-asdadsd-kjakhsdede',
        "url": "https://live.staticflickr.com/65535/52735213458_6e09e7a05d_z.jpg"
      },
      {
        id: 'Posudksns-Osjlsdsds-kjakhsdede',
        "url": "https://live.staticflickr.com/65535/52739425722_362f593cf8_z.jpg"
      },
      {
        id: 'Posudksns-asdadss2-kjakhsdede',
        "url": "https://live.staticflickr.com/65535/52739942511_77566e168f_z.jpg"
      }
    ],
    "weight": 6.5,
    "colors": [
      {
        id: 'Posudksns-3948dsdksd-asdsaaw',
        "name": "silver|A"
      }
    ],
    "metal": "silver|A",
    "sizes": [
      {
        id: 'Posudksns-3948dsdksd-33rdsads',
        "value": "54"
      },
      {
        id: 'Posudksns-3948dsdksd-asdqwww55saaw',
        "value": "56"
      }
    ],
    "description": "Неймовірна срібна каблучка із Султанітом - дорогоцінним каменем, який міняє колір взалежності від освітлення. Наприклад, на фото зображений колір каменя під світлом сонячних променів. Під світлом ламп - камінь набуде насиченого зеленого кольору, близького до кольору Ізумруду ",
    "popularity": 6,
    "customPopularity": 2,
    "packaging": "Чорна коробка під каблучки та пакет TVOI",
    realPhotos: []
  },
  {
    id: 'Opskaslsd-mdnkchds-asdsaaw',
    "count": 1,
    "collection": "gift",
    "category": "rings",
    "labelName": "ring_name|A",
    "brand": "Pandora",
    "price": 1395,
    "salePrice": 995,
    "images": [
      {
        id: 'Opskaslsd-asdads-asdsaaw',
        "url": "https://live.staticflickr.com/65535/52740499143_030b58f250_z.jpg"
      },
      {
        id: 'Opskaslsd-mdnkchds-asadasdadsaaw',
        "url": "https://live.staticflickr.com/65535/52740420875_237326b8e0_z.jpg"
      },
      {
        id: 'Opskaslsd-mdn453rewdkchds-asdsaaw',
        "url": "https://live.staticflickr.com/65535/52740264104_2a40a22b28_z.jpg"
      }
    ],
    "weight": 6.7,
    "colors": [
      {
        id: 'Opskaslsd-mdnkchds-wdq344fas',
        "name": "rosegold|A"
      }
    ],
    "metal": "silver|A",
    "sizes": [
      {
        id: 'Opskaslsd-qewqweqwd-asdsaaw',
        "value": "52"
      },
    ],
    "description": "Срібна каблучка у вигляді квітки із тонким напиленням рожевого золота 18к та вставками кубічного цирконію. Любов та ніжність у всіх її проявах",
    "popularity": 6,
    "customPopularity": 2,
    "packaging": "Оригінальна коробка бренду та пакет TVOI",
    realPhotos: []
  },
  {
    id: 'Mkjdiasds-Sdio8osd8sdd-asdsff',
    "count": 0,
    "collection": "gift",
    "category": "rings",
    "labelName": "ring_name|A",
    "brand": "Pandora",
    "price": 1390,
    "salePrice": 0,
    "images": [
      {
        id: 'Mkjdiasds-asdasd-asdsff',
        "url": "https://live.staticflickr.com/65535/52740011306_f60a9a4a00_z.jpg"
      },
      {
        id: 'Mkjdiasds-98as79dsa-asdsff',
        "url": "https://live.staticflickr.com/65535/52740420760_99f673a9a9_z.jpg"
      },
      {
        id: 'Mkjdiasds-Sdio8osd8sdd-0asd89jsdd',
        "url": "https://live.staticflickr.com/65535/52740011371_e9266f9925_z.jpg"
      }
    ],
    "weight": 6.3,
    "colors": [
      {
        id: 'Mkjdiasds-qwdre55-asdsff',
        "name": "rosegold|A"
      }
    ],
    "metal": "silver|A",
    "sizes": [
      {
        id: 'Mkjdiasds-Sdio8osd8sdd-asdsasdsaff',
        "value": "52"
      },
      {
        id: 'Mkjdiasds-Sdio8osdasdawe5sdd-asdsff',
        "value": "54"
      }
    ],
    "description": "Нагадай про свої почуття із цією срібною каблучкою. Тонке напиленням рожевого золота 18к та інкрустація камінцем Цирконію у вигляді сердечка",
    "popularity": 6,
    "customPopularity": 2,
    "packaging": "Оригінальна коробка бренду та пакет TVOI",
    realPhotos: []
  },
  {
    id: 'Mkjdiasds-564kj5kejfd-asdsff',
    "count": 0,
    "collection": "gift",
    "category": "rings",
    "labelName": "ring_name|A",
    "brand": "Pandora",
    "price": 1390,
    "salePrice": 0,
    "images": [
      {
        id: 'Mkjdiasds-Sdio8osd8sdd-asdadsc',
        "url": "https://live.staticflickr.com/65535/52740263809_f78b8a5a88_z.jpg"
      },
      {
        id: 'Mkjdiasds-asdadss-asdsff',
        "url": "https://live.staticflickr.com/65535/52740498878_7a3a288048_z.jpg"
      },
      {
        id: 'Mkjdiasds-PopsjkaOIjka-asdsff',
        "url": "https://live.staticflickr.com/65535/52740011126_aeeaf634c8_z.jpg"
      }
    ],
    "weight": 6.9,
    "colors": [
      {
        id: 'Mkjdiasds-sdasaw4355-asdsff',
        "name": "silver|A"
      }
    ],
    "metal": "silver|A",
    "sizes": [
      {
        id: 'Mkjdiasds-LKDHkjsd-asdsff',
        "value": "52"
      },
      {
        id: 'Mkjdiasds-Sdio8osd8sdd-KJsdusk',
        "value": "54"
      }
    ],
    "description": "Неймовірно сяюча срібна каблучка інкрустована камінцями Цирконію. Просто та акуратно - ідеальний варіант каблучки, яка підійде під будь-який твій образ",
    "popularity": 15,
    "customPopularity": 15,
    "packaging": "Оригінальна коробка бренду та пакет TVOI",
    realPhotos: []
  },
  {
    id: 'Ojsdjdkakdsf432-asjkcxvs-asdsff',
    "count": 0,
    "collection": "gift",
    "category": "rings",
    "labelName": "ring_name|A",
    "brand": "Pandora",
    "price": 1190,
    "salePrice": 0,
    "images": [
      {
        id: 'Ojsdjdkakdsf432-sfadd-asdsff',
        "url": "https://live.staticflickr.com/65535/52740498908_52163bd81b_z.jpg"
      },
      {
        id: 'Ojsdjdkakdsf432-asjkcxvs-kjhkmgnfds',
        "url": "https://live.staticflickr.com/65535/52740498893_f5dd319fb5_z.jpg"
      },
      {
        id: 'Ojsdjdkakdsf432-564ygdfgs-asdsff',
        "url": "https://live.staticflickr.com/65535/52740011296_537db32893_z.jpg"
      }
    ],
    "weight": 5.8,
    "colors": [
      {
        id: 'Ojsdjdkakdsf432-asjkcxvs-sdadsad',
        "name": "silver|A"
      }
    ],
    "metal": "silver|A",
    "sizes": [
      {
        id: 'asdscaw444-asjkcxvs-asdsff',
        "value": "54"
      }
    ],
    "description": "Натхненна зачарованою трояндою, каблучка із кубічного цирконію в центрі обідка, оточену ажурним листям і вигнутими гілками, а також трьома меншими червоними каменями. Оспівуючи недосконале, але чарівне кохання між діснеївською Попелюшкою та Чудовиськом, каблучка стане елегантним, вигадливим доповненням до будь-якого образу",
    "popularity": 5,
    "customPopularity": 3,
    "packaging": "Оригінальна коробка бренду та пакет TVOI",
    realPhotos: []
  },
  {
    id: 'BNsdjos8862-asjkcxvs-asdsff',
    "count": 1,
    "collection": "gift",
    "category": "rings",
    "labelName": "ring_name|A",
    "brand": "Xenox",
    "price": 1055,
    "salePrice": 825,
    "images": [
      {
        id: 'BNsdjos8862-Kkjdskds66-asdsff',
        "url": "https://live.staticflickr.com/65535/52764924028_d41645f1ec_z.jpg"
      },
      {
        id: 'BNsdjos8862-Oissdk6776-asdsff',
        "url": "https://live.staticflickr.com/65535/52764924623_17c0798372_z.jpg"
      }
    ],
    "weight": 5.4,
    "colors": [
      {
        id: 'BNsdjos8862-adasasasa-asdsff',
        "name": "rosegold|A"
      }
    ],
    "metal": "silver|A",
    "sizes": [
      {
        id: 'BNsdjos8862-Kkjdskds66-543werdassa',
        "value": "50"
      },
      {
        id: 'BNsdjos8862-Kkjdskds66-sdaewered',
        "value": "52"
      },
      {
        id: 'BNsdjos8862-456546445dfss-asdsff',
        "value": "56"
      }
    ],
    "description": "Довершена рожевим золотом 18к, ця срібна каблучка із сяючим камінчиком підкреслить витонченість вашого смаку і додасть особливі нотки казковості. Для тебе - лише найкраще",
    "popularity": 5,
    "customPopularity": 3,
    "packaging": "Чорна коробка під каблучку та фірмовий пакет TVOI",
    realPhotos: []
  },
  {
    id: 'Popaskdnckzss5434-Kkjdskds66-asdsff',
    "count": 1,
    "collection": "Sif Jakobs",
    "category": "rings",
    "labelName": "ring_name|A",
    "brand": "Sif Jakobs",
    "price": 6490,
    "salePrice": 5090,
    "images": [
      {
        id: 'Popaskdnckzss5434-Kkjqweqdqskds66-asdsff',
        "url": "https://live.staticflickr.com/65535/52770010796_29b623ab2c_z.jpg"
      },
      {
        id: 'qdwaswe4-Kkjdskds66-asdsff',
        "url": "https://live.staticflickr.com/65535/52769475222_836ab67b4e_z.jpg"
      },
      {
        id: 'Popaskdnckzss5434-asdar654-asdsff',
        "url": "https://live.staticflickr.com/65535/52770509848_361fe42545_z.jpg"
      }
    ],
    "weight": 8.4,
    "colors": [
      {
        id: 'Popaskdnckzss5434-Kkjdskds66-LPosjksdsd',
        "name": "silver|A"
      }
    ],
    "metal": "silver|A",
    "sizes": [
      {
        id: 'Popaskdnckzss5434-dfadasdfgs-LPosjksdsd',
        "value": "56"
      }
    ],
    "description": "Ви не купуєте прикрасу - ви купуєте статус. Ця каблучка із високоякісного срібла, інкрустована камінцями Цирконію найвищого класу, зробить дорогим будь-який ваш образ, адже вона розроблена знаменитими дизайнерами та виготовлена найкращими ювелірами від Sif Jakobs",
    "popularity": 3,
    "customPopularity": 3,
    "packaging": "Оригінальна коробка бренду|A",
    realPhotos: []
  },

  // WATCHES

  {
    id: 'PHIlsdajfkds-ipoetpgdf-ETmdbjs5564',
    "count": 1,
    "collection": "Philipp Plein",
    "category": "watches",
    "labelName":  "watch_name|A",
    "brand": "Carl von Zeyten",
    "price": 29990,
    "salePrice": 0,
    "images": [
      {
        id: 'PHIlsdajfkds-ZTNdlLjsld45ssldlsd-ETmdbjs5564',
        "url": "images/watches/1.jpg"
      },
      {
        id: 'PHIlsdajfkds-sdfkhsdfdf-ETmdbjs5564',
        "url": "images/watches/2.jpg"
      },
      {
        id: 'PHIlsdajfkds-55f45sfds-ETmdbjs5564',
        "url": "images/watches/4.jpg"
      }
    ],
    "weight": 62,
    "colors": [
      {
        id: 'PHIlsdajfkds-ZTNdadsfsalsldlsd-ETmdbjs5564',
        "name": "black|A"
      }
    ],
    "metal": "steel|A",
    "sizes": [
      {
        id: 'PHIlsdajfkds-Ldhjsjl-ETmdbjs5564',
        "value": "єдиний розмір"
      }
    ],
    "description": "",
    "popularity": 5,
    "customPopularity": 5,
    "packaging": "Оригінальна коробка бренду, паспорт та гарантія",
    realPhotos: [
      {
        id: 'PHIlsdajfkds-KLKHdkaskldsd-ETmdbjs5564',
        "url": "real_photos/watches/img_0634.jpg"
      },
      {
        id: 'PHIlsdajfkds-Jofkdfls;pf-ETmdbjs5564',
        "url": "real_photos/watches/img_0636.jpg"
      },
      {
        id: 'PHIlsdajfkds-sndfkjsdflsv-ETmdbjs5564',
        "url": "real_photos/watches/img_0637.jpg"
      },
      {
        id: 'PHIlsdajfkds-ieorwiefnckds-ETmdbjs5564',
        "url": "real_photos/watches/img_0638.jpg"
      },
    ]
  },

  {
    id: 'CRLOSVNSLSM-ZTNdlsldlsd-ETmdbjs5564',
    "count": 1,
    "collection": "Carl von Zeyten",
    "category": "watches",
    "labelName": "watch_name|A",
    "brand": "Carl von Zeyten",
    "price": 12200,
    "salePrice": 9990,
    "images": [
      {
        id: 'CRLOSVNSLSM-ZTNdlLjsld45ssldlsd-ETmdbjs5564',
        "url": "https://thumbs2.imgbox.com/a0/7c/4rS8upNJ_t.png"
      },
      {
        id: 'CRLOSVNSLSM-sdfkhsdfdf-ETmdbjs5564',
        "url": "https://thumbs2.imgbox.com/b1/bf/SdC3GGAA_t.png"
      },
      {
        id: 'CRLOSVNSLSM-55f45sfds-ETmdbjs5564',
        "url": "https://thumbs2.imgbox.com/66/bf/POz2JTzr_t.png"
      }
    ],
    "weight": 62,
    "colors": [
      {
        id: 'CRLOSVNSLSM-ZTNdadsfsalsldlsd-ETmdbjs5564',
        "name": "darkblue|A"
      }
    ],
    "metal": "steel|A",
    "sizes": [
      {
        id: 'CRLOSVNSLSM-Ldhjsjl-ETmdbjs5564',
        "value": "єдиний розмір"
      }
    ],
    "description": "Розмір корпусу 40 мм - ідеальний вибір, якщо вам не подобаються ні великі, ні дуже компактні годинники.  Середня загальна вага (63 г) робить його зручним для носіння. Корпус годинника Carl von Zeyten Urach Quartz Moonphase виготовлений  з високоякісного антиалергічного сплаву нержавіючої сталі 316L, він гарантує безпеку вашого тіла, а сплав 316L характеризується високою корозійною стійкістю. Годинник Carl von Zeyten CVZ0015BL оснащений високоточним кварцовим механізмом Ronda 706.B, що працює від надійної батарейки. У кварцовому механізмі вібрації, керовані електронікою, передаються через спеціально виготовлений синтетичний кварцовий кристал. Точність коливань гарантує високу точність кварцових механізмів",
    "popularity": 2,
    "customPopularity": 2,
    "packaging": "Оригінальна коробка бренду, паспорт та гарантія",
    realPhotos: [
      {
        id: 'CRLOSVNSLSM-KLKHdkaskldsd-ETmdbjs5564',
        "url": "https://thumbs2.imgbox.com/4e/dc/BJPPu8cq_t.png"
      }
    ]
  },

  {
    id: 'PIjksknd-udioakfsav-ETmdbjs5564',
    "count": 1,
    "collection": "Versus Versace",
    "category": "watches",
    "labelName": "watch_name|A",
    "brand": "Versace",
    "price": 7777,
    "salePrice": 0,
    "images": [
      {
        id: 'PIjksksdafnd-5dsafa6da-96s5d6ssd',
        "url": "https://thumbs2.imgbox.com/e8/29/srQAlLv2_t.png"
      },
      {
        id: 'PIjkssfdaknd-98da9ac7vz-96369JHJdsd',
        "url": "https://thumbs2.imgbox.com/ee/c9/DdtKZRSK_t.png"
      },
      {
        id: 'PIjksqwfegknd-2a3d2a3sd-YUKS5d5f45sd',
        "url": "https://thumbs2.imgbox.com/5f/9e/egfNlDiZ_t.png"
      },
      {
        id: 'PIjksafdknd-1a5sd74-LPqwewqdosjksdsd',
        "url": "https://thumbs2.imgbox.com/ce/01/5Ptf2POR_t.png"
      }
    ],
    "weight": 100,
    "colors": [
      {
        id: 'PIOkslnkdfnmv-Kdjkalsklsd-LPosjksdsd',
        "name": "black|A"
      },
      {
        id: 'LJKKHklsnkfsd-v-Kdjkalsklsd-LPosjksdsd',
        "name": "red|A"
      }
    ],
    "metal": "steel|A",
    "sizes": [
      {
        id: 'MNBNMczsd-KHksf4sd-LPosjksdsd',
        "value": "єдиний розмір"
      }
    ],
    "description": "VSPLL1220 - червоний циферблат, VSPLL1520 - чорний циферблат. Мінеральне загартоване скло, механізм кварцевий, водонепроникність 5 АТМ. Гарантія на механізм 2 роки",
    "popularity": 5,
    "customPopularity": 5,
    "packaging": "Оригінальна коробка бренду, паспорт та гарантія",
    realPhotos: [
      {
        id: 'PIjksqwfegknd-PPInksdasndks4d-YUKS5d5f45sd',
        "url": "https://thumbs2.imgbox.com/e4/d0/IBXsxyAY_t.jpg"
      },
      {
        id: 'PIjksafdknd-VHBJKds4556-LPqwewqdosjksdsd',
        "url": "https://thumbs2.imgbox.com/92/6f/d3csYkBX_t.jpg"
      }
    ]
  },

  {
    id: 'OIoasdsds4d5-dfadasdfgs-LPosjksdsd',
    "count": 1,
    "collection": "Lancaster Italy",
    "category": "watches",
    "labelName": "watch_name|A",
    "brand": "Lancaster",
    "price": 129990,
    "salePrice": 0,
    "images": [
      {
        id: 'OIoasdsds4d5-dfada434sdfgs-LPosjksdsd',
        "url": "https://live.staticflickr.com/65535/52704506189_0c39d7c82f_z.jpg"
      },
      {
        id: 'OIoasdsds4d5-dfadadwawasdfgs-LPosjksdsd',
        "url": "https://live.staticflickr.com/65535/52704506179_7b84815c28_z.jpg"
      },
      {
        id: 'OIoasdsds4d5-dfadasdfgs-LPqwewqdosjksdsd',
        "url": "https://live.staticflickr.com/65535/52703729147_30c0176f48_z.jpg"
      }
    ],
    "weight": 0,
    "colors": [
      {
        id: 'OIoasdsds4d5-Kdjkalsklsd-LPosjksdsd',
        "name": "gold|A"
      }
    ],
    "metal": "steel|A",
    "sizes": [
      {
        id: 'OIoasdsds4d5-dsadsdawa-LPosjksdsd',
        "value": "єдиний розмір"
      }
    ],
    "description": "Цей розкішний швейцарський годинник поєднує в собі бездоганну точність і елегантний дизайн. Механізм годинника — справжній витвір швейцарських майстрів, відомий своєю точністю та надійністю. Корпус виготовлений із високоякісної сталі, яка покрита тонким шаром 18-каратного золота, надаючи годиннику вишуканого золотого блиску. Безель прикрашений діамантами загальною вагою 3,14 карата, що додає виробу неймовірного сяйва і розкішного вигляду. Ремінець виготовлений із ексклюзивної шкіри ската, що надає годиннику не тільки довговічності, але й унікального текстурного вигляду. Завдяки класичному дизайну, вишуканій обробці та високоякісним матеріалам, цей годинник є не лише надійним аксесуаром, але й справжньою окрасою вашого образу|A",
    "popularity": 50,
    "customPopularity": 5,
    "packaging": "Оригінальна коробка бренду, паспорт, сертифікас на механізм та діаманти|A",
    realPhotos: []
  },
    {
      id: 'Kldskldds4d5ss-dfadasdfgs-LPosjksdsd',
      "count": 1,
      "collection": "Versace Woman",
      "category": "watches",
      "labelName": "watch_name|A",
      "brand": "Versace",
      "price": 52800,
      "salePrice": 45900,
      "images": [
        {
          id: 'Kldskldds4d5ss-df324rdsaadasdfgs-LPosjksdsd',
          "url": "https://live.staticflickr.com/65535/52688775626_48a1f95cc8_z.jpg"
        },
        {
          id: 'Kldsklddasdadsas4d5ss-dfadasdfgs-LPosjksdsd',
          "url": "https://live.staticflickr.com/65535/52688775651_72e56903e3_z.jpg"
        },
        {
          id: 'Kldskldds4d5ss-dfadasdfgs-asdakdlassads',
          "url": "https://live.staticflickr.com/65535/52689197485_6bafb36baa_z.jpg"
        }
      ],
      "weight": 0,
      "colors": [
        {
          id: 'Kldskldds4d5ss-sdasdawes-LPosjksdsd',
          "name": "gold|A"
        }
      ],
      "metal": "steel|A",
      "sizes": [
        {
          id: 'Kldskldds4d5ss-dfadasdfgs-PPIsdjldsss',
          "value": 'єдиний розмір'
        }
      ],
      "description": "string",
      "popularity": 5,
      "customPopularity": 5,
      "packaging": "Оригінальна коробка бренду|A",
      realPhotos: []
    },
    {
      id: 'Inmsjdks5d4s5sd-dfadasdfgs-LPosjksdsd',
      "count": 1,
      "collection": "Versace Woman",
      "category": "watches",
      "labelName": "watch_name|A",
      "brand": "Versace",
      "price": 62900,
      "salePrice": 51900,
      "images": [
        {
          id: 'Kldskldds4d5ss-edasdasdaf-LPosjksdsd',
          "url": "https://live.staticflickr.com/65535/52689737418_f601b55227_z.jpg"
        },
        {
          id: 'Kldskldds4d5ss-dfadasdfgs-KLaskdlsdsd',
          "url": "https://live.staticflickr.com/65535/52689666735_3e52fcf259_z.jpg"
        },
        {
          id: 'Kldskldds4d5ss-OPiskldsds-LPosjksdsd',
          "url": "https://live.staticflickr.com/65535/52689517044_2650cb7ec3_z.jpg"
        },
        {
          id: 'Kldsklsdfdds4d5ss-dfadasdfgs-LPosjksdsd',
          "url": "https://live.staticflickr.com/65535/52689666645_d557de187d_z.jpg"
        }
      ],
      "weight": 0,
      "colors": [
        {
          id: 'Kldsklddsadsaas4d5ss-dfadasdfgs-LPosjksdsd',
          "name": "gold|A"
        }
      ],
      "metal": "steel|A",
      "sizes": [
        {
          id: 'asdasdsaas-dfadasdfgs-LPosjksdsd',
          "value": 'єдиний розмір'
        }
      ],
      "description": "string",
      "popularity": 27,
      "customPopularity": 27,
      "packaging": "Оригінальна коробка бренду|A",
      realPhotos: []
    },
    {
      id: 'Ljsdkdsd556sd-dfadasdfgs-LPosjksdsd',
      "count": 1,
      "collection": "Roamer of Switzerland",
      "category": "watches",
      "labelName": "watch_name|A",
      "brand": "Roamer",
      "price": 11990,
      "salePrice": 0,
      "images": [
        {
          id: 'Kldskldds4d5ss-Kdksjkdss-LPosjksdsd',
          "url": "https://thumbs2.imgbox.com/a6/b2/blj76LzK_t.jpg"
        },
        {
          id: 'Kldskldds4d5ss-Kdksjkdss-qds4d5s4ds',
          "url": "https://thumbs2.imgbox.com/00/65/VghkenGu_t.jpg"
        },
        {
          id: 'Kldskldds4d5ss-Kdksjkdss-sadsad7s8',
          "url": "https://thumbs2.imgbox.com/43/a8/SKKYapaJ_t.jpg"
        }
        ,
        {
          id: 'Kldskldds4d5ss-Kdksasdsjkdss-sadsad7s8',
          "url": "https://thumbs2.imgbox.com/95/bd/snWJS5wx_t.jpg"
        }
      ],
      "weight": 160,
      "colors": [
        {
          id: 'Kldskldds4d5ss-Pospdpssd8s-LPosjksdsd',
          "name": "silver|A"
        }
      ],
      "metal": "steel|A",
      "sizes": [
        {
          id: 'Jjuosdsdsds-Kdksjkdss-LPosjksdsd',
          "value": "єдиний розмір"
        }
      ],
      "description": "string",
      "popularity": 28,
      "customPopularity": 28,
      "packaging": "Оригінальна коробка бренду|A",
      realPhotos: []
    },
    {
      id: 'Pkdlsjdksds45sd-Kdksjkdss-LPosjksdsd',
      "count": 1,
      "collection": "Roamer of Switzerland",
      "category": "watches",
      "labelName": "watch_name|A",
      "brand": "Roamer",
      "price": 11990,
      "salePrice": 0,
      "images": [
        {
          id: 'Pkdlsjdksds45sd-a5sd6s59asd-LPosjksdsd',
          "url": "https://live.staticflickr.com/65535/52699129809_9d6c2ee05b_z.jpg"
        },
        {
          id: 'Pkdlsjdksds45sd-Kdksjkdss-ads4sd7sa8asd',
          "url": "https://live.staticflickr.com/65535/52698850816_33fa034e00_z.jpg"
        },
        {
          id: 'Pkdlsjdksds45sd-ODKs8d8s78fds-LPosjksdsd',
          "url": "https://live.staticflickr.com/65535/52698850961_f45993525a_z.jpg"
        }
      ],
      "weight": 160,
      "colors": [
        {
          id: 'JOSdosds8d48sd-Kdksjkdss-LPosjksdsd',
          "name": "silver|A"
        }
      ],
      "metal": "steel|A",
      "sizes": [
        {
          id: 'Pkdlsjdksds45sd-LJKLsds87ds8-LPosjksdsd',
          "value": "єдиний розмір"
        }
      ],
      "description": "string",
      "popularity": 29,
      "customPopularity": 29,
      "packaging": "Оригінальна коробка бренду|A",
      realPhotos: []
    },
    {
      id: 'Pjkdslsd4s5d-Kdksjkdss-LPosjksdsd',
      "count": 1,
      "collection": "Roamer of Switzerland",
      "category": "watches",
      "labelName": "watch_name|A",
      "brand": "Roamer",
      "price": 14990,
      "salePrice": 10990,
      "images": [
        {
          id: 'Pjkdslsd4s5d-Kdksjkasddss-LPosjksdsd',
          "url": "https://live.staticflickr.com/65535/52706708070_4629ddcbd1_z.jpg"
        }
      ],
      "weight": 160,
      "colors": [
        {
          id: 'Pjkdslsasdasd4s5d-Kdksjkdss-LPosjksdsd',
          "name": "gold|A"
        }
      ],
      "metal": "steel|A",
      "sizes": [
        {
          id: 'Pjkdslsd4s5d-Kdksjsadakdss-LPosjksdsd',
          "value": "єдиний розмір"
        }
      ],
      "description": "string",
      "popularity": 30,
      "customPopularity": 30,
      "packaging": "Оригінальна коробка бренду|A",
      realPhotos: []
    },
    {
      id: 'lksodkpssd-Kdasdassjkdss-LPosjksdsd',
      "count": 1,
      "collection": "Roamer of Switzerland",
      "category": "watches",
      "labelName": "watch_name|A",
      "brand": "Roamer",
      "price": 11990,
      "salePrice": 8990,
      "images": [
        {
          id: 'lksodkpssd-Kdaasdasdsssdassjkdss-LPosjksdsd',
          "url": "https://live.staticflickr.com/65535/52706783923_671641db93_z.jpg"
        },
        {
          id: 'lksodkpssd-Kdasdassjkdss-LPosjasdasdfaksdsd',
          "url": "https://live.staticflickr.com/65535/52706783948_e52b19ba8a_z.jpg"
        }
      ],
      "weight": 160,
      "colors": [
        {
          id: 'lksodkpssd-Kdasdasdsadsassjkdss-LPosjksdsd',
          "name": "gold|A"
        }
      ],
      "metal": "натуральна шкіра та загартована сталь",
      "sizes": [
        {
          id: 'lksodasdsadsdkpssd-Kdasdassjkdss-LPosjksdsd',
          "value": "єдиний розмір"
        }
      ],
      "description": "string",
      "popularity": 31,
      "customPopularity": 31,
      "packaging": "Оригінальна коробка бренду|A",
      realPhotos: []
    },
    {
      id: 'PJksjddssssd-Kdasdassjkdss-LPosjksdsd',
      "count": 1,
      "collection": "Roamer of Switzerland",
      "category": "watches",
      "labelName": "watch_name|A",
      "brand": "Roamer",
      "price": 12990,
      "salePrice": 10990,
      "images": [
        {
          id: 'PJksjddssssd-adsasda5sd48as-LPosjksdsd',
          "url": "https://live.staticflickr.com/65535/52705769937_14c903dabb_z.jpg"
        }
      ],
      "weight": 160,
      "colors": [
        {
          id: 'PJksjddssssd-asdasds8-LPosjksdsd',
          "name": "silver|A"
        }
      ],
      "metal": "steel|A",
      "sizes": [
        {
          id: 'PJksjddssasdsssd-Kdasdassjkdss-LPosjksdsd',
          "value": "єдиний розмір"
        }
      ],
      "description": "string",
      "popularity": 32,
      "customPopularity": 32,
      "packaging": "Оригінальна коробка бренду|A",
      realPhotos: []
    },
    {
      id: 'KPspdsd-Jklsd4s5sd5-LPosjksdsd',
      "count": 1,
      "collection": "Michael Kors",
      "category": "watches",
      "labelName": "watch_name|A",
      "brand": "Michael Kors Classic",
      "price": 12990,
      "salePrice": 7990,
      "images": [
        {
          id: 'KPspdsd-Jklsd4s5sd5-LPasdsaosjksdsd',
          "url": "https://live.staticflickr.com/65535/52705771347_a428c9ea9e_z.jpg"
        },
        {
          id: 'KPspdsd-Jklsd4s5sd5-LPos23easdjksdsd',
          "url": "https://live.staticflickr.com/65535/52706548614_ed954513d6_z.jpg"
        }
      ],
      "weight": 160,
      "colors": [
        {
          id: 'KPspdsd-Jklsd4s5sd5-LPosasdasdjksdsd',
          "name": "gold|A"
        }
      ],
      "metal": "сталь",
      "sizes": [
        {
          id: 'KPspdsd-Jklsd4s5sd5-LPosasdasxsjksdsd',
          "value": "єдиний розмір"
        }
      ],
      "description": "string",
      "popularity": 15,
      "customPopularity": 15,
      "packaging": "Оригінальна коробка бренду|A",
      realPhotos: []
    },
    {
      id: 'Lsdopsdssasa-787sdjkHJJdskds-adskdhas55s',
      "count": 1,
      "collection": "Versace",
      "category": "watches",
      "labelName": "watch_name|A",
      "brand": "Versace",
      "price": 45990,
      "salePrice": 39990,
      "images": [
        {
          id: 'Lsdopsdssasa-787sdjkHJJdskds-adskasdsdhas55s',
          "url": "https://live.staticflickr.com/65535/52706785568_85c5f39a79_z.jpg"
        }
      ],
      "weight": 160,
      "colors": [
        {
          id: 'Lsdopsdssasa-787sdjkHasdasJJdskds-adskdhas55s',
          "name": "silver|A"
        }
      ],
      "metal": "steel|A",
      "sizes": [
        {
          id: 'Lsdopsdadssssasa-787sdjkHJJdskds-adskdhas55s',
          "value": "єдиний розмір"
        }
      ],
      "description": "string",
      "popularity": 14,
      "customPopularity": 14,
      "packaging": "Оригінальна коробка бренду|A",
      realPhotos: []
    },
    {
      id: 'Ksdsjdopsdss-787sdskds-adskdhas55s',
      "count": 1,
      "collection": "Guess",
      "category": "watches",
      "labelName": "watch_name|A",
      "brand": "Guess",
      "price": 6390,
      "salePrice": 4880,
      "images": [
        {
          id: 'Ksdsjdopsdss-787sdasdskds-adskdhas55s',
          "url": "https://live.staticflickr.com/65535/52758213929_5f07da0b9e_z.jpg"
        },
        {
          id: 'Ksdsjdopsdss-787sdskds-adskasdasdhas55s',
          "url": "https://live.staticflickr.com/65535/52758213924_48cd0f35a9_z.jpg"
        },
        {
          id: 'Ksdsjasdasdopsdss-787sdskds-adskdhas55s',
          "url": "https://live.staticflickr.com/65535/52758213944_f02b600eb5_z.jpg"
        },
        {
          id: 'Ksdsjdopsdss-787sdskds-adskdasdasdascas55s',
          "url": "https://live.staticflickr.com/65535/52758213894_31a93f2799_z.jpg"
        }
      ],
      "weight": 160,
      "colors": [
        {
          id: 'Ksdsjdopsdss-dskhjaskdss-adskdhas55s',
          "name": "gold|A"
        },
        {
          id: 'Ksdsjdopsdss-787sdskds-adskdhaasdasdss55s',
          "name": "rosegold|A"
        }
      ],
      "metal": "steel|A",
      "sizes": [
        {
          id: 'Ksdsjdopsdss-KJsdihdisdds-adskdhas55s',
          "value": "єдиний розмір"
        }
      ],
      "description": "Над моделями цієї колекції працюють провідні європейські дизайнери. Всі моделі виходять одночасно з новими колекціями одягу, отже, всі риси виробів є у тренді. В основі годинника використовуються високоточні швейцарські механізми ETA і Ronda, завдяки чому вироби нічим не поступаюся швейцарському годиннику знаменитих марок.",
      "popularity": 8,
      "customPopularity": 2,
      "packaging": "Оригінальна коробка бренду|A",
      realPhotos: []
    },
    {
      id: 'KJKdsjisdps54d5s-787sdskds-adskdhas55s',
      "count": 1,
      "collection": "s.Oliver",
      "category": "watches",
      "labelName": "watch_name|A",
      "brand": "s.Oliver",
      "price": 4430,
      "salePrice": 3190,
      "images": [
        {
          id: 'KJKdsjisdps54d5s-48asd4-aksjdis',
          "url": "https://live.staticflickr.com/65535/52768198220_359137cd97_z.jpg"
        },
        {
          id: 'KJKdsjisdps54d5s-48aasdssd4-aksjdis',
          "url": "https://live.staticflickr.com/65535/52767248262_c7d3290b42_z.jpg"
        },
        {
          id: 'asdv-48asd4-aksjdis',
          "url": "https://live.staticflickr.com/65535/52768038494_233e819ac4_z.jpg"
        }
      ],
      "weight": 160,
      "colors": [
        {
          id: 'KJKdsjisdps54d5s-48asd4-asddxcZcx',
          "name": "rosegold|A"
        }
      ],
      "metal": "steel|A",
      "sizes": [
        {
          id: 'KJKdsjisdpsasdsa54d5s-48asd4-aksjdis',
          "value": "єдиний розмір"
        }
      ],
      "description": "Незмінна класика та маст хев у гардеробі кожної жінки. Корпус із якісної сталі та надійний механізм - те, що дозволяє годиннику прослужити довгий час та не втратити свого вигляду",
      "popularity": 10,
      "customPopularity": 3,
      "packaging": "Оригінальна коробка бренду|A",
      realPhotos: []
    },
    {
      id: 'NJsdhsd45sdds-48asasdd4-aksasdjdis',
      "count": 1,
      "collection": "Lancaster Italy",
      "category": "watches",
      "labelName": "watch_name|A",
      "brand": "Lancaster Italy",
      "price": 69995,
      "salePrice": 52665,
      "images": [
        {
          id: 'NJsdhsd45sdds-48asasdd4asdasd-aksasdjdis',
          "url": "https://live.staticflickr.com/65535/52829290507_7f2053829f_z.jpg"
        },
        {
          id: 'NJsdhsd45sdds-48aadssassasdd4-aksasdjdis',
          "url": "https://live.staticflickr.com/65535/52829289837_341854a36b_z.jpg"
        },
        {
          id: 'asdac-48asasdd4-adas5d4dsa',
          "url": "https://live.staticflickr.com/65535/52829865636_656f570bb3_z.jpg"
        }
      ],
      "weight": 160,
      "colors": [
        {
          id: 'NJsdhsd45sdds-48asdsaasasdd4-aksasdjdis',
          "name": "white|A"
        }
      ],
      "metal": "кераміка",
      "sizes": [
        {
          id: 'NJsdhsd45sdds-48asaasdasd4a5ssdd4-aksasdjdis',
          "value": "єдиний розмір"
        }
      ],
      "description": "Новий, красивий і унікальний жіночий годинник, виготовлений в Італії та прикрашений 70 діамантами (загальна кількість 0,59 карата). Годинник стане ідеальним подарунком і подарує довготривале задоволення першому власнику. Корпус: біла кераміка, діаметр 33 мм, висота 10 мм, загартоване мінеральне кришталеве скло, водонепроникність 50 м (5 ATM), безель із червоним золотом із 70 діамантами 0,59 карата. Серійний номер: OLA0649RG/BN",
      "popularity": 15,
      "customPopularity": 15,
      "packaging": "Оригінальна коробка бренду, сертифікат на діаманти, паспорт на годинник",
      realPhotos: []
    },
    {
      id: 'KKHKsd5ssd-NHJHs54d5s-aksasdjdis',
      "count": 1,
      "collection": "Philipp Plein Skull Watch",
      "category": "watches",
      "labelName": "watch_name|A",
      "brand": "Philipp Plein",
      "price": 17777,
      "salePrice": 0,
      "images": [
        {
          id: 'KKHKsd5ssd-asdads5asds5a-aksasdjdis',
          "url": "https://live.staticflickr.com/65535/52832655695_e7644ee88c_z.jpg"
        },
        {
          id: 'KKHKsasdsd5ssd-NHJHs54d5s-aksasdjdis',
          "url": "https://live.staticflickr.com/65535/52832255026_44a9825ac9_z.jpg"
        },
        {
          id: 'KKHKsd5ssd-NHJHs54d5s-aksasads45sdjdis',
          "url": "https://live.staticflickr.com/65535/52832655655_cdf955ebfc_z.jpg"
        }
      ],
      "weight": 120,
      "colors": [
        {
          id: 'KKHKsd5ssd-NHJHs54asdasdsdd5s-aksasdjdis',
          "name": "silver|A"
        }
      ],
      "metal": "steel|A",
      "sizes": [
        {
          id: 'KKHKsd5ssd-NHJHsadasdsa54d5s-aksasdjdis',
          "value": "єдиний розмір"
        }
      ],
      "description": "Оригінальний чоловічий годинник відомого бренду. Гарантія на механізм 24 місяці. Кварцевий механізм, загартоване мінеральне скло, водостійкість 5 АТМ. Корпус - нержавіюча сталь. Виробник: Німеччина",
      "popularity": 3,
      "customPopularity": 3,
      "packaging": "Оригінальна коробка бренду, паспорт на годинник",
      realPhotos: []
    },
  
    {
      id: 'IOOsakhdas-NsdsdHJHs54d5s-aksasdjdis',
      "count": 1,
      "collection": "Philipp Plein Skull Watch",
      "category": "watches",
      "labelName": "watch_name|A",
      "brand": "Philipp Plein",
      "price": 17777,
      "salePrice": 0,
      "images": [
        {
          id: 'IOOsakhdas-adsasd5as-aksasdjdis',
          "url": "https://live.staticflickr.com/65535/52904827768_cb3e760b0e_z.jpg"
        },
        {
          id: 'IOOsakhdas-adsas-aksasdjdis',
          "url": "https://live.staticflickr.com/65535/52904762410_9970f20aa7_z.jpg"
        },
        {
          id: 'IOOsakhdas-NsdsdHJadasHs54d5s-aksasdjdis',
          "url": "https://live.staticflickr.com/65535/52904536104_d36a30f91f_z.jpg"
        },
        {
          id: 'IOOsakasdsashdas-NsdsdHJHs54d5s-aksasdjdis',
          "url": "https://live.staticflickr.com/65535/52904827783_121a8659b3_z.jpg"
        }
      ],
      "weight": 120,
      "colors": [
        {
          id: 'IOOsakhdas-Nsdasd5s-aksasdjdis',
          "name": "white|A"
        }
      ],
      "metal": "steel|A",
      "sizes": [
        {
          id: 'IOOsakhdas-Nsdasd5s-aksasasdasddjdis',
          "value": "єдиний розмір"
        }
      ],
      "description": "Оригінальний годинник унісекс відомого бренду. Гарантія на механізм 24 місяці. Кварцевий механізм, загартоване мінеральне скло, водостійкість 5 АТМ. Корпус - нержавіюча сталь. Виробник: Німеччина",
      "popularity": 3,
      "customPopularity": 3,
      "packaging": "Оригінальна коробка бренду, паспорт на годинник, сертифікат та гарантійний талон",
      realPhotos: []
    },

  // CHARMS

  {
    id: 'LSnksjojdsd7834-Nsdadsasd5s-ajksdjds',
    "count": 1,
    "collection": "marvel",
    "category": "charms",
    "labelName": "charm_name|A",
    "brand": "Pandora",
    "price": 690,
    "salePrice": 350,
    "images": [
      {
        id: 'LJklsd-NKsd55ds-ajksdjds',
        "url": "https://live.staticflickr.com/65535/52780363265_63e645bab3_z.jpg"
      },
      {
        id: 'LJklsd-NKsasdd55ds-ajksdjds',
        "url": "https://live.staticflickr.com/65535/52780417608_b9dcc1767c_z.jpg"
      },
      {
        id: 'LJklassd-NKsd55ds-ajksdjds',
        "url": "https://live.staticflickr.com/65535/52780363485_760a8457f2_z.jpg"
      }
    ],
    "weight": 3.2,
    "colors": [
      {
        id: 'LJklsd-NKsd55ds-ajkadssdjds',
        "name": "gold|A"
      }
    ],
    "metal": "silver 925",
    "sizes": [
      {
        id: 'LJklsd-NKsdasd55ds-ajksdjds',
        "value": "єдиний розмір"
      }
    ],
    "description": "Сучасно та стильно - довершена срібна намистина із покриттям золота 18к, оригінальне доповнення до твоєї колекції та особливий варіант на подарунок для усіх поціновувачів Marvel. Зручність намистини у тому, що її можна носити як на браслеті, так і як підвіску на твоєму ланцюжку ",
    "popularity": 5,
    "customPopularity": 3,
    "packaging": "Оригінальний мішечок бренду та фірмовий пакет TVOI",
    realPhotos: []
  },
  {
    id: 'OKjsdjod-NHIsdds-ajksdjds',
    "count": 1,
    "collection": "star wars",
    "category": "charms",
    "labelName": "charm_name|A",
    "brand": "Pandora",
    "price": 590,
    "salePrice": 350,
    "images": [
      {
        id: 'OKjsdjod-NHadsadIsdds-ajksdjds',
        "url": "https://live.staticflickr.com/65535/52779945476_37456a613d_z.jpg"
      },
      {
        id: 'OKjsdjod-NHadsadIsdds-ajkadssdjds',
        "url": "https://live.staticflickr.com/65535/52780363085_fe948359f7_z.jpg"
      },
      {
        id: 'OKjsdjod-PJOsdsds-ajkadssdjds',
        "url": "https://live.staticflickr.com/65535/52780363190_2a321c2d96_z.jpg"
      }
    ],
    "weight": 4.1,
    "colors": [
      {
        id: 'JKIHIdaskpss-PJOsdsds-ajkadssdjds',
        "name": "silver|A"
      }
    ],
    "metal": "silver 925",
    "sizes": [
      {
        id: 'JKIHIdaskpss-PJOadssdsds-ajkadssdjds',
        "value": "єдиний розмір"
      }
    ],
    "description": "Намистина з підвісками Йода з колекції Star Wars — прикраса для справжніх фанатів. Цю довершену вручну срібну прикрасу доповнено двома підвісками: на ажурній підвісці зображено персонажа Yoda™ з палкою, оповитою плющем, а підвіску-диск оздоблено у лісовому стилі та вручну розфарбовано емаллю зеленого та синього кольорів",
    "popularity": 5,
    "customPopularity": 3,
    "packaging": "Оригінальний мішечок бренду та фірмовий пакет TVOI",
    realPhotos: []
  },
  {
    id: 'NKjhisd59ds59-PJOsdsds-ajkadssdjds',
    "count": 1,
    "collection": "star wars",
    "category": "charms",
    "labelName": "charm_name|A",
    "brand": "Pandora",
    "price": 535,
    "salePrice": 350,
    "images": [
      {
        id: 'Ksdsd-PJOsdsds-ajkadssdjds',
        "url": "https://live.staticflickr.com/65535/52780417533_b41f479faa_z.jpg"
      },
      {
        id: 'Ksdsd-PJOasdsdsds-ajkadssdjds',
        "url": "https://live.staticflickr.com/65535/52780417523_a6caa82c12_z.jpg"
      }
    ],
    "weight": 3.4,
    "colors": [
      {
        id: 'Ksdsd-PJOsdsds-ajkadasdssdjds',
        "name": "silver|A"
      }
    ],
    "metal": "silver 925",
    "sizes": [
      {
        id: 'Ksdsd-PJOasdadssdsds-ajkadssdjds',
        "value": "єдиний розмір"
      }
    ],
    "description": "Намистина Чубакка з колекції Зоряні війни завжди нагадуватиме вам про силу справжньої дружби. Ця, довершена вручну, срібна прикраса, з нанесеними вручну темно-коричневою та чорною емаллю, зображує Чубакку, одного з найулюбленіших героїв всіх фанатів Зоряних воєн",
    "popularity": 5,
    "customPopularity": 3,
    "packaging": "Оригінальний мішечок бренду та фірмовий пакет TVOI",
    realPhotos: []
  },
  {
    id: 'HARosklsd-PJOsdsds-ajkadssdjds',
    "count": 1,
    "collection": "Harry Potter",
    "category": "charms",
    "labelName": "charm_name|A",
    "brand": "Pandora",
    "price": 590,
    "salePrice": 420,
    "images": [
      {
        id: 'HARosklsd-asdasda-ajkadssdjds',
        url: "/images/charms/harry_11.png"
      },
      {
        id: 'HARosklsd-LKJkldsds-ajkadssdjds',
        url: "/images/charms/harry_12.png"
      },
      {
        id: 'HARosklsd-Ppoidsojdla-ajkadssdjds',
        url: "/images/charms/harry_13.png"
      },
      {
        id: 'HARosklsd-NKJBdaskds-ajkadssdjds',
        url: "/images/charms/harry_14.png"
      },
    ],
    "weight": 3.4,
    "colors": [
      {
        id: 'HARoskKLsdslsd-adssasaads-ajkadasdssdjds',
        "name": "silver|A"
      }
    ],
    "metal": "silver 925",
    "sizes": [
      {
        id: 'Ksdsd-PJOasdadssdsds-Lkjksldsd',
        "value": "єдиний розмір"
      }
    ],
    "description": "",
    "popularity": 2,
    "customPopularity": 2,
    "packaging": "Оригінальний мішечок бренду та фірмовий пакет TVOI",
    realPhotos: []
  },
  {
    id: 'HARosklsd-PJOsdsds-OPjdksdss',
    "count": 1,
    "collection": "Harry Potter",
    "category": "charms",
    "labelName": "charm_name|A",
    "brand": "Pandora",
    "price": 590,
    "salePrice": 420,
    "images": [
      {
        id: 'HARosklsd-asdasda-UHJdklsdnksdc',
        url: "/images/charms/harry_21.png"
      },
      {
        id: 'HARosklsd-LKJkldsds-MCKshdsdw',
        url: "/images/charms/harry_12.png"
      },
      {
        id: 'HARosklsd-Ppoidsojdla-PIIhsdislcsdv',
        url: "/images/charms/harry_13.png"
      },
      {
        id: 'HARosklsd-NKJBdaskds-MSDknhfkddf',
        url: "/images/charms/harry_24.png"
      },
    ],
    "weight": 3.4,
    "colors": [
      {
        id: 'HARoskKLsdslsd-IUI7sd89dsd-ajkadasdssdjds',
        "name": "silver|A"
      }
    ],
    "metal": "silver 925",
    "sizes": [
      {
        id: 'Ksdsd-klcd8d9s-Lkjksldsd',
        "value": "єдиний розмір"
      }
    ],
    "description": "",
    "popularity": 2,
    "customPopularity": 2,
    "packaging": "Оригінальний мішечок бренду та фірмовий пакет TVOI",
    realPhotos: []
  },
  {
    id: 'HARosklsd-KUD8s7df9sfsdf-sfddfhjhgjbv',
    "count": 1,
    "collection": "Harry Potter",
    "category": "charms",
    "labelName": "charm_name|A",
    "brand": "Pandora",
    "price": 590,
    "salePrice": 420,
    "images": [
      {
        id: 'HARosklsd-687sdfkjksdf-UHJdklsdnksdc',
        url: "/images/charms/harry_31.png"
      },
      {
        id: 'HARosklsd-MKjdi7f8sd-MCKshdsdw',
        url: "/images/charms/harry_12.png"
      },
      {
        id: 'HARosklsd-KJKjdfkjfksff-PIIhsdislcsdv',
        url: "/images/charms/harry_13.png"
      },
      {
        id: 'HARosklsd-Pdsfkjdsjisfgfopds-MSDsdfdghg5d6fknhfkddf',
        url: "/images/charms/harry_34.png"
      },
    ],
    "weight": 3.4,
    "colors": [
      {
        id: 'HARoskKLsdslsd-PIjfdflsdvv-ajkadasdssdjds',
        "name": "silver|A"
      }
    ],
    "metal": "silver 925",
    "sizes": [
      {
        id: 'Ksdsd-KOJdodkfpkdpfdf-Lkjksldsd',
        "value": "єдиний розмір"
      }
    ],
    "description": "",
    "popularity": 2,
    "customPopularity": 2,
    "packaging": "Оригінальний мішечок бренду та фірмовий пакет TVOI",
    realPhotos: []
  },
  {
    id: 'LIOSMKSHDJSHJ-KUD8s7df9sfsdf-sfddfhjhgjbv',
    "count": 1,
    "collection": "Lion Zodiac Sign",
    "category": "charms",
    "labelName": "charm_name|A",
    "brand": "Pandora",
    "price": 1195,
    "salePrice": 795,
    "images": [
      {
        id: 'HARosklsd-687sdfkjksdf-UHJdklsdnksdc',
        url: "https://nz.pandora.net/dw/image/v2/BKNF_PRD/on/demandware.static/-/Sites-pandora-master-catalog/default/dw1b0afdbd/productimages/singlepackshot/798414C01_RGB.jpg?sw=900&sh=900&sm=fit&sfrm=png&bgcolor=F2F2F2"
      },
      {
        id: 'HARosklsd-MKjdi7f8sd-MCKshdsdw',
        url: "https://nz.pandora.net/dw/image/v2/BKNF_PRD/on/demandware.static/-/Sites-pandora-master-catalog/default/dwf1039aad/productimages/modelshot/798414C01_ABC123_MODEL_eCOM_02_RGB.jpg?sw=900&sh=900&sm=fit&sfrm=png&bgcolor=F2F2F2"
      },
      {
        id: 'HARosklsd-KJKjdfkjfksff-PIIhsdislcsdv',
        url: "https://nz.pandora.net/dw/image/v2/BKNF_PRD/on/demandware.static/-/Sites-pandora-master-catalog/default/dwf91027d2/productimages/singlepackshot/798414C01_V2_RGB.jpg?sw=900&sh=900&sm=fit&sfrm=png&bgcolor=F2F2F2"
      }
    ],
    "weight": 3.4,
    "colors": [
      {
        id: 'HARoskKLsdslsd-PIjfdflsdvv-ajkadasdssdjds',
        "name": "silver|A"
      }
    ],
    "metal": "silver 925",
    "sizes": [
      {
        id: 'Ksdsd-KOJdodkfpkdpfdf-Lkjksldsd',
        "value": "єдиний розмір"
      }
    ],
    "description": "",
    "popularity": 2,
    "customPopularity": 2,
    "packaging": "Оригінальний мішечок бренду та фірмовий пакет TVOI",
    realPhotos: []
  },
  {
    id: 'CLISadsdds-KUD8s7df9sfsdf-sfddfhjhgjbv',
    "count": 1,
    "collection": "Pandora",
    "category": "charms",
    "labelName": "charm_name|A",
    "brand": "Pandora",
    "price": 590,
    "salePrice": 350,
    "images": [
      {
        id: 'CLISadsdds-687sdfkjksdf-UHJdklsdnksdc',
        url: "https://thumbs2.imgbox.com/67/90/o87ymbLu_t.png"
      },
      {
        id: 'CLISadsdds-MKjdi7f8sd-MCKshdsdw',
        url: "https://thumbs2.imgbox.com/fc/30/Ayg0ZhdS_t.png"
      },
    ],
    "weight": 1.4,
    "colors": [
      {
        id: 'CLISadsdds-PIjfdflsdvv-ajkadasdssdjds',
        "name": "silver|A"
      }
    ],
    "metal": "silver 925",
    "sizes": [
      {
        id: 'CLISadsdds-KOJdodkfpkdpfdf-Lkjksldsd',
        "value": "єдиний розмір"
      }
    ],
    "description": "",
    "popularity": 2,
    "customPopularity": 2,
    "packaging": "Оригінальний мішечок бренду та фірмовий пакет TVOI",
    realPhotos: []
  },
  {
    id: 'CLISadsdds-LJOiojskds-sfddfhjhgjbv',
    "count": 0,
    "collection": "Pandora",
    "category": "charms",
    "labelName": "charm_name|A",
    "brand": "Pandora",
    "price": 590,
    "salePrice": 0,
    "images": [
      {
        id: 'CLISadsdds-LJOiojskds-KLoujisuisdsd',
        url: "https://thumbs2.imgbox.com/ec/39/soxU7FHu_t.png"
      },
      {
        id: 'CLISadsdds-LJOiojskds-MCKshdsdw',
        url: "https://thumbs2.imgbox.com/b2/b6/QVdvRl69_t.png"
      },
      {
        id: 'CLISadsdds-LJOiojskds-UHJdklsdnksdc',
        url: "https://thumbs2.imgbox.com/5e/b7/wavsG0Fr_t.png"
      },
    ],
    "weight": 1.4,
    "colors": [
      {
        id: 'CLISadsdds-LJOiojskds-ajkadasdssdjds',
        "name": "silver|A"
      }
    ],
    "metal": "silver 925",
    "sizes": [
      {
        id: 'CLISadsdds-LJOiojskds-Lkjksldsd',
        "value": "єдиний розмір"
      }
    ],
    "description": "",
    "popularity": 2,
    "customPopularity": 2,
    "packaging": "Оригінальний мішечок бренду та фірмовий пакет TVOI",
    realPhotos: []
  },
  {
    id: 'CLISadsdds-LJOiojskds-POUHidsds',
    "count": 0,
    "collection": "Pandora",
    "category": "charms",
    "labelName": "charm_name|A",
    "brand": "Pandora",
    "price": 590,
    "salePrice": 0,
    "images": [
      {
        id: 'CLISadsdds-LJOiojskds-POUHidsds',
        url: "https://thumbs2.imgbox.com/94/41/t35hJ5RM_t.png"
      },
      {
        id: 'CLISadsdds-LJOiojskds-POUHidsds',
        url: "https://thumbs2.imgbox.com/ef/5f/qyOZ5gr5_t.png"
      },
      {
        id: 'CLISadsdds-LJOiojskds-POUHidsds',
        url: "https://thumbs2.imgbox.com/cd/13/VWpMAB9c_t.png"
      },
    ],
    "weight": 1.4,
    "colors": [
      {
        id: 'CLISadsdds-LJOiojskds-POUHidsds',
        "name": "silver|A"
      }
    ],
    "metal": "silver 925",
    "sizes": [
      {
        id: 'CLISadsdds-LJOiojskds-POUHidsds',
        "value": "єдиний розмір"
      }
    ],
    "description": "",
    "popularity": 2,
    "customPopularity": 2,
    "packaging": "Оригінальний мішечок бренду та фірмовий пакет TVOI",
    realPhotos: []
  },
  //ACCESSORIES
  //BAGS
  {
    id: 'VERSJKJA-KUD8s7df9sfsdf-sfddfhjhgjbv',
    "count": 1,
    "collection": "Versus Versace",
    "category": "accessories",
    "labelName": "woman_bag|A",
    "brand": "Versace",
    "price": 2495,
    "salePrice": 1590,
    "images": [
      {
        id: 'VERSJKJA-687sdfkjksdf-UHJdklsdnksdc',
        url: "/images/accessories/bags/versace/1.png"
      },
      {
        id: 'VERSJKJA-MKjdi7f8sd-MCKshdsdw',
        url: "/images/accessories/bags/versace/2.png"
      },
      {
        id: 'VERSJKJA-KJKjdfkjfksff-PIIhsdislcsdv',
        url: "/images/accessories/bags/versace/3.png"
      }
    ],
    "weight": 0,
    "colors": [
      {
        id: 'VERSJKJA-PIjfdflsdvv-ajkadasdssdjds',
        "name": "black|A"
      }
    ],
    "metal": "eko|A",
    "sizes": [
      {
        id: 'VERSJKJA-KOJdodkfpkdpfdf-Lkjksldsd',
        "value": "єдиний розмір"
      }
    ],
    "description": "",
    "popularity": 1,
    "customPopularity": 1,
    "packaging": "",
    realPhotos: []
  },
  {
    id: 'HERMKELLSKJFDKJk-KUD8s7df9sfsdf-sfddfhjhgjbv',
    "count": 1,
    "collection": "Hermes Kelly",
    "category": "accessories",
    "labelName": "woman_clatch|A",
    "brand": "Hermes Kelly",
    "price": 3225,
    "salePrice": 2995,
    "images": [
      {
        id: 'VERSJKJA-687sdfkjksdf-UHJdklsdnksdc',
        url: "https://thumbs2.imgbox.com/6a/95/vMSJUFGX_t.jpg"
      },
      {
        id: 'VERSJKJA-MKjdi7f8sd-MCKshdsdw',
        url: "https://thumbs2.imgbox.com/a7/0a/57jFoAGn_t.jpg"
      },
      {
        id: 'VERSJKJA-KJKjdfkjfksff-PIIhsdislcsdv',
        url: "https://thumbs2.imgbox.com/85/bc/OcRNE2Yy_t.jpg"
      },
      {
        id: 'VERSJKJA-KJKjdfkjfksff-PIIhsdislcsdv',
        url: "https://thumbs2.imgbox.com/de/40/YykhvFRz_t.jpg"
      },
      {
        id: 'VERSJKJA-KJKjdfkjfksff-PIIhsdislcsdv',
        url: "https://thumbs2.imgbox.com/c1/14/FN9389gQ_t.jpg"
      },
      {
        id: 'VERSJKJA-KJKjdfkjfksff-PIIhsdislcsdv',
        url: "https://thumbs2.imgbox.com/27/4e/bJtCqEZy_t.jpg"
      },
      {
        id: 'VERSJKJA-KJKjdfkjfksff-PIIhsdislcsdv',
        url: "https://thumbs2.imgbox.com/0d/fb/s2AdepPn_t.jpg"
      },
      {
        id: 'VERSJKJA-KJKjdfkjfksff-PIIhsdislcsdv',
        url: "https://thumbs2.imgbox.com/3a/57/epG4o0BC_t.jpg"
      }
    ],
    "weight": 0,
    "colors": [
      {
        id: 'VERSJKJA-PIjfdflsdvv-ajkadasdssdjds',
        "name": "orange|A"
      }
    ],
    "metal": "eko|A",
    "sizes": [
      {
        id: 'VERSJKJA-KOJdodkfpkdpfdf-Lkjksldsd',
        "value": "єдиний розмір"
      }
    ],
    "description": "Яскрава,впізнавана,класична та елегантна - Hermes Kelly. Обираючи цю сумку, ви обираєте не просто аксесуар, а люксовий шарм і вишуканий смак, що додають особливий штрих до вашого стилю. Це не просто річ – це частинка вашого життя, сповненого елегантності та неповторності|A",
    "popularity": 1,
    "customPopularity": 1,
    "packaging": "",
    realPhotos: []
  },
  // GLASSES

  {
    id: 'CARIEJKDJKS-sdflksdlfslsdg-sfddfhjhgjbv',
    "count": 1,
    "collection": "Сartier",
    "category": "accessories",
    "labelName": "glasses|A",
    "brand": "Сartier",
    "price": 1190,
    "salePrice": 799,
    "images": [
      {
        id: 'CARIEJKDJKS-687sdfkjksdf-UHJdklsdnksdc',
        url: "/images/accessories/glasses/cartier/1.png"
      },
      {
        id: 'CARIEJKDJKS-MKjdi7f8sd-MCKshdsdw',
        url: "/images/accessories/glasses/cartier/2.png"
      },
      {
        id: 'CARIEJKDJKS-KJKjdfkjfksff-PIIhsdislcsdv',
        url: "/images/accessories/glasses/cartier/3.png"
      }
    ],
    "weight": 0,
    "colors": [
      {
        id: 'CARIEJKDJKS-PIjfdflsdvv-ajkadasdssdjds',
        "name": "gold|A"
      }
    ],
    "metal": "сплав металів",
    "sizes": [
      {
        id: 'CARIEJKDJKS-KOJdodkfpkdpfdf-Lkjksldsd',
        "value": "єдиний розмір"
      }
    ],
    "description": "",
    "popularity": 5,
    "customPopularity": 5,
    "packaging": "Чохол для окулярів, документи",
    realPhotos: [
      {
        id: 'CARIEJKDJKS-687sdfkjksdf-UHJdklsdnksdc',
        url: "/real_photos/glasses/1.jpg"
      },
      {
        id: 'CARIEJKDJKS-MKjdi7f8sd-MCKshdsdw',
        url: "/real_photos/glasses/2.jpg"
      },
      {
        id: 'CARIEJKDJKS-KJKjdfkjfksff-PIIhsdislcsdv',
        url: "/real_photos/glasses/3.jpg"
      },
      {
        id: 'CARIEJKDJKS-KJKjdfkjfksff-PIIhsdislcsdv',
        url: "/real_photos/glasses/4.jpg"
      }
    ]
  },
  {
    id: 'TVOISJAKJSKD-sdflksdlfslsdg-sfddfhjhgjbv',
    "count": 1,
    "collection": "Сartier",
    "category": "accessories",
    "labelName": "glasses|A",
    "brand": "TVOI",
    "price": 690,
    "salePrice": 499,
    "images": [
      {
        id: 'TVOISJAKJSKD-MKjdi7f8sd-MCKshdsdw',
        url: "https://thumbs2.imgbox.com/f4/50/Qw47xKdV_t.jpg"
      },
      {
        id: 'TVOISJAKJSKD-687sdfkjksdf-UHJdklsdnksdc',
        url: "https://thumbs2.imgbox.com/03/65/pdFzfqzt_t.jpg"
      },
      {
        id: 'TVOISJAKJSKD-KJKjdfkjfksff-PIIhsdislcsdv',
        url: "https://thumbs2.imgbox.com/ec/58/4WhBHX2C_t.jpg"
      },
      {
        id: 'TVOISJAKJSKD-687sdfkjksdf-UHJdklsdnksdc',
        url: "https://thumbs2.imgbox.com/11/fa/COzGjiik_t.jpg"
      },
      {
        id: 'TVOISJAKJSKD-MKjdi7f8sd-MCKshdsdw',
        url: "https://thumbs2.imgbox.com/78/58/2pDhBG5k_t.jpg"
      },
      {
        id: 'TVOISJAKJSKD-KJKjdfkjfksff-PIIhsdislcsdv',
        url: "https://thumbs2.imgbox.com/ef/d4/4WsnDXno_t.jpg"
      },
      {
        id: 'TVOISJAKJSKD-KJKjdfkjfksff-PIIhsdislcsdv',
        url: "https://thumbs2.imgbox.com/0d/8d/fFCftTKj_t.jpg"
      },
      {
        id: 'TVOISJAKJSKD-KJKjdfkjfksff-PIIhsdislcsdv',
        url: "https://thumbs2.imgbox.com/d6/6b/61GIxigo_t.jpg"
      }
    ],
    "weight": 0,
    "colors": [
      {
        id: 'TVOISJAKJSKD-PIjfdflsdvv-ajkadasdssdjds',
        "name": "lightblue|A"
      },
      {
        id: 'TVOISJAKJSKD-PIjfdflsdvv-ajkadasdssdjds',
        "name": "brown|A"
      },
      {
        id: 'TVOISJAKJSKD-PIjfdflsdvv-ajkadasdssdjds',
        "name": "black|A"
      },
      {
        id: 'TVOISJAKJSKD-PIjfdflsdvv-ajkadasdssdjds',
        "name": "yellow|A"
      },
      {
        id: 'TVOISJAKJSKD-PIjfdflsdvv-ajkadasdssdjds',
        "name": "oilgreen|A"
      }
    ],
    "metal": "steel|A",
    "sizes": [
      {
        id: 'TVOISJAKJSKD-KOJdodkfpkdpfdf-Lkjksldsd',
        "value": "єдиний розмір"
      }
    ],
    "description": "glasses_desc|A",
    "popularity": 5,
    "customPopularity": 5,
    "packaging": "",
    realPhotos: []
  },
  {
    id: 'TVOISJAKDHHSHGaj74389-sdflksdlfslsdg-sfddfhjhgjbv',
    "count": 1,
    "collection": "Сartier",
    "category": "accessories",
    "labelName": "glasses|A",
    "brand": "TVOI",
    "price": 995,
    "salePrice": 659,
    "images": [
      {
        id: 'TVOISJAKJSKD-MKjdi7f8sd-MCKshdsdw',
        url: "https://thumbs2.imgbox.com/c2/77/OStiIgog_t.jpg"
      },
      {
        id: 'TVOISJAKJSKD-687sdfkjksdf-UHJdklsdnksdc',
        url: "https://thumbs2.imgbox.com/ac/18/iFl28RiN_t.jpg"
      },
      {
        id: 'TVOISJAKJSKD-KJKjdfkjfksff-PIIhsdislcsdv',
        url: "https://thumbs2.imgbox.com/ec/80/moZrnZ2T_t.jpg"
      },
      {
        id: 'TVOISJAKJSKD-687sdfkjksdf-UHJdklsdnksdc',
        url: "https://thumbs2.imgbox.com/31/4f/48iRpRze_t.jpg"
      },
      {
        id: 'TVOISJAKJSKD-KJKjdfkjfksff-PIIhsdislcsdv',
        url: "https://thumbs2.imgbox.com/75/9b/ulAM6jb9_t.jpg"
      }
    ],
    "weight": 0,
    "colors": [
      {
        id: 'TVOISJAKJSKD-PIjfdflsdvv-ajkadasdssdjds',
        "name": "lightblue|A"
      }
    ],
    "metal": "steel|A",
    "sizes": [
      {
        id: 'TVOISJAKJSKD-KOJdodkfpkdpfdf-Lkjksldsd',
        "value": "єдиний розмір"
      }
    ],
    "description": "glasses_desc|A",
    "popularity": 5,
    "customPopularity": 5,
    "packaging": "",
    realPhotos: []
  },
  {
    id: 'TVKSJDKSJOeare-sdflksdlfslsdg-sfddfhjhgjbv',
    "count": 1,
    "collection": "Сartier",
    "category": "accessories",
    "labelName": "glasses|A",
    "brand": "TVOI",
    "price": 995,
    "salePrice": 629,
    "images": [
      {
        id: 'TVOISJAKJSKD-687sdfkjksdf-UHJdklsdnksdc',
        url: "https://thumbs2.imgbox.com/c4/af/gtxgWiL7_t.jpg"
      },
      {
        id: 'TVOISJAKJSKD-MKjdi7f8sd-MCKshdsdw',
        url: "https://thumbs2.imgbox.com/e3/bf/2c6UWtnV_t.jpg"
      },
      {
        id: 'TVOISJAKJSKD-KJKjdfkjfksff-PIIhsdislcsdv',
        url: "https://thumbs2.imgbox.com/c1/3d/8Ftoazh1_t.jpg"
      }
    ],
    "weight": 0,
    "colors": [
      {
        id: 'TVOISJAKJSKD-PIjfdflsdvv-ajkadasdssdjds',
        "name": "brown|A"
      }
    ],
    "metal": "steel|A",
    "sizes": [
      {
        id: 'TVOISJAKJSKD-KOJdodkfpkdpfdf-Lkjksldsd',
        "value": "єдиний розмір"
      }
    ],
    "description": "glasses_desc|A",
    "popularity": 5,
    "customPopularity": 5,
    "packaging": "",
    realPhotos: []
  },
  {
    id: 'TVOPSiodjeafjrklwdew-sdflksdlfslsdg-sfddfhjhgjbv',
    "count": 1,
    "collection": "Сartier",
    "category": "accessories",
    "labelName": "glasses|A",
    "brand": "TVOI",
    "price": 995,
    "salePrice": 699,
    "images": [
      {
        id: 'TVOISJAKJSKD-687sdfkjksdf-UHJdklsdnksdc',
        url: "https://thumbs2.imgbox.com/1f/03/SL58mfeV_t.jpg"
      },
      {
        id: 'TVOISJAKJSKD-MKjdi7f8sd-MCKshdsdw',
        url: "https://thumbs2.imgbox.com/68/a0/bmlbZz6D_t.jpg"
      },
      {
        id: 'TVOISJAKJSKD-KJKjdfkjfksff-PIIhsdislcsdv',
        url: "https://thumbs2.imgbox.com/f0/ac/ZAuFUBac_t.jpg"
      },
      {
        id: 'TVOISJAKJSKD-KJKjdfkjfksff-PIIhsdislcsdv',
        url: "https://thumbs2.imgbox.com/dc/ec/RCOcwBuO_t.jpg"
      }
    ],
    "weight": 0,
    "colors": [
      {
        id: 'TVOISJAKJSKD-PIjfdflsdvv-ajkadasdssdjds',
        "name": "brown|A"
      }
    ],
    "metal": "steel|A",
    "sizes": [
      {
        id: 'TVOISJAKJSKD-KOJdodkfpkdpfdf-Lkjksldsd',
        "value": "єдиний розмір"
      }
    ],
    "description": "glasses_desc|A",
    "popularity": 5,
    "customPopularity": 5,
    "packaging": "",
    realPhotos: []
  },
  {
    id: 'YIUSfjsdnfsjlv-sdflksdlfslsdg-sfddfhjhgjbv',
    "count": 1,
    "collection": "Сartier",
    "category": "accessories",
    "labelName": "glasses|A",
    "brand": "TVOI",
    "price": 995,
    "salePrice": 599,
    "images": [
      {
        id: 'TVOISJAKJSKD-MKjdi7f8sd-MCKshdsdw',
        url: "https://thumbs2.imgbox.com/e8/52/YO0inGRp_t.jpg"
      },
      {
        id: 'TVOISJAKJSKD-KJKjdfkjfksff-PIIhsdislcsdv',
        url: "https://thumbs2.imgbox.com/d1/33/AdoLeekc_t.jpg"
      },
      {
        id: 'TVOISJAKJSKD-KJKjdfkjfksff-PIIhsdislcsdv',
        url: "https://thumbs2.imgbox.com/40/d8/hxvTvVGP_t.jpg"
      },
      {
        id: 'TVOISJAKJSKD-KJKjdfkjfksff-PIIhsdislcsdv',
        url: "https://thumbs2.imgbox.com/9a/b1/C8yUMOP1_t.jpg"
      },
      {
        id: 'TVOISJAKJSKD-KJKjdfkjfksff-PIIhsdislcsdv',
        url: "https://thumbs2.imgbox.com/39/79/vXUwgIbz_t.jpg"
      }
    ],
    "weight": 0,
    "colors": [
      {
        id: 'TVOISJAKJSKD-PIjfdflsdvv-ajkadasdssdjds',
        "name": "black|A"
      }
    ],
    "metal": "steel|A",
    "sizes": [
      {
        id: 'TVOISJAKJSKD-KOJdodkfpkdpfdf-Lkjksldsd',
        "value": "єдиний розмір"
      }
    ],
    "description": "glasses_desc|A",
    "popularity": 5,
    "customPopularity": 5,
    "packaging": "",
    realPhotos: []
  },
  {
    id: 'FENKSJKTVOIISLDKL-ksdlfslsdg-sfddfhjhgjbv',
    "count": 1,
    "collection": "Fendi",
    "category": "accessories",
    "labelName": "glasses|A",
    "brand": "Fendi",
    "price": 2395,
    "salePrice": 1795,
    "images": [
      {
        id: 'TVOISJAKJSKD-687sdfkjksdf-UHJdklsdnksdc',
        url: "https://thumbs2.imgbox.com/2b/1d/JaRnWAJL_t.png"
      },
      {
        id: 'TVOISJAKJSKD-MKjdi7f8sd-MCKshdsdw',
        url: "https://thumbs2.imgbox.com/9e/6b/ScmLn2aT_t.jpg"
      },
      {
        id: 'TVOISJAKJSKD-KJKjdfkjfksff-PIIhsdislcsdv',
        url: "https://thumbs2.imgbox.com/5d/67/FRHQ2aBi_t.jpg"
      },
      {
        id: 'TVOISJAKJSKD-KJKjdfkjfksff-PIIhsdislcsdv',
        url: "https://thumbs2.imgbox.com/e1/b3/zl0nbdSn_t.jpg"
      },
      {
        id: 'TVOISJAKJSKD-KJKjdfkjfksff-PIIhsdislcsdv',
        url: "https://thumbs2.imgbox.com/68/32/i4sF4Sps_t.jpg"
      }
    ],
    "weight": 25,
    "colors": [
      {
        id: 'TVOISJAKJSKD-PIjfdflsdvv-ajkadasdssdjds',
        "name": "white|A"
      }
    ],
    "metal": "Пластик|A",
    "sizes": [
      {
        id: 'TVOISJAKJSKD-KOJdodkfpkdpfdf-Lkjksldsd',
        "value": "єдиний розмір"
      }
    ],
    "description": "Ці окуляри мають масивну оправу з гладкого білого пластику, що додає виразності та сучасного шарму. Лінзи темні, з високим рівнем захисту від UV-променів, ідеальні для сонячних днів біля басейну чи на пляжі / активних днів у місті|A",
    "popularity": 5,
    "customPopularity": 5,
    "packaging": "",
    realPhotos: []
  },
];

  