import React, { useEffect, useState } from 'react';
import { collection, doc, getDocs, setDoc, getDoc, deleteDoc, query, where, DocumentData, Query } from "firebase/firestore";
import { db } from '../configs/firebase';
import { ALL_DATA } from 'src/data/all_data';
import { sanitizePhoneNumber } from 'src/utils/number.util';
import { ProductContext } from 'src/context/product-context';
import { commandErrors, commandRegex, operators } from 'src/components/Admin/constants/firebase/attributes';
import { handleDeleteQuery, handleSelectQuery, handleUpdateQuery } from 'src/utils/firebase.util';

export function getDataFromFireStore(collectionName: string) {
    const [data, setData] = useState<any[]>([]);
    const { setIsLoading } = React.useContext(ProductContext);

    const fetchData = async () => {
        try {
            setIsLoading(true);
            const querySnapshot = await getDocs(collection(db, collectionName));
            const newData = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
            setData(newData);
        } catch (error) {
            console.error("Error fetching data: ", error);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        fetchData()
    }, []);

    return { data, fetchData };
}

export function getDataFromFireStoreById(collectionName: string, id: string) {
    const [data, setData] = useState<any | null>(null);

    const fetchData = async () => {
        try {
            const docRef = doc(db, collectionName, id);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                setData({ ...docSnap.data(), id: docSnap.id });
            }
        } catch (error) {
            console.error("Error fetching data: ", error);
        }
    }

    useEffect(() => {
        fetchData()
    }, []);

    return { data, fetchData };
}

export function postDataToFireStore(collectionName: string, data: any) {
    const postData = async () => {
        try {
            await setDoc(doc(db, collectionName), data);
        } catch (error) {
            console.error("Error adding data: ", error);
        }
    }

    return { postData };
}

export function updateDataInFireStore(collectionName: string, id: string, newData: any) {
    const updateData = async () => {
        try {
            await setDoc(doc(db, collectionName, id), newData);
        } catch (error) {
            console.error("Error updating data: ", error);
        }
    }

    return { updateData };
}

export function deleteDataFromFireStore(collectionName: string, id: string) {
    const deleteData = async () => {
        try {
            await setDoc(doc(db, collectionName, id), { isDeleted: true });
        } catch (error) {
            console.error("Error deleting data: ", error);
        }
    }

    return { deleteData };
}

export async function uploadUserDataToFireStore(userData: any) {
    try {
        const sanitizedPhone = sanitizePhoneNumber(userData.phone);
        console.log(`Sanitized phone number: ${sanitizedPhone}`);
        
        const usersRef = collection(db, "orders");
        const phoneQuery = query(usersRef, where("phone", "==", sanitizedPhone));
        const querySnapshot = await getDocs(phoneQuery);

        if (!querySnapshot.empty) {
            console.log(`Skipping upload. User with phone number ${sanitizedPhone} already exists.`);
            return;
        }

        const userRef = doc(db, "orders", userData.phone);

        const dataToUpload = {
            name: userData.name,
            phone: sanitizedPhone,
            //email: userData.email,
            selectedImage: userData.selectedImage,
            town: userData.town,
            postOffice: userData.postOffice
        };

        await setDoc(userRef, dataToUpload);

        console.log("User data successfully uploaded!");
    } catch (error) {
        console.error("Error uploading user data: ", error);
    }
}

export async function getUserDataFromFireStoreByPhone(phone: string) {
    try {
        const sanitizedPhone = sanitizePhoneNumber(phone);
        const userRef = doc(db, "orders", sanitizedPhone);
        const docSnap = await getDoc(userRef);

        if (docSnap.exists()) {
            console.log(`User with phone number ${sanitizedPhone} found.`);
            return { ...docSnap.data(), id: docSnap.id };
        }

        console.log(`User with phone number ${sanitizedPhone} not found.`);
        return;
    } catch (error) {
        console.error("Error fetching user data: ", error);
    }
}

export async function uploadPricesToFireStore() {
    try {
        const batchUpload = ALL_DATA.map(async (item) => {
            const docRef = doc(db, "prices", item.id);
            
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                console.log(`Skipping item with ID ${item.id}, as it already exists.`);
                return;
            }
          
            const priceData = {
                productId: item.id,
                count: item.count,
                price: item.price,
                collection: item.collection,
                category: item.category,
                salePrice: item.salePrice,
                popularity: item.popularity,
                customPopularity: item.customPopularity,
                weight: item.weight,
                metal: item.metal,
                description: item.description,
                packaging: item.packaging,
                brand: item.brand,
                labelName: item.labelName,
                images: item.images ?? [],
                sizes: item.sizes ?? [],
                colors: item.colors ?? [],
                realPhotos: item.realPhotos ?? [],  
                relatedProducts: item.relatedProducts ?? []
            };
          
            await setDoc(docRef, priceData);
            console.log(`Added item with ID ${item.id}`);
        });
      
        await Promise.all(batchUpload);
        console.log("Prices upload process completed.");
    } catch (error) {
        console.error("Error uploading prices: ", error);
    }
}

export async function removeAllPricesFromFireStore() {
    try {
        const querySnapshot = await getDocs(collection(db, "prices"));
        const batchDelete = querySnapshot.docs.map(async (doc) => {
            await deleteDoc(doc.ref);
        });
      
        await Promise.all(batchDelete);
        console.log("All prices have been successfully deleted.");
    } catch (error) {
        console.error("Error removing prices: ", error);
    }
}

export async function convertQueryToFirestoreCommand(command: string) {
    try {
        const lowerCaseQuery = command.toLowerCase().trim();

        if (commandRegex.SELECT_REGEX.test(lowerCaseQuery)) {
            return await handleSelectQuery(command);
        }

        if (commandRegex.UPDATE_REGEX.test(lowerCaseQuery)) {
            return await handleUpdateQuery(command);
        }

        if (commandRegex.DELETE_REGEX.test(lowerCaseQuery)) {
            return await handleDeleteQuery(command);
        }

        throw new Error(commandErrors.INVALID_QUERY);
    } catch (error) {
        console.error("Error converting query:", error);
    }
}