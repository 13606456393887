import React from 'react'
import Skeleton from '@mui/material/Skeleton';
import useWindowSize from 'src/hooks/UseWindowSize';

const ProductCardSkeleton = () => {
    let size = useWindowSize();
    const isMobile = size.width < 770;

    return (
        <div className='productCard'>
            <div className="productImagePos">
                <Skeleton variant='rectangular' animation="wave" style={{borderRadius: '25px', height: '100%'}} width='100%' />
            </div>

            <div className='productLabel'>
                <Skeleton variant='text' animation="wave" width={100} height={30} />
                {!isMobile && <Skeleton variant='text' animation="wave" width={150} height={15} />}
            </div>
        </div>
    )
}

export default ProductCardSkeleton