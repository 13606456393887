import React from 'react'
import './uistyle.css'

interface ITvoiSvgProps {
    alt: string;
    path: string;
    onClick?: (value: any) => void;
}

const TvoiSvg = (props: ITvoiSvgProps) => {
    return (
        <img
            src={props.path}
            alt={props.alt}
            onClick={props.onClick}
        />
    )
}

export default TvoiSvg;