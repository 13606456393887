import React from 'react'
import { useNavigate } from 'react-router-dom';
import { getRoute } from 'src/services/routes.service';
import { translateText } from 'src/services/translation.service';

const IMG_URL_V1 = 'https://thumbs2.imgbox.com/a3/6b/MVFWpy3H_t.jpg';
const IMG_URL_V2 = 'https://thumbs2.imgbox.com/19/60/6SPshXgW_t.jpg';
const IMG_URL_V3 = 'https://thumbs2.imgbox.com/7b/8c/oOl8gaHC_t.jpg';

const ForSheAndHeBanner = () => {
    const navigate = useNavigate();

    const goToGlasses = () => {
        navigate(getRoute('watches'));
    }

    const _renderGoToButton = () => {
        return(
            <div className='flex justify-center items-center'>
                <button onClick={goToGlasses} className='bg-[#000] text-white p-3 rounded-3xl text-lg font-[100]' style={{letterSpacing: '1px'}}>{translateText("viewDetails|A")}</button>
            </div>
        )
    }
    return (
        <div className='flex sm:flex-row flex-col-reverse w-full justify-between mt-3 rounded-[45px]' style={{ backgroundColor: 'white' }}>
            <div className='w-full flex justify-center items-center'>
                <div className='lg:text-[75px] text-[45px] font-[900] text-center py-5 pt-5 pb-1' style={{letterSpacing: -2.5}}>
                    <div className='gilroy text-[#313131]'>{translateText("timeConnect|A")}.</div>
                    <div className='lg:text-[24px] text-[20px] text-[#8a8a8a] mb-5 font-[100]' style={{letterSpacing: -0.7}}>{translateText("watchesBanner|A")}</div>
                    {_renderGoToButton()}
                </div>
            </div>
            <img src={IMG_URL_V1} alt="model" className='w-full sm:w-2/5 rounded-[45px] shadow'/>
        </div>

    )
}

export default ForSheAndHeBanner