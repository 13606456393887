export const operators: string[] = ['>', '<', '>=', '<=', '==', '!='];

export const commandRegex = Object.freeze({
    SELECT_REGEX: /^select\s+([a-z0-9_-]+)\s+where\s+(.*)$/i,
    UPDATE_REGEX: /^update\s+([a-z0-9_-]+)\s+set\s+(.*)\s+where\s+(.*)$/i,
    DELETE_REGEX: /^delete\s+from\s+([a-z0-9_-]+)\s+where\s+(.*)$/i,
    CONDITION_REGEX: /\s+and\s+/i
});

export const commandErrors = Object.freeze({
    INVALID_QUERY: 'Invalid query format. Supported formats: SELECT, UPDATE, DELETE.',
    INVALID_CONDITION: 'Invalid condition format. Expected valid operators (>, <, >=, <=, ==, !=).',
    INVALID_UPDATE: 'Invalid UPDATE format. Expected format: update <collection> set <field=value,...> where <conditions>.',
    INVALID_DELETE: 'Invalid DELETE format. Expected format: delete from <collection> where <conditions>.'
});